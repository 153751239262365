import './Footer.css'
import uterin from '../../../images/images/Uterine-logo-tag--TagRev.svg'
import ScrollToTop from '../ScrollToTop';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';

import {
    Link
  } from 'react-router-dom';
  

  const message= <div><span className='d-block fw-bold mb-2'>
    We value your privacy</span> <span> We use cookies to enhance your browsing experience.<br/>By clicking “Accept All” you consent to our use of cookies.</span></div>

function Footer(props) {
    return <>
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <a href="javascript:void(0)"><img src={uterin} alt="uterinekind" /></a>
                        <div className="footer__content mt-5">
                            <p> 6 Liberty Sq. #2479, Boston, MA 02109</p>
                            <p>Questions? Email: <a href="mailto:hello@uterinekind.com">hello@uterinekind.com</a></p>
                            <p>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </p>
                            <p>
                                <Link to="/terms-and-conditions">Terms and Conditions</Link>
                            </p>
                            <p>&copy; {(new Date().getFullYear())} Uterinekind. All rights reserved. </p>
                            <div className="social-links">
                                <a href="https://www.linkedin.com/company/uterinekind" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <defs>
                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                <stop offset="0" stopColor="#11a4de" />
                                                <stop offset="0.06" stopColor="#1d9dd9" />
                                                <stop offset="0.14" stopColor="#378ccb" />
                                                <stop offset="0.25" stopColor="#4b75b9" />
                                                <stop offset="0.36" stopColor="#6260aa" />
                                                <stop offset="0.38" stopColor="#675ea8" />
                                                <stop offset="0.44" stopColor="#725ba6" />
                                                <stop offset="0.55" stopColor="#a1509e" />
                                                <stop offset="0.67" stopColor="#d34c72" />
                                                <stop offset="0.75" stopColor="#db5158" />
                                                <stop offset="0.91" stopColor="#ee6124" />
                                            </linearGradient>
                                        </defs>
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path className="social-media-icon" d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z" fill="#13A5DF" />
                                    </svg>
                                </a>

                                <a href="https://www.instagram.com/uterinekind" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <defs>
                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                <stop offset="0" stopColor="#11a4de" />
                                                <stop offset="0.06" stopColor="#1d9dd9" />
                                                <stop offset="0.14" stopColor="#378ccb" />
                                                <stop offset="0.25" stopColor="#4b75b9" />
                                                <stop offset="0.36" stopColor="#6260aa" />
                                                <stop offset="0.38" stopColor="#675ea8" />
                                                <stop offset="0.44" stopColor="#725ba6" />
                                                <stop offset="0.55" stopColor="#a1509e" />
                                                <stop offset="0.67" stopColor="#d34c72" />
                                                <stop offset="0.75" stopColor="#db5158" />
                                                <stop offset="0.91" stopColor="#ee6124" />
                                            </linearGradient>
                                        </defs>

                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path className="social-media-icon" d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z" fill="#13A5DF" />
                                    </svg>
                                </a>
                               
                                <a href="https://www.facebook.com/uterinekind" target="_blank">
                         
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <defs>
                                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stopColor="#11a4de" />
                                            <stop offset="0.06" stopColor="#1d9dd9" />
                                            <stop offset="0.14" stopColor="#378ccb" />
                                            <stop offset="0.25" stopColor="#4b75b9" />
                                            <stop offset="0.36" stopColor="#6260aa" />
                                            <stop offset="0.38" stopColor="#675ea8" />
                                            <stop offset="0.44" stopColor="#725ba6" />
                                            <stop offset="0.55" stopColor="#a1509e" />
                                            <stop offset="0.67" stopColor="#d34c72" />
                                            <stop offset="0.75" stopColor="#db5158" />
                                            <stop offset="0.91" stopColor="#ee6124" />
                                        </linearGradient>
                                                                            </defs>
    <path className="social-media-icon" d="M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47062 14 5.5 16 5.5H17.5V2.1401C17.1743 2.09685 15.943 2 14.6429 2C11.9284 2 10 3.65686 10 6.69971V9.5H7V13.5H10V22H14V13.5Z" fill="#13A5DF"></path></svg>

                                </a>
                                <a href="https://www.tiktok.com/@uterinekind" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <defs>
                                            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                                <stop offset="0" stopColor="#11a4de" />
                                                <stop offset="0.06" stopColor="#1d9dd9" />
                                                <stop offset="0.14" stopColor="#378ccb" />
                                                <stop offset="0.25" stopColor="#4b75b9" />
                                                <stop offset="0.36" stopColor="#6260aa" />
                                                <stop offset="0.38" stopColor="#675ea8" />
                                                <stop offset="0.44" stopColor="#725ba6" />
                                                <stop offset="0.55" stopColor="#a1509e" />
                                                <stop offset="0.67" stopColor="#d34c72" />
                                                <stop offset="0.75" stopColor="#db5158" />
                                                <stop offset="0.91" stopColor="#ee6124" />
                                            </linearGradient>
                                        </defs>
                                    <path className="social-media-icon" d="M16 8.24537V15.5C16 19.0899 13.0899 22 9.5 22C5.91015 22 3 19.0899 3 15.5C3 11.9101 5.91015 9 9.5 9C10.0163 9 10.5185 9.06019 11 9.17393V12.3368C10.5454 12.1208 10.0368 12 9.5 12C7.567 12 6 13.567 6 15.5C6 17.433 7.567 19 9.5 19C11.433 19 13 17.433 13 15.5V2H16C16 4.76142 18.2386 7 21 7V10C19.1081 10 17.3696 9.34328 16 8.24537Z" fill="#13A5DF"></path></svg>
                                </a>
                            </div>
                            <p className='mt-20p'>
                            <b>Disclaimer:</b> Uterinekind is an educational platform. Content presented on the platform and on the Uterinekind website is for informational purposes only and is not medical advice or to be used to diagnose or treat any medical conditions. If you are experiencing a medical emergency, contact your physician, dial 911 or call your local emergency care center.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
            <CookieBanner
                message={message}
                styles={{
                    dialog: {
                      position: 'fixed',
                      zIndex:999,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: '25px',
                      color:"#fff",
                      background:'#808CE3',
                      borderRadius:"20px 20px 0px 0px"
                    }
                  }}
                  
                  acceptAllButtonText="Accept All"
                  showAcceptSelectionButton={true}
                  acceptSelectionButtonText="Reject All"
                  wholeDomain={true}
                  showStatisticsOption={false}
                  showMarketingOption={false}
                  policyLink="/privacy-policy"
                  onAccept = {() => {}}
                  onAcceptPreferences = {() => {}}
                  onAcceptStatistics = {() => {}}
                  onAcceptMarketing = {() => {}}
            />
        </footer>
    </>
}

export default Footer;