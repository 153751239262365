import { useEffect, React } from 'react';
import './css/HomePage.css';
//import ContentHero from "./ContentHero";
import Latesthero from "./Latesthero";
import StepSection from "./StepSection";
import ContentSec from "./ContentSec";
import MobileContentSec from "./MobileContentSec";
import NoneOFTrue from "./NoneOFTrue";
import ContentCitizen from "./ContecntCitizen";
import MobileContecntCitizen from "./MobileContecntCitizen";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Symptoms from "./Symptoms";
import Report from "./Report";
import Moniter from "./Moniter";
import MobSymptoms from "./MobSymptoms";
import MobReport from "./MobReport";
import MobMoniter from "./MobMoniter";
import MoniterAndMeasure from "./MoniterAndMeasure";
import SymptonAndSign from "./SymptonAndSign";
import SymptomContent from "./SymptomContent";
import MobileSymptomContent from "./MobileSymptomContent";
import StartListening from "./StartListening";
import StartTalking from "./StartTalking";
import DifferntKindConsult from "./DifferntKindConsult";
import PartTool from "./PartTool";
import Precautions from "./Precautions";
import OurStory from "./OurStory";
import MobCaraousel from './MobCaraousel';
import './css/home-hero.css';
import './css/animate-lines.css';
import './css/steps.css';
import './css/general-style.css';
import './css/content-animate.css';
import './css/clouds.css';
import './css/symptoms.css';
import './css/faq.css';
import './css/footer.css';
import './css/typing-animation.css';
import { TextPlugin } from "gsap/all";
import { SuperSEO } from "react-super-seo";

import useWindowDimensions from "../../../hooks/useWindowDimensions"

import CommentDoesntMean from "./CommentDoesntMean";

import Video from "./Intro-video";

function BadPeriodsNotDiagnosis(props) {

    const { width } = useWindowDimensions();
    gsap.registerPlugin(TextPlugin)
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin)

    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            },
            duration: 0,
        });
    }, []);
    return <>
        <SuperSEO title="Uterinekind™ Bad periods not diagnosis" />

        <Latesthero gsap={gsap} />
        
        <Video gsap={gsap}  />

        {width < 768
            ? <MobileContecntCitizen gsap={gsap} />
            : <ContentCitizen gsap={gsap} />
        }

{width < 768
            ? <>
                <MobCaraousel />
                {/* <MobSymptoms gsap={gsap} />
                <MobReport gsap={gsap} />
                <MobMoniter gsap={gsap} /> */}
                
                </>
            : <><Symptoms gsap={gsap} /><Report gsap={gsap} /><Moniter gsap={gsap} /></>
        }

        <PartTool gsap={gsap} />

        <StepSection gsap={gsap} />

        {/* {width < 768
            ? <>
                <MobileContentSec gsap={gsap} />
                <NoneOFTrue gsap={gsap} />
            </>
            : <ContentSec gsap={gsap} />
        } */}

        <CommentDoesntMean gsap={gsap} />

       
         
       

      

        
        
        <MoniterAndMeasure gsap={gsap} />
        {/* 
        <SymptonAndSign gsap={gsap} />*/}
        {/* {width < 768
            ? <MobileSymptomContent gsap={gsap} />
            : <SymptomContent gsap={gsap} />
        } */}
        {/* <StartListening gsap={gsap} /> */}
      {/*    <StartTalking gsap={gsap} />
        <DifferntKindConsult gsap={gsap} />
      
        <Precautions gsap={gsap} />
        <OurStory gsap={gsap} />
    */}
       
    </>
}

export default BadPeriodsNotDiagnosis