import React, { Component } from "react";

// import chart from '../../../../src/assets/images/MeasureSymptoms-Trend-1day.png'
// import chart2 from '../../../../src/assets/images/MeasureSymptoms-Trend-7days.png'

import chart from '../../../../src/assets/gif/MeasureSymptoms-Impact-1.gif'
import chart2 from '../../../../src/assets/gif/MeasureSymptoms-Impact-2.gif'
/* generate report images */
import physicianReport from '../../../../src/assets/images/PhyReport-1.png'
import physicianReport2 from '../../../../src/assets/images/PhyReport-2.png'

/* monitor meds and treatment */

import monitorReport from '../../../../src/assets/images/MonitorMeds-Diary.png'
import monitorReport2 from '../../../../src/assets/images/MonitorMeds-Impact.png'

import Slider from "react-slick";
import NextArrow from "./SampleNextArrow";
import PrevArrow from "./SamplePrevArrow";
const settings = {
  dots: false,
  arrows: true,
  fade: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  afterChange: () => this.setState(state => ({ updateCount: this.state.updateCount + 1 })),
  beforeChange: (current, next) => this.setState({ slideIndex: next })
};

export default class MobCaraouselTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }
  
  render() {
    return (
       <section className="bg-secondary symptoms text-end spacer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                      <div className="slider-text">
                        <Slider
                          asNavFor={this.state.nav2}
                          ref={slider => (this.slider1 = slider)}
                          arrows={false}
                          fade={false}
                          infinite={true}
                        >
                          <div>
                            <h2 className="fw-bold animate-heading">Measure your symptoms</h2>
                            <div className="list-unstyled animate-listing animate-listing-lt gallery-items a">
                                <div>
                                    <a className='active' href="javascript:void(0);">
                                        See how they trend over time
                                    </a>
                                </div>
                                <div onClick={()=>this.slider1.slickGoTo(1)} >
                                    <a  className='sd' href="javascript:void(0);">
                                        See how symptoms impact each other
                                    </a>
                                </div>
                            </div>
                          </div>
                          <div>
                            <h2 className="fw-bold animate-heading">Measure your symptoms</h2>
                            <div className="list-unstyled animate-listing animate-listing-lt gallery-items">
                                <div  onClick={()=>this.slider1.slickGoTo(0)}>
                                    <a className='' href="javascript:void(0);">
                                        See how they trend over time
                                    </a>
                                </div>
                                <div>
                                    <a className='active' href="javascript:void(0);">
                                        See how symptoms impact each other
                                    </a>
                                </div>
                            </div>
                          </div>
                          <div>
                            <h2 className="fw-bold animate-heading">
                            Generate your physician report
                            </h2>
                            <div className="list-unstyled animate-listing animate-listing-lt gallery-items">
                                <div>
                                    <a className='active' href="javascript:void(0);">
                                        Comprehensive, self-reported symptom data
                                    </a>
                                </div>
                                <div onClick={()=>this.slider1.slickGoTo(3)}>
                                    <a className='' href="javascript:void(0);">
                                        Easy to scan charts designed for physicians
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="fw-bold animate-heading">
                            Generate your symptoms summary
                            </h2>
                            <div className="list-unstyled animate-listing animate-listing-lt gallery-items">
                                <div onClick={()=>this.slider1.slickGoTo(2)}>
                                    <a className='' href="javascript:void(0);">
                                        Comprehensive, self-reported symptom data
                                    </a>
                                </div>
                                <div>
                                    <a className='active' href="javascript:void(0);">
                                        Easy to scan charts designed for physicians
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                          <h2 className="fw-bold animate-heading">
                              Monitor meds and treatments
                          </h2>
                          <div className="list-unstyled animate-listing animate-listing-lt gallery-items">
                              <div>
                                  <a className='active' href="javascript:void(0);">
                                      Note your treatments
                                  </a>
                              </div>
                              <div onClick={()=>this.slider1.slickGoTo(5)}>
                                  <a className='' href="javascript:void(0);">
                                      See how treatments impact symptoms
                                  </a>
                              </div>
                          </div>
                      </div>
                      <div>
                          <h2 className="fw-bold animate-heading">
                              Monitor meds and treatments
                          </h2>
                          <div className="list-unstyled animate-listing animate-listing-lt gallery-items s">
                              <div onClick={()=>this.slider1.slickGoTo(4)}>
                                  <a className='' href="javascript:void(0);">
                                      Note your treatments
                                  </a>
                              </div>
                              <div onClick={()=>this.slider1.slickGoTo(1)}>
                                  <a className='active' href="javascript:void(0);">
                                      See how treatments impact symptoms
                                  </a>
                              </div>
                          </div>
                      </div>
                        </Slider>
                        </div>
                        <div className="slider-media mt-4">
                        <Slider
                          asNavFor={this.state.nav1}
                          ref={slider => (this.slider2 = slider)}
                          slidesToShow={1}
                          swipeToSlide={true}
                          focusOnSelect={true}
                          arrows={true}
                          fade={false}
                          infinite={false}
                          nextArrow={<NextArrow />}
                          prevArrow={<PrevArrow />}
                        >
                          <div className="app-media">
                              <div className="app-media__slides">
                                <img src={chart} alt="symptoms report" />
                              </div>
                          </div>
                          <div>
                          <div className="app-media__slides">
                                <img src={chart2} alt="symptoms report" />
                              </div>
                          </div>
                          <div>
                          <div className="app-media__slides">
                                  <img src={physicianReport} alt="symptoms report" />
                              </div>
                          </div>
                          <div>
                          <div className="app-media__slides">
                                  <img src={physicianReport2} alt="symptoms report" />
                              </div>
                          </div>
                          <div>
                          <div className="app-media__slides">
                                  <img src={monitorReport} alt="symptoms report" />
                              </div>
                          </div>
                          <div>
                          <div className="app-media__slides">
                              <img src={monitorReport2} alt="symptoms report" />
                              </div>
                          </div>
                          
                        </Slider>
                        </div>
                      </div>
                    </div>
                </div>
        </section>
    
    );
  }
}
