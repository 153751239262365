import {React, useEffect} from "react";
import gsap from "gsap";

import {ScrollTrigger} from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import {SuperSEO} from "react-super-seo";

import Trail from '../HomePage/Trail';

import Image from '../../../images/navigate-path.png';
import Image2 from '../../../images/struggling2.png';
import Image3 from '../../../images/struggling3.png';

function StrugglingDiagnosis(props) {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            }, duration: 0,
        });
    }, []);

    useEffect(() => {
        const ctx = gsap.context(() => {
        });

        return () => ctx.revert();
    }, []);

    return (<>
        <SuperSEO
            title="Uterinekind™ - Everything needed to navigate the path to diagnosis, treatment, and recovery"/>
        <SuperSEO description="Uterinekind App: The essential healthcare companion for women. Offering a complete suite of tools for symptom understanding, treatment access, and recovery support, our app ensures a seamless and well-informed healthcare journey." />
        <section className="hero-shadow hero-section-spacer spacer-bottom">
            <div className="container container-sm position-relative">
                <div className="row align-content-center mb-5">
                    <h1 className="h2 mb-5 text-center">Struggling to get a diagnosis?</h1>
                    <div className="col-md-4 px-5">
                        <img src={Image}
                             alt="Everything needed to navigate the path to diagnosis, treatment, and recovery"
                             className="mb-5"/>
                        <h4 className="font-weight-300 mb-5">
                            Easily record your daily symptoms. See how they trend.
                        </h4>
                    </div>
                    <div className="col-md-4 px-5">
                        <img src={Image2}
                             alt="Everything needed to navigate the path to diagnosis, treatment, and recovery"
                             className="mb-5"/>
                        <h4 className="font-weight-300 mb-5">
                            Educational content to prepare for a meaningful consultation.
                        </h4>
                    </div>
                    <div className="col-md-4 px-5">
                        <img src={Image3}
                             alt="Everything needed to navigate the path to diagnosis, treatment, and recovery"
                             className="mb-5"/>
                        <h4 className="font-weight-300 mb-5">
                            Generate a physician report with your comprehensive data.
                        </h4>
                    </div>
                </div>
                <Trail gsap={gsap} showButtonsOnly={1}/>
                <h5>Don't let undiagnosed symptoms hold you back. Keep track of your daily health and wellness with ease
                    using our symptom tracking app. Get valuable insights and trends that can aid in diagnosis and
                    prepare you for a productive consultation. Our educational resources ensure you are informed and
                    ready to advocate for your health. Generate a detailed physician report with your comprehensive data
                    for a more accurate diagnosis and treatment plan.</h5>

            </div>
        </section>

    </>);
}

export default StrugglingDiagnosis;