export const podcastItemAction = (_payload) =>{
    return {
        type:'PODCASTITEM', payload:_payload
    }
}

export const activePodcastItem = (_payload) =>{
    return {
        type:'ACTIVEPODCASTITEM', payload:_payload
    }
}

export const latestPodcastItem = (_payload) =>{
    return {
        type:'LATESTPODCASTITEM', payload:_payload
    }
}
