import React, { useEffect, useRef, useState } from 'react'
// import monitorReport from '../../../../src/assets/images/Diary-UI.png'
// import monitorReport2 from '../../../../src/assets/images/Chart-Trend-6.png'
import monitorReport from '../../../../src/assets/images/MonitorMeds-Diary.png'
import monitorReport2 from '../../../../src/assets/images/MonitorMeds-Impact.png'
import monitorReport3 from '../../../../src/assets/images/Chart-Trend-6.png'

import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';

import { gsapSplitTextAnimationv2 } from '../../gsap/common';
import { monitorMedsAndTreatments } from '../../../utits/staticContent/HomePage'

function Moniter(props) {

    const [monitorImg, setmonitorImg] = useState(monitorReport);
    const [isActive, setIsActive] = useState(8);
    const monitorTriggerRef = useRef([]);
    const monitorRef = useRef([]);

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            gsapSplitTextAnimationv2(props.gsap, monitorRef, monitorTriggerRef,
                1, 100, 1.5, 0.1, "top 80%", "bottom top"
            );
        });
        return () => ctx.revert();
    }, []);

    const handleClick = (id, key) => {
        setIsActive(key);
        switch (id) {
            case '2':
                setmonitorImg(monitorReport2);
                break;
            case '3':
                setmonitorImg(monitorReport3);
                break;
            default:
                setmonitorImg(monitorReport);
                break;
        }
    }

    useEffect(() => {
        const osInstance = OverlayScrollbars(document.querySelector('.monitor-gallery-items'), {
            overflow: {
                x: 'hidden',
            },
        });

    }, [])

    return (
         <section className="bg-secondary symptoms spacer-bottom">
            <div className="container">
                <div className="row align-items-center" ref={monitorTriggerRef}>
                    <div className="col-md-7 text-end">
                        <h2 ref={monitorRef} className="fw-bold animate-heading">Monitor meds and treatments</h2>
                        <div className="list-unstyled animate-listing animate-listing-lt physician-report-items monitor-gallery-items">
                            {monitorMedsAndTreatments().map(({ title, key, id }) => {
                                return (
                                    <>
                                        <div key={key}>
                                            <a className={(isActive == key ? `active` : ``)} key={key} href="javascript:void(0);"
                                                onClick={() => handleClick(id, key)}
                                            >{title}
                                            </a>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>

                    <div className="col-md-5 text-center">
                        <div className="Monitor-gallery mt-5 mt-md-0">
                            <div className="Monitor-gallery__blk" id="Monitor-items-one">
                                <img src={monitorImg} alt="monitor screen" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Moniter