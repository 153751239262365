import {React, useEffect} from "react";
import gsap from "gsap";
import Mailchimp from "../../../utils/mailchimp-form";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import { SuperSEO } from "react-super-seo";

import Trail from '../HomePage/Trail';

import Image from '../../../images/connecting-the-dots.png';

function ConnectTheDots(props) {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  useEffect(() => {
    gsap.to(window, {
      scrollTo: {
        y: ".pageHeader",
      },
      duration: 0,
    });
  }, []);

  useEffect(() => {
    const ctx = gsap.context(() => {});

    return () => ctx.revert();
  }, []);

  return (
    <>
        <SuperSEO title="Uterinekind™ - Help your patients connect the dots with the Uterinekind App." />
        <SuperSEO description="Uterinekind App: The essential healthcare companion for women. Offering a complete suite of tools for symptom understanding, treatment access, and recovery support, our app ensures a seamless and well-informed healthcare journey." />
        <section className="hero-shadow hero-section-spacer spacer-bottom">
            <div className="container container-sm position-relative">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="h3">
                            Help your patients connect the dots with the Uterinekind App.
                        </h1>
                        <h4>Symptoms of common chronic gynecological conditions impact mental health</h4>
                        {/*<Mailchimp*/}
                        {/*    action='https://uterinekind.us11.list-manage.com/subscribe/post?u=5949df872bf794ee6cbea8a93&amp;id=e8d34d99fc&amp;f_id=0056abe0f0'*/}
                        {/*    fields={[*/}
                        {/*        {*/}
                        {/*            name: 'FNAME',*/}
                        {/*            placeholder: 'First name',*/}
                        {/*            type: 'text',*/}
                        {/*            required: true*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            name: 'LNAME',*/}
                        {/*            placeholder: 'Last name',*/}
                        {/*            type: 'text',*/}
                        {/*            required: false*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            name: 'EMAIL',*/}
                        {/*            placeholder: 'Email',*/}
                        {/*            type: 'email',*/}
                        {/*            required: true*/}
                        {/*        }*/}
                        {/*    ]}*/}
                        {/*/>*/}
                        <img src={Image} alt="Help your patients connect the dots with the Uterinekind App." className="mb-5" />
                        <Trail gsap={gsap} showButtonsOnly={1}/>

                        <h5>
                            Empower your patients with the Uterinekind App, the ultimate tool for comprehensive support throughout their healthcare journey. From understanding symptoms to accessing treatment and achieving full recovery, our app provides all the essential resources for a smooth and informed experience.
                        </h5>

                    </div>
                </div>
            </div>
        </section>

    </>
  );
}

export default ConnectTheDots;
