export function MeasureYourSymptoms() {
  return [
    {
      id: "1",
      key: 1,
      title: "See how they trend over time",
    },
    {
      id: "2",
      key: 2,
      title: "See how symptoms impact each other",
    }
  ];
}

export function generateYourPhysicianReport() {
  return [
    {
      id: "1",
      key: 5,
      title: "Comprehensive, self-reported symptom data",
    },
    {
      id: "2",
      key: 6,
      title: "Easy to scan charts designed for physicians",
    }
  ];
}

export function monitorMedsAndTreatments() {
  return [
    {
      id: "1",
      key: 8,
      title: "Note your treatments",
    },
    {
      id: "2",
      key: 9,
      title: "See how treatments impact symptoms",
    }
  ];
}

export const VIDEO_URL = 'https://uterinekind.s3.amazonaws.com/explainer-video-060723.mp4';