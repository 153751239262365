export function formatPublisedDate(createdDate, monthType) {
  
  const _date = new Date(createdDate);
  return _date.toLocaleDateString("en-US", {
    month: monthType,
    day: "numeric",
    year: "numeric",
  });
}

export function minToStandardFormat(duration){
  let _sec = parseInt(duration, 10); // don't forget the second param
  let hours   = Math.floor(_sec / 3600);
  let minutes = Math.floor((_sec - (hours * 3600)) / 60);
  let seconds = _sec - (hours * 3600) - (minutes * 60);

  hours = hours < 10 ? `0${hours}`:hours;
  minutes = minutes < 10 ? `0${minutes}`:minutes;
  seconds = seconds < 10 ? `0${seconds}`:seconds;
  return `${hours === '00' ? '':`${hours}:`}${minutes}:${seconds}`;
}

export function formatDuration(_param) {
  let _duration = _param.split(":");
  return ( _duration[0] === '00' ) ?  `${_duration[1]}:${_duration[2]}`:_param;
}

export function getAudioId(enclosure) {
    return (enclosure === undefined || enclosure === "") ? '':enclosure.split('/')[6]
}

export function splitTitle(title) {
    return title.split('with')[0]
}

export function seoFriendlyUrl(params, separator="-") {
  var _param = params.replace(/[^a-zA-Z ]/g, "");
  return _param.trim().replace(/ /g, separator).toLowerCase();
}

export function searchFriendlyUrl(string) {
  return string.replace(/-/gi, ' ');
}

export function randomNo(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getPodcastAurthor( title ){
  var _title = title.toLowerCase().split('with');
  var response = ``;
  if( _title.length > 1){
    for (let index = 1; index < _title.length; index++) {
      response += `with ${_title[index]}`;
    }
  }

  return response;
}