import { useEffect, useState, useRef } from "react";
import Symptomsscreen from '../../../assets/images/symptoms-screen.png';

function HowToUse(props) {

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            
                let timelineHowToCheck = props.gsap.timeline({
                    scrollTrigger: {
                        trigger: ".instruction-section",
                        start: "top 50%",
                    }
                });
    
                timelineHowToCheck.from(".instruction-image-section", {
                    duration: 1.5,
                    x: -320,
                    autoAlpha: 0,
                    ease: "Power4.easeOut"
                }, 0);
                
                timelineHowToCheck.from(".instruction-content-section", {
                    duration: 1.5,
                    x: 320,
                    autoAlpha: 0,
                    ease: "Power4.easeOut"
                }, 0);
        });
        
        return () => ctx.revert();
    }, []);

    return <>
        {/* third section */}
        <section className="spacer-both instruction-section overflow-hidden">
            <div className='container container-sm'>
                <div className='row justify-content-center'>
                    <div className='col-md-5 instruction-image-section mb-4 mb-md-0 text-center text-mb-start'>
                        <img src={Symptomsscreen} alt="Symptomsscreen" />
                    </div>
                    <div className='col-md-6 instruction-content-section'>
                        <p>Check the box next to each specific symptom you experienced that day</p>
                        <div className='checkbox-wrapper'>
                            <div className='checkbox-fields'>
                                <input type="checkbox" checked name='symptoms' id="Bladder" />
                                <label for="Bladder"></label>Bladder
                            </div>

                            <div className='checkbox-fields'>
                                <input type="checkbox" name='symptoms' id='Breast' />
                                <label for='Breast'></label>Breast
                            </div>

                            <div className='checkbox-fields'>
                                <input type="checkbox" checked name='symptoms' id='Chest' />
                                <label for='Chest'></label>Chest
                            </div>

                            <p className='mt-5'>Each detailed symptoms section contains a text field to add an entry to your diary under that specific symptom</p>
                            <textarea placeholder='(Example entry) The clots are really painful and pain meds don’t help'></textarea>

                            <div className='checkbox-fields'>
                                <input type="checkbox" checked name='symptoms' id='Post' />
                                <label for='Post'></label>Post diary entry to symptoms summary report
                            </div>

                            <p className='mt-5'>After completing the categories, click the button to enter your symptoms</p>
                            <a href='javascript:void(0)' className='btn px-4'>Save</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}
export default HowToUse;