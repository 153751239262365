import { useEffect, useRef } from "react";
import { gsapSplitTextLineAnimation, gsapAlphaYInAnimation } from "../../gsap/common";

function Drive( props ){
    const ourMemberRef = useRef([]);
    const ourMemberContentRef = useRef([]);
    ourMemberContentRef.current = [];
  
    useEffect(() => {
      const ctx = props.gsap.context(() => {
        
        gsapSplitTextLineAnimation(props.gsap, ourMemberRef, ourMemberRef,
          1, 50, 0.6, "top bottom", "bottom bottom");
  
        // gsapSplitTextLineAnimation(props.gsap, peterRef, peterRef,
        // 1, 50, 0.6, "top bottom", "bottom bottom");
  
        gsapAlphaYInAnimation(props.gsap, ourMemberContentRef, ourMemberContentRef, 0, 30, 0.5, 0.4, 0, "bottom bottom", "bottom bottom");
  
      });
      return () => ctx.revert();
    }, []);
  
    
    const _ourMemberContentRef = el => {
      if (el && !ourMemberContentRef.current.includes(el)) {
        ourMemberContentRef.current.push(el);
      }
    };
    return(
        <>
         <section className="about-mission text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <h2 ref={ourMemberRef}>What drives us</h2>
                            <p ref={_ourMemberContentRef} className="mt-5">The team at Uterinekind is on a mission to accelerate research and bring doctors, researchers and patients together for better conversations that lead to faster diagnoses. Far too many people feel lost and are needlessly unwell for years. We’re 100% focused on changing this unhealthy dynamic.</p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <h3 ref={_ourMemberContentRef} className="primary-text-color mb-3 h4">Our expertise:</h3>
                            <div className="d-flex flex-column h5">
                                <span ref={_ourMemberContentRef}>Patient education</span>
                                <span ref={_ourMemberContentRef}>Physician education</span>
                                <span ref={_ourMemberContentRef}>Virtual and in-person events</span>
                                <span ref={_ourMemberContentRef}>Community development</span>
                                <span ref={_ourMemberContentRef}>Patient experience optimization</span>
                            </div>
                        </div>
                    </div>
                </div>

            </section> 
        </>
    )
}

export default Drive;