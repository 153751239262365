export const AXIOS_ERROR_CODE = {
    ERR_NETWORK: 'ERR_NETWORK',
    ECONNABORTED: 'ECONNABORTED',
    ERR_BAD_REQUEST: 'ERR_BAD_REQUEST',
    ERR_CANCELED: 'ERR_CANCELED',
}

export const RESPONSE_CODE = {
    200: 200,
    400: 400,
    404: 404,
    500: 500,
}

export const RESPONSE_MESSAGES = {
    FETCHING_EPISODES:`Getting error while fetching episodes.`,
    ERROR_MESSAGE:`Getting error while sending your request!`,
    UNKNOWN_ERROR_MESSAGE:`Getting error while sending your request. Please try again.`,
    TYPE_ERROR:`error`,
    TYPE_SUCCESS:`success`,
    NETWORK_ERROR:`Unable to connect with backend services.`,
    NOT_FOUND_404:`404: Service not found!.`,
    ECONNABORTED:`Getting too long to process your request. Pease try again.`,
    INTERNAL_SERVER_ERROR:`Internal server error!`,
}