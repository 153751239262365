import gsap from 'gsap';
import { _renderComplexString } from 'gsap/gsap-core';
import SplitType from 'split-type'
const ALPHA_DURATION_DEFAULT = 0.6
export function gsapWheardTitleAnimation(weHeardYouRef, textwords) {

    return gsap.timeline({
        scrollTrigger: {
            trigger: weHeardYouRef.current,
            start: "top 35%",
            end: "bottom top",
        }
    }).from(textwords, {
        opacity: 0,
        y: "30px",
        duration: 0.7,
        stagger: 0.7,
        rotationX: "45deg"
    });
}

export function gsapSplitTextAnimation(elementRef, triggerRef) {
    return gsap.timeline({
        scrollTrigger: {
            trigger: triggerRef.current,
            start: "top 40%",
            end: "bottom top",
        }
    }).from(
        new SplitType(elementRef.current).words, {
        opacity: 0,
        y: "30px",
        duration: 0.7,
        stagger: 0.7,
        rotationX: "45deg"
    });
}



/*
    *  
    @param: 
        *   gsap obj, 
        *   element Ref, 
        *   elementTrigger Ref, 
        *   opacity
        *   yPercent
        *   duration
        *   stagger
        *   start
        *   end
    *
*/

export function gsapSplitTextAnimationv2(_gsap, elementRef, triggerRef, _opacity, _ypercent, _duration, _stagger, _start, _end, _delay=0) {
    return _gsap.fromTo(new SplitType(elementRef.current).words, {
        duration: _duration,
        opacity: 0,
        yPercent: _ypercent,
        ease: "power4.out",
        stagger: _stagger,
        delay: _delay,
    }, {
        duration: _duration,
        opacity: _opacity,
        yPercent: 0,
        ease: "power4.out",
        stagger: _stagger,
        delay: _delay,
        scrollTrigger: {
            trigger: triggerRef.current,
            start: _start,
            end: _end,
            delay: _delay,
            // markers:true,
        }

    },);
}

/*
    *  
    @param: gsap obj, element Ref, ease, duration, opacity, x, delay
    *
*/
export function gsapTitleInXAnimation(_gsap, elementRef, _ease, _duration = null, _opacity, _X, _delay) {
    return _gsap.from(elementRef.current, {
        ease: _ease,
        duration: _duration,
        opacity: _opacity,
        x: _X,
        delay: _delay,
    });
}

/*
    *  
    @param: gsap obj, element Ref, ease, duration, opacity, y, delay
    *
*/
export function gsapTitleInYAnimation(_gsap, elementRef, _ease, _duration, _opacity, _Y, _delay) {
    _duration = (_duration === null || _duration === undefined) ? ALPHA_DURATION_DEFAULT : _duration
    return _gsap.from(elementRef.current, {
        ease: _ease,
        duration: _duration,
        opacity: _opacity,
        y: _Y,
        delay: _delay,
    });
}

/*
   *  
   @param: gsap obj, element Ref, autoAlpha value, ease, delay
   *
*/
export function gsapAlphaFadeInAnimation(_gsap, elementRef, _autoAlpha, _ease, _delay, _duration=1) {
    return _gsap.from(elementRef.current, {
        autoAlpha: _autoAlpha,
        ease: _ease,
        delay: _delay,
        duration:_duration
    });
}

/*
   *  
   @param: gsap obj, element Ref, triggerRef, autoAlpha value, x value, stagger, duration, delay
   *
*/
export function gsapAlphaXInAnimation(_gsap, elementRef, triggerRef, _autoAlpha, _X, _stagger, _duration, _delay) {
    return _gsap.from(elementRef.current, {
        scrollTrigger: {
            trigger: triggerRef.current,
        },
        autoAlpha: _autoAlpha,
        x: _X,
        stagger: _stagger,
        duration: _duration,
        delay: _delay
    });
}

/*
    *  
    @param: 
        *   gsap obj, 
        *   element Ref, 
        *   elementTrigger Ref, 
        *   opacity
        *   yPercent
        *   duration
    *
*/

export function gsapSplitTextLineAnimation(_gsap, elementRef, triggerRef, _opacity, _ypercent, _duration, _start = "top 40%", _end = "bottom bottom", _delay=0) {
    return _gsap.fromTo(elementRef.current, {
        opacity: 0,
        yPercent: _ypercent, 
        
    }, {
        duration: _duration, 
        opacity: _opacity, 
        yPercent: 0,
        delay:_delay,
        scrollTrigger: {
            trigger: triggerRef.current,
            start: _start,
            end: _end
        }
    },);
  }

/*
   *  
   @param: gsap obj, element Ref, triggerRef, autoAlpha value, y value, stagger, duration, delay, start, end
   *
*/
export function gsapAlphaYInAnimation(_gsap, elementRef, triggerRef, _autoAlpha, _Y, _stagger, _duration, _delay, _start, _end) {
    return _gsap.from(elementRef.current, {
        scrollTrigger: {
            trigger: triggerRef.current,
            start: _start,
            end: _end,
            markers: false,
        },
        autoAlpha: _autoAlpha,
        y: _Y,
        stagger: _stagger,
        duration: _duration,
        delay: _delay
    });
}

/*
   *  
   @param: gsap obj, element Ref, triggerRef, autoAlpha value, y value, stagger, duration, delay, start, end
   *
*/
export function gsapScrollToElementById(_gsap, element, _duration, _offsetY = 0) {
    return _gsap.to(window, {
        scrollTo: {
            y: element,
        },
        duration: _duration,
    });
}
