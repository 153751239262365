import { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import AboutHero from "./AboutHero"
import Research from './Research';
import Founder from './FounderSlider';
import Mission from './About-Mission';
import DeliveringMember from './Delivering-member';
import Team from './Team';
import Drive from './Drive-us';
import Testimonials from './Testimonials';
import AboutFounder from './About-content';
import WeHeard from "./WeHeard"
import HelpedCreate from "./HelpedCreate"
import ListOfThings from "./ListOfThings"
import WhatAboutResearch from "./WhatAboutResearch"
import HiBoss from './HiBoss';
import BeAmember from './BeAmember';
import './css/index.css'
import { SuperSEO } from "react-super-seo";


function AboutPage(props) {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            },
            duration: 0,
        });
    }, []);

    return <>
        <SuperSEO title="Uterinekind™ - About" />
        <SuperSEO description="Discover Uterinekind: Your go-to platform for tracking uterine health symptoms safely and easily. Empower yourself with knowledge, take control of your health data, and make informed decisions for improved health outcomes." />
      <Testimonials />
      <Drive gsap={gsap} />
      <Founder gsap={gsap} />
      <Team gsap={gsap} />
      <Research gsap={gsap} />
      <DeliveringMember gsap={gsap} />
      <Mission gsap={gsap} />
      
         {/* <HiBoss gsap={gsap} /> */}
        {/* <AboutHero gsap={gsap} /> */}
        {/* <WeHeard gsap={gsap} /> */}
        {/* <HelpedCreate gsap={gsap} /> */}
        {/* <ListOfThings gsap={gsap} /> */}
        {/* <WhatAboutResearch gsap={gsap} /> */}
        {/* <BeAmember gsap={gsap} /> */}
        
        
      
        
        
        
        
    </>
}

export default AboutPage