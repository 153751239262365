import { useEffect, useRef } from "react";

import AnatomyIcon from "../../../assets/images/Diagnosis_icon.svg";
import CC_Icon from "../../../assets/images/CC_Icon.svg";
import DiagnosisIcon from "../../../assets/images/Diagnosis_icon.svg";
import ResearchIcon from "../../../assets/images/Research_Icon_2.svg";
import OptionIcon from "../../../assets/images/Options_Icon.svg";

import Prepone from '../../../assets/images/prep-1.jpg';
import Preptwo from '../../../assets/images/prep-2.jpg';
import Prepthree from '../../../assets/images/prep-3.jpg';

import DiaryShot from '../../../assets/images/dairy-shot.png';

import Slider from "react-slick";
import NextArrow from "../HomePage/SampleNextArrow";
import PrevArrow from "../HomePage/SamplePrevArrow";
import pdffile from "../../../assets/documents/Doc-Report-8_sample.pdf"
function FourSectionOfApp(props) {

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            
                let timelineNavidate = props.gsap.timeline({
                    scrollTrigger: {
                        trigger: ".navigateSection",
                        start: "t0p 75%",
                    }
                });
    
                timelineNavidate
                    .from(".navigateSectionTitle", {
                        duration: 1,
                        y: 50,
                        autoAlpha: 0,
                        ease: "Power4.easeOut"
                    }, 0)
                    .from(".myBody", {
                        duration: 1,
                        y: 50,
                        autoAlpha: 0,
                        ease: "Power4.easeOut",
                        delay:0.5
                    }, 0)
                    .from(".myDiary", {
                        duration: 1,
                        y: 50,
                        autoAlpha: 0,
                        ease: "Power4.easeOut",
                        delay:1
                    }, 0)
                    .from(".myLibrary", {
                        duration: 1,
                        y: 50,
                        autoAlpha: 0,
                        ease: "Power4.easeOut",
                        delay:1.5
                    }, 0)
                    .from(".myPrep", {
                        duration: 1,
                        y: 50,
                        autoAlpha: 0,
                        ease: "Power4.easeOut",
                        delay:2
                    }, 0)
                    .from(".descriptionSection", {
                        duration: 1,
                        autoAlpha: 0,
                        ease: "Power4.easeOut",
                        delay:2.5
                    }, 0)
        });
        
        return () => ctx.revert();
    }, []);


    return <>
        <section className='section-navigator text-center navigateSection'>
            <div className='container container-sm'>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='mb-1 navigateSectionTitle'>Navigate to the four sections of the app using the buttons located at the bottom of your screen:</p>
                        <p className='mb-1'>
                            <span className="myBody">My Body,</span>
                            <span className="myDiary"> Diary,</span>
                            <span className="myLibrary"> Library</span>
                            <span className="myPrep"> and Prep</span>
                        </p>
                        <div className='section-navigator__wrapper mt-5'>
                            <div className='section-navigator__items myBody'>
                                <a href={() => {return false}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="42.93" height="44.93" viewBox="0 0 42.93 44.93">
                                        <path id="MyBody" d="M54.5,54.04H17.49a3.034,3.034,0,0,1-3.03-3.03V46.68L22,27.92c.45-1.11.83-1.27.83-1.28.02.01.4.2.76,1.34l4.49,14.05c.69,2.16,1.72,2.63,2.47,2.65.73.04,1.8-.41,2.59-2.54l3.54-9.62c.37-1,.82-1.38,1.09-1.4s.77.29,1.27,1.23l2.26,4.2c1.11,2.05,2.41,2.28,3.09,2.23s1.93-.46,2.73-2.65l6.29-17.3a.959.959,0,0,0-1.8-.66l-6.29,17.3c-.36,1-.81,1.38-1.07,1.4-.29.02-.76-.29-1.27-1.23l-2.26-4.2c-1.11-2.05-2.41-2.28-3.09-2.23s-1.94.46-2.75,2.64l-3.54,9.62a3.863,3.863,0,0,1-.71,1.3c-.09-.04-.42-.36-.73-1.33L25.41,27.39c-.69-2.15-1.74-2.64-2.5-2.67s-1.84.38-2.69,2.48L14.45,41.55V11.99a.96.96,0,0,0-1.92,0V51.01a4.955,4.955,0,0,0,4.95,4.95H54.5a.96.96,0,0,0,0-1.92Z" transform="translate(-12.53 -11.03)" fill="#d1d7ff" />
                                    </svg>
                                    <span>My Body</span></a>
                            </div>

                            <div className='section-navigator__items myDiary'>
                                <a href="javascript:void(0)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="42.09" height="48.92" viewBox="0 0 42.09 48.92">
                                        <g id="diary-icon" transform="translate(-14.54 -7.04)">
                                            <path id="Path_13510" data-name="Path 13510" d="M56.6,24.04H53.46V11.99a4.955,4.955,0,0,0-4.95-4.95H19.49a4.955,4.955,0,0,0-4.95,4.95V51.01a4.955,4.955,0,0,0,4.95,4.95H48.51a4.955,4.955,0,0,0,4.95-4.95V38.96h2.19l.98-.03V25.03l-.03-.98ZM51.55,51.01a3.034,3.034,0,0,1-3.03,3.03H19.49a3.034,3.034,0,0,1-3.03-3.03V11.99a3.034,3.034,0,0,1,3.03-3.03H21.5V19.5l5.5-4,5.5,4V8.96H48.51a3.034,3.034,0,0,1,3.03,3.03V24.04H39.94a6.406,6.406,0,0,0-6.4,6.4v2.1a6.406,6.406,0,0,0,6.4,6.4h11.6V50.99Zm3.17-13.97H39.96a4.49,4.49,0,0,1-4.49-4.49v-2.1a4.49,4.49,0,0,1,4.49-4.49H54.72V37.05Z" fill="#d1d7ff" />
                                            <path id="Path_13511" data-name="Path 13511" d="M45.86,35.5l-.98-4a2.18,2.18,0,1,0-2.4,0l-.98,4Z" fill="#d1d7ff" />
                                        </g>
                                    </svg>
                                    <span>Diary</span></a>
                            </div>

                            <div className='section-navigator__items myLibrary'>
                                <a href="javascript:void(0)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="42.91" height="48.83" viewBox="0 0 42.91 48.83">
                                        <g id="content-icon" transform="translate(-11.55 -6.7)">
                                            <path id="Path_13498" data-name="Path 13498" d="M19.29,44.81H15.74a2.279,2.279,0,0,1-2.28-2.28V10.89a2.279,2.279,0,0,1,2.28-2.28H39.27a2.279,2.279,0,0,1,2.28,2.28v2.67a.96.96,0,1,0,1.92,0V10.89A4.2,4.2,0,0,0,39.28,6.7H15.74a4.2,4.2,0,0,0-4.19,4.19V42.53a4.2,4.2,0,0,0,4.19,4.19h3.55a.96.96,0,1,0,0-1.92Z" fill="#d0d6ff" />
                                            <path id="Path_13499" data-name="Path 13499" d="M38.43,13.74a.96.96,0,0,0-.96-.96H16.55a.96.96,0,1,0,0,1.92H37.47A.96.96,0,0,0,38.43,13.74Z" fill="#d0d6ff" />
                                            <path id="Path_13500" data-name="Path 13500" d="M19.09,19.27H16.55a.96.96,0,1,0,0,1.92h2.54a.96.96,0,1,0,0-1.92Z" fill="#d0d6ff" />
                                            <path id="Path_13501" data-name="Path 13501" d="M19.09,25.75H16.55a.96.96,0,1,0,0,1.92h2.54a.96.96,0,1,0,0-1.92Z" fill="#d0d6ff" />
                                            <path id="Path_13502" data-name="Path 13502" d="M19.09,32.24H16.55a.96.96,0,1,0,0,1.92h2.54a.96.96,0,0,0,0-1.92Z" fill="#d0d6ff" />
                                            <path id="Path_13503" data-name="Path 13503" d="M19.09,38.73H16.55a.96.96,0,1,0,0,1.92h2.54a.96.96,0,0,0,0-1.92Z" fill="#d0d6ff" />
                                            <path id="Path_13504" data-name="Path 13504" d="M50.26,17.51H26.74a4.2,4.2,0,0,0-4.19,4.19V51.34a4.2,4.2,0,0,0,4.19,4.19H50.27a4.2,4.2,0,0,0,4.19-4.19V21.7a4.2,4.2,0,0,0-4.19-4.19Zm2.28,33.83a2.279,2.279,0,0,1-2.28,2.28H26.74a2.279,2.279,0,0,1-2.28-2.28V21.7a2.279,2.279,0,0,1,2.28-2.28H50.27a2.279,2.279,0,0,1,2.28,2.28V51.34Z" fill="#d0d6ff" />
                                            <path id="Path_13505" data-name="Path 13505" d="M27.55,25.12h8.92a.96.96,0,1,0,0-1.92H27.55a.96.96,0,1,0,0,1.92Z" fill="#d0d6ff" />
                                            <path id="Path_13506" data-name="Path 13506" d="M27.55,31.61h8.11a.96.96,0,1,0,0-1.92H27.55a.96.96,0,1,0,0,1.92Z" fill="#d0d6ff" />
                                            <path id="Path_13507" data-name="Path 13507" d="M49.45,35.18H27.55a.96.96,0,1,0,0,1.92H49.44a.96.96,0,0,0,0-1.92Z" fill="#d0d6ff" />
                                            <path id="Path_13508" data-name="Path 13508" d="M48.09,22.38h-6.2a2.322,2.322,0,0,0-2.32,2.32v5.2a2.322,2.322,0,0,0,2.32,2.32h6.2a2.322,2.322,0,0,0,2.32-2.32V24.7a2.322,2.322,0,0,0-2.32-2.32Zm.4,7.51a.4.4,0,0,1-.4.4h-6.2a.4.4,0,0,1-.4-.4v-5.2a.4.4,0,0,1,.4-.4h6.2a.4.4,0,0,1,.4.4Z" fill="#d0d6ff" />
                                            <path id="Path_13509" data-name="Path 13509" d="M48,40.84H29a2.407,2.407,0,0,0-2.4,2.4v5.84a2.407,2.407,0,0,0,2.4,2.4H48a2.407,2.407,0,0,0,2.4-2.4V43.24A2.407,2.407,0,0,0,48,40.84Zm.49,8.24a.491.491,0,0,1-.49.49H29a.491.491,0,0,1-.49-.49V43.24a.491.491,0,0,1,.49-.49H48a.491.491,0,0,1,.49.49Z" fill="#d0d6ff" />
                                        </g>
                                    </svg>

                                    <span>Library</span></a>
                            </div>

                            <div className='section-navigator__items myPrep'>
                                <a href="javascript:void(0)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36.92" height="48.92" viewBox="0 0 36.92 48.92">
                                        <g id="Prep_Icon" data-name="Prep Icon" transform="translate(-12.54 -7.54)">
                                            <path id="Path_13512" data-name="Path 13512" d="M44.51,7.54H17.49a4.955,4.955,0,0,0-4.95,4.95V51.51a4.955,4.955,0,0,0,4.95,4.95H44.51a4.955,4.955,0,0,0,4.95-4.95V12.49a4.955,4.955,0,0,0-4.95-4.95Zm3.03,43.97a3.034,3.034,0,0,1-3.03,3.03H17.49a3.034,3.034,0,0,1-3.03-3.03V12.49a3.034,3.034,0,0,1,3.03-3.03H44.51a3.034,3.034,0,0,1,3.03,3.03V51.51Z" fill="#d0d6ff" />
                                            <g id="Group_220" data-name="Group 220">
                                                <path id="Path_13513" data-name="Path 13513" d="M34.23,17.93l-4.5,6.1L27.7,21.85a.955.955,0,1,0-1.4,1.3l3.6,3.87,5.87-7.95a.958.958,0,0,0-1.54-1.14Z" fill="#d0d6ff" />
                                                <path id="Path_13514" data-name="Path 13514" d="M35.75,13.54H26.24a3.7,3.7,0,0,0-3.7,3.7v9.51a3.7,3.7,0,0,0,3.7,3.7h9.51a3.7,3.7,0,0,0,3.7-3.7V17.24A3.7,3.7,0,0,0,35.75,13.54Zm1.79,13.21a1.788,1.788,0,0,1-1.79,1.79H26.24a1.788,1.788,0,0,1-1.79-1.79V17.24a1.788,1.788,0,0,1,1.79-1.79h9.51a1.788,1.788,0,0,1,1.79,1.79Z" fill="#d0d6ff" />
                                            </g>
                                            <g id="Group_221" data-name="Group 221">
                                                <path id="Path_13515" data-name="Path 13515" d="M34.23,37.9l-4.5,6.11L27.7,41.82a.955.955,0,0,0-1.4,1.3L29.9,47l5.87-7.97a.958.958,0,1,0-1.54-1.14Z" fill="#d0d6ff" />
                                                <path id="Path_13516" data-name="Path 13516" d="M35.75,33.5H26.24a3.713,3.713,0,0,0-3.7,3.71v9.53a3.707,3.707,0,0,0,3.7,3.71h9.51a3.713,3.713,0,0,0,3.7-3.71V37.21A3.707,3.707,0,0,0,35.75,33.5Zm1.79,13.24a1.8,1.8,0,0,1-1.79,1.8H26.24a1.8,1.8,0,0,1-1.79-1.8V37.21a1.8,1.8,0,0,1,1.79-1.8h9.51a1.8,1.8,0,0,1,1.79,1.8Z" fill="#d0d6ff" />
                                            </g>
                                        </g>
                                    </svg>

                                    <span>Prep</span></a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='diary-section spacer-both descriptionSection'>
            <div className='container container-sm'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h2 className='h4 primary-text-color mb-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="42.09" height="48.92" viewBox="0 0 42.09 48.92" className='me-2'>
                                <g id="diary-icon" transform="translate(-14.54 -7.04)">
                                    <path id="Path_13510" data-name="Path 13510" d="M56.6,24.04H53.46V11.99a4.955,4.955,0,0,0-4.95-4.95H19.49a4.955,4.955,0,0,0-4.95,4.95V51.01a4.955,4.955,0,0,0,4.95,4.95H48.51a4.955,4.955,0,0,0,4.95-4.95V38.96h2.19l.98-.03V25.03l-.03-.98ZM51.55,51.01a3.034,3.034,0,0,1-3.03,3.03H19.49a3.034,3.034,0,0,1-3.03-3.03V11.99a3.034,3.034,0,0,1,3.03-3.03H21.5V19.5l5.5-4,5.5,4V8.96H48.51a3.034,3.034,0,0,1,3.03,3.03V24.04H39.94a6.406,6.406,0,0,0-6.4,6.4v2.1a6.406,6.406,0,0,0,6.4,6.4h11.6V50.99Zm3.17-13.97H39.96a4.49,4.49,0,0,1-4.49-4.49v-2.1a4.49,4.49,0,0,1,4.49-4.49H54.72V37.05Z" fill="#13A5DF" />
                                    <path id="Path_13511" data-name="Path 13511" d="M45.86,35.5l-.98-4a2.18,2.18,0,1,0-2.4,0l-.98,4Z" fill="#13A5DF" />
                                </g>
                            </svg> Diary
                        </h2>
                        <p>Your diary is the place to capture your experience in your own words. You can choose to include entries in the Symptom Summary Report that you share with your care teams.</p>
                    </div>
                    <div className='col-md-6 text-center'>
                        <img src={DiaryShot} alt="diary" />
                    </div>
                </div>
            </div>
        </section>


        <section className='library-section descriptionSection'>
            <div className='container container-sm'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h2 className='h4 primary-text-color mb-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="42.91" height="48.83" viewBox="0 0 42.91 48.83" className='me-3'>
                                <g id="content-icon" transform="translate(-11.55 -6.7)">
                                    <path id="Path_13498" data-name="Path 13498" d="M19.29,44.81H15.74a2.279,2.279,0,0,1-2.28-2.28V10.89a2.279,2.279,0,0,1,2.28-2.28H39.27a2.279,2.279,0,0,1,2.28,2.28v2.67a.96.96,0,1,0,1.92,0V10.89A4.2,4.2,0,0,0,39.28,6.7H15.74a4.2,4.2,0,0,0-4.19,4.19V42.53a4.2,4.2,0,0,0,4.19,4.19h3.55a.96.96,0,1,0,0-1.92Z" fill="#13A5DF" />
                                    <path id="Path_13499" data-name="Path 13499" d="M38.43,13.74a.96.96,0,0,0-.96-.96H16.55a.96.96,0,1,0,0,1.92H37.47A.96.96,0,0,0,38.43,13.74Z" fill="#13A5DF" />
                                    <path id="Path_13500" data-name="Path 13500" d="M19.09,19.27H16.55a.96.96,0,1,0,0,1.92h2.54a.96.96,0,1,0,0-1.92Z" fill="#13A5DF" />
                                    <path id="Path_13501" data-name="Path 13501" d="M19.09,25.75H16.55a.96.96,0,1,0,0,1.92h2.54a.96.96,0,1,0,0-1.92Z" fill="#13A5DF" />
                                    <path id="Path_13502" data-name="Path 13502" d="M19.09,32.24H16.55a.96.96,0,1,0,0,1.92h2.54a.96.96,0,0,0,0-1.92Z" fill="#13A5DF" />
                                    <path id="Path_13503" data-name="Path 13503" d="M19.09,38.73H16.55a.96.96,0,1,0,0,1.92h2.54a.96.96,0,0,0,0-1.92Z" fill="#13A5DF" />
                                    <path id="Path_13504" data-name="Path 13504" d="M50.26,17.51H26.74a4.2,4.2,0,0,0-4.19,4.19V51.34a4.2,4.2,0,0,0,4.19,4.19H50.27a4.2,4.2,0,0,0,4.19-4.19V21.7a4.2,4.2,0,0,0-4.19-4.19Zm2.28,33.83a2.279,2.279,0,0,1-2.28,2.28H26.74a2.279,2.279,0,0,1-2.28-2.28V21.7a2.279,2.279,0,0,1,2.28-2.28H50.27a2.279,2.279,0,0,1,2.28,2.28V51.34Z" fill="#13A5DF" />
                                    <path id="Path_13505" data-name="Path 13505" d="M27.55,25.12h8.92a.96.96,0,1,0,0-1.92H27.55a.96.96,0,1,0,0,1.92Z" fill="#13A5DF" />
                                    <path id="Path_13506" data-name="Path 13506" d="M27.55,31.61h8.11a.96.96,0,1,0,0-1.92H27.55a.96.96,0,1,0,0,1.92Z" fill="#13A5DF" />
                                    <path id="Path_13507" data-name="Path 13507" d="M49.45,35.18H27.55a.96.96,0,1,0,0,1.92H49.44a.96.96,0,0,0,0-1.92Z" fill="#13A5DF" />
                                    <path id="Path_13508" data-name="Path 13508" d="M48.09,22.38h-6.2a2.322,2.322,0,0,0-2.32,2.32v5.2a2.322,2.322,0,0,0,2.32,2.32h6.2a2.322,2.322,0,0,0,2.32-2.32V24.7a2.322,2.322,0,0,0-2.32-2.32Zm.4,7.51a.4.4,0,0,1-.4.4h-6.2a.4.4,0,0,1-.4-.4v-5.2a.4.4,0,0,1,.4-.4h6.2a.4.4,0,0,1,.4.4Z" fill="#13A5DF" />
                                    <path id="Path_13509" data-name="Path 13509" d="M48,40.84H29a2.407,2.407,0,0,0-2.4,2.4v5.84a2.407,2.407,0,0,0,2.4,2.4H48a2.407,2.407,0,0,0,2.4-2.4V43.24A2.407,2.407,0,0,0,48,40.84Zm.49,8.24a.491.491,0,0,1-.49.49H29a.491.491,0,0,1-.49-.49V43.24a.491.491,0,0,1,.49-.49H48a.491.491,0,0,1,.49.49Z" fill="#13A5DF" />
                                </g>
                            </svg> Library
                        </h2>
                        <p>The Library features a wide variety of content in various formats (articles, podcasts, stories, comprehensive curriculum on chronic conditions, and more) and allows for the building of your own personal library.</p>
                    </div>
                    <div className='col-md-6 text-center'>
                        <div className='library-grid'>
                            <div className='library-grid__outer'>
                                <div className='library-grid__blk library-grid__first'>
                                    <img src={AnatomyIcon} alt='AnatomyIcon' />
                                    <span>Your anatomy</span>
                                </div>
                            </div>
                            <div className='library-grid__outer'>

                                <div className='library-grid__blk library-grid__second'>
                                    <img src={CC_Icon} alt='Chronic conditions' />

                                    <span>Chronic conditions</span>
                                </div>
                            </div>
                            <div className='library-grid__outer'>
                                <div className='library-grid__blk library-grid__third'>
                                    <img src={DiagnosisIcon} alt='Getting a diagnosis' />
                                    <span>Getting a diagnosis</span>
                                </div>
                            </div>
                            {/* <div className='library-grid__outer'>
                                <div className='library-grid__blk library-grid__fourth'>
                                    <img src={OptionIcon} alt='Your options' />
                                    <span>Your options</span>
                                </div>
                            </div> */}
                            <div className='library-grid__outer'>
                                <div className='library-grid__blk library-grid__fifth'>
                                    <img src={ResearchIcon} alt='Research & studies' />
                                    <span>Research & studies</span>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='prep spacer-both descriptionSection'>
            <div className='container container-sm'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h2 className='h4 primary-text-color mb-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="36.92" height="48.92" viewBox="0 0 36.92 48.92" className='me-3'>
                                <g id="Prep_Icon" data-name="Prep Icon" transform="translate(-12.54 -7.54)">
                                    <path id="Path_13512" data-name="Path 13512" d="M44.51,7.54H17.49a4.955,4.955,0,0,0-4.95,4.95V51.51a4.955,4.955,0,0,0,4.95,4.95H44.51a4.955,4.955,0,0,0,4.95-4.95V12.49a4.955,4.955,0,0,0-4.95-4.95Zm3.03,43.97a3.034,3.034,0,0,1-3.03,3.03H17.49a3.034,3.034,0,0,1-3.03-3.03V12.49a3.034,3.034,0,0,1,3.03-3.03H44.51a3.034,3.034,0,0,1,3.03,3.03V51.51Z" fill="#13A5DF" />
                                    <g id="Group_220" data-name="Group 220">
                                        <path id="Path_13513" data-name="Path 13513" d="M34.23,17.93l-4.5,6.1L27.7,21.85a.955.955,0,1,0-1.4,1.3l3.6,3.87,5.87-7.95a.958.958,0,0,0-1.54-1.14Z" fill="#13A5DF" />
                                        <path id="Path_13514" data-name="Path 13514" d="M35.75,13.54H26.24a3.7,3.7,0,0,0-3.7,3.7v9.51a3.7,3.7,0,0,0,3.7,3.7h9.51a3.7,3.7,0,0,0,3.7-3.7V17.24A3.7,3.7,0,0,0,35.75,13.54Zm1.79,13.21a1.788,1.788,0,0,1-1.79,1.79H26.24a1.788,1.788,0,0,1-1.79-1.79V17.24a1.788,1.788,0,0,1,1.79-1.79h9.51a1.788,1.788,0,0,1,1.79,1.79Z" fill="#13A5DF" />
                                    </g>
                                    <g id="Group_221" data-name="Group 221">
                                        <path id="Path_13515" data-name="Path 13515" d="M34.23,37.9l-4.5,6.11L27.7,41.82a.955.955,0,0,0-1.4,1.3L29.9,47l5.87-7.97a.958.958,0,1,0-1.54-1.14Z" fill="#13A5DF" />
                                        <path id="Path_13516" data-name="Path 13516" d="M35.75,33.5H26.24a3.713,3.713,0,0,0-3.7,3.71v9.53a3.707,3.707,0,0,0,3.7,3.71h9.51a3.713,3.713,0,0,0,3.7-3.71V37.21A3.707,3.707,0,0,0,35.75,33.5Zm1.79,13.24a1.8,1.8,0,0,1-1.79,1.8H26.24a1.8,1.8,0,0,1-1.79-1.8V37.21a1.8,1.8,0,0,1,1.79-1.8h9.51a1.8,1.8,0,0,1,1.79,1.8Z" fill="#13A5DF" />
                                    </g>
                                </g>
                            </svg> Prep
                        </h2>
                        <p>Your detailed symptoms data becomes part of your Symptoms Summary Report, which is generated in the PREP section. This is the report you can download and share with your care team. (Let your data do your talking.)</p>
                        <p>Prep also contains an appointment checklist, interactive appointment discussion guides, your personal library, and it is where you go to generate and download your Symptoms Summary Report to be used with your care teams.</p>
                        <a download="sample-report" href={pdffile} className='btn mb-4 mb-md-0'>Download sample PDF</a>
                    </div>
                    <div className='col-md-6 text-center'>
                        <Slider arrows={true} fade={true} infinite={false} nextArrow={<NextArrow />}
                            prevArrow={
                                <PrevArrow />}
                        >
                            <div className='prep__blk'>
                                <p>Symptoms Summary Report Page 1</p>
                                <img src={Prepone} alt='Symptoms Summary Report Page 1' />
                            </div>
                            <div>
                                <div className='prep__blk'>
                                    <p>Symptoms Summary Report Page 2</p>
                                    <img src={Preptwo} alt='Symptoms Summary Report Page 2' />
                                </div>
                            </div>
                            <div className='prep__blk'>
                                <p>Symptoms Summary Report Page 3</p>
                                <img src={Prepthree} alt='Symptoms Summary Report Page 3' />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    </>
}
export default FourSectionOfApp;