import React, { Component } from 'react';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import thumbnail from '../../../assets/images/Introvideo-thumbnail.jpg'
import { VIDEO_URL } from '../../../utits/staticContent/HomePage';
const sources = {
  introVideo: VIDEO_URL
};

export default class VideoPlayer extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      source: sources.introVideo,
      isEnded: false
    };

  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }


  handleStateChange(state) {

    if (state.ended) {
      this.setState({ isEnded: true });
    } else {
      this.setState({ isEnded: false });
    }

    this.setState({
      player: state
    });
  }

  render() {
    return (
      <div className='text-center'>
        <Player
          ref={player => {
            this.player = player;
          }}
          poster={thumbnail}
        >
          <source src={this.state.source} />
            <BigPlayButton className="btn" position="center" />
          <ControlBar autoHide={false} />
        </Player>

        {this.state.isEnded ? <a class="btn mt-5 btn-lg" href="https://app.uterinekind.com/en/body" >Join now - free trial!</a> : ''}
      </div>
      
    );
  }
}