import React, {useEffect, useRef} from 'react'
import Playstore from "../../../images/store-google.png";
import Applestore from "../../../images/store-apple.png";


function Trail({props, showButtonsOnly, force, noSpacer}) {
    let buttonTitle, postText;
    if (showButtonsOnly) {
        buttonTitle = '';
    } else {
        buttonTitle =
            <h4 className="font-weight-300">Everything you need to navigate the path to diagnosis, treatment, and
                recovery is here.</h4>;
    }
    if (force) {
        postText = '';
    } else {
        postText =
            <ul className="list-unstyled features-lisitng mt-2">
                <li>100% free to use. <br />No paywalls. <br />No ads.</li>
            </ul>;
    }
    return (
        <section className="text-center spacer-sm-bottom trail">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-7 mx-auto mt-3">
                        {/*<div className="section__seprator w-75 mx-auto"></div>*/}
                        {buttonTitle}
                        <div className="store-links d-flex justify-content-center my-4">
                            <a className="me-3" target="_blank"
                               href='https://play.google.com/store/apps/details?id=app.vercel.uterinekind_manifest.twa&utm_source=website_trail&utm_medium=click&utm_campaign=app_android_download'><img
                                alt='Get it on Google Play' src={Playstore}/></a>
                            <a target="_blank" className="me-3"
                               href="https://apps.apple.com/us/app/uterinekind-app/id6446996869?itsct=apps_box_link&itscg=30200&utm_source=website_trail&utm_medium=click&utm_campaign=app_ios_download">
                                <img alt='Now available in the Apple Store' src={Applestore}/>
                            </a>
                        </div>
                        {postText}
                        {/*<p className="mt-2">*No credit card required</p>*/}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Trail;
