import {useEffect, useRef} from 'react';
import './css/home-contentgrid.css';
import sticky from '../../../../src/images/sticky.png';

function Conditions(props) {

    return (
        <section className="spacer text-white sticky-note">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <div className="px-0 px-md-5">
                            <h4 className='font-weight-bold'>Symptoms associated with “bad periods” are also symptoms of
                                these common chronic conditions:</h4>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="px-0 px-md-5">

                            <div className='symptoms-items my-4'>
                                <ul>
                                    <li>AUB (Abnormal Uterine Bleeding)</li>
                                    <li>Endometriosis</li>
                                    <li>Fibroids</li>
                                    <li>Adenomyosis</li>
                                    <li>PCOS (Polycystic Ovary Syndrome)</li>
                                    <li>PID (Pelvic Inflammatory Disease)</li>
                                    <li>Chronic pelvic pain</li>
                                    <li>Uterine prolapse</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-5 mb-md-0 align-content-md-start">
                        <img src={sticky} alt="sticky"/>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <div className="px-0 px-md-5">
                            <p className='mt-5 mb-4'>The app features a library of content on these conditions that affect
                                millions of people who struggle for years to get properly diagnosed and treated.</p>
                            <p className='mb-0'>With Uterinekind, deepen the connection you have with your body and be
                                more informed to confidently advocate for your needs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Conditions;
