import React, {useEffect, useRef} from 'react';
import ExternalLink from "../../other/ExternalLink";

import './css/HomePage.css';
import './css/tracking.css';

import Applestore from '../../../../src/images/store-apple.png';
import Playstore from '../../../../src/images/store-google.png';

import appimage from '../../../../src/images/app-shot.png';

function Tracking(props) {
    return (
        <>
            <section className='hero home-hero symptoms-tracking overflow-visible'>
                <div className="container container-default position-relative">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className="text-white headline">“Bad periods”<span>is not a diagnosis</span></h1>
                            <div className="mobile-view d-block d-md-none text-center">
                                <img src={appimage}/>
                            </div>
                            <div className="my-4 symptoms-tracking__content">
                                <p>Symptoms of chronic conditions are often dismissed as “bad periods”. Easily track your full-body symptoms and provide the data your care team needs.</p>
                            </div>

                            <div className="store-links d-flex mt-5">
                                <a target="_blank" className="me-3"
                                   href="https://play.google.com/store/apps/details?id=app.vercel.uterinekind_manifest.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                    <img alt="Get it on Google Play" src={Playstore}/>
                                </a>
                                <ExternalLink href="https://apps.apple.com/us/app/uterinekind-app/id6446996869?itsct=apps_box_link&itscg=30200">
                                    <img alt='Get it on the Apple Store' src={Applestore}/>
                                </ExternalLink>
                            </div>

                            <ul className="list-unstyled features-lisitng mt-5">
                                <li>100% free to use. <br />No paywalls. <br />No ads.</li>
                            </ul>

                        </div>
                        <div className="col-md-6 d-none d-md-block">
                            <img src={appimage}/>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default Tracking;