import React, { useEffect, useRef } from "react"
import { gsapScrollToElementById, gsapSplitTextLineAnimation } from '../../gsap/common'

function NoneOFTrue(props) {
    const noneOfThatIsRef = useRef([]);

    useEffect(() => {
        const ctx = props.gsap.context(() => {

            /*  
                @param: 
                    *   gsap obj, 
                    *   element Ref, 
                    *   elementTrigger Ref, 
                    *   opacity
                    *   yPercent
                    *   duration
                *
            */
            gsapSplitTextLineAnimation(props.gsap, noneOfThatIsRef, noneOfThatIsRef,
                    1, 0, 2, "top center", "top bottom");

            
        });
        return () => ctx.revert();
    }, []);

    const handleClick = (elementId) => {
        gsapScrollToElementById(props.gsap, elementId, 1, -20)
    }
    
    return <>
        <section ref={noneOfThatIsRef} className="content-sec position-relative" id="noneoftrue">
            <div className="container">
                <div className="row">
                    <div className="col-md-11 mx-auto text-center">
                        <h2 className="h1">None of that<br/> is true</h2>
                        <p className="h4 mt-5">Period cycles aren't a spa vacation, but they're not supposed to wreck your life. When they do, it's a sign that something is out of balance.
                        </p>
                        <a className="mouse-scroll" onClick={() => handleClick("#commntDoesntMean")}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35.31" height="71.832" viewBox="0 0 35.31 71.832">
                                <g id="mouse-scroll" transform="translate(-4.25 -4.78)">
                                    <rect id="Rectangle_547" data-name="Rectangle 547" width="33.31" height="56.04" rx="16.65" transform="translate(5.25 5.78)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                                    <line id="Line_427" className="animate-line" data-name="Line 427" y2="6.04" transform="translate(21.9 17.22)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                                    <path id="Path_13338" data-name="Path 13338" d="M32.36,69.31l-9.71,6.12-9.71-6.12" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default NoneOFTrue;