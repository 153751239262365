import {React, useEffect, useRef} from "react";
import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import Slider from "react-slick";
import Newsone from "../../../../assets/images/news-1.jpg";
import Pressthumb from '../../../../assets/images/press-article.png';
import "../css/index.css";
import Newsheadshot from "../../../../assets/images/John-Petrozza.jpg";
import Newsthree from "../../../../assets/images/news-3.jpg";
import {useNavigate} from "react-router-dom";

function NewsDetailsPage() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 3,
    centerMode: false,
    // initialSlide:1,
    //centerPadding:"200px",
    arrows: false,
    afterChange: () =>
      document
        .querySelector(".more-news__slider .slick-list")
        .classList.add("slick-padding"),
  };
  let navigate = useNavigate();
  function redirectToNewsPage() {
    let path = `/news`;
    navigate(path);
  }
  return (
    <>
      <section className="hero-shadow hero-section-spacer spacer-bottom  purple-shadow news-detail">
        <div className="container container-sm position-relative">
            <div className="row">
                <div className="col-md-10 mx-auto">
                  <a onClick={redirectToNewsPage} href={() => false} className='btn back-btn mb-5' style={{ cursor: "pointer" }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" fill="rgba(255,255,255,1)" /></svg> Back
                  </a>
                <div className="row pb-5">
            <div className="col-md-12">
              <div className="news-info mb-2">
                <span className="ps-0">Press Release</span>
                <span className="fw-normal">January 23, 2023</span>
              </div>
              <h2 className="h4 primary-text-color">BOSTON, MA</h2>
              <p>
                Uterinekind Launches U by Uterinekind, An Educational Platform
                Helping Transform Uterine Healthcare from the Palm of Your Hands
              </p>
              <p className="fst-italic">
                New comprehensive app helps consumers monitor, measure, and
                connect the dots on symptoms they experience, which may be
                associated with chronic conditions that affect those born with a
                uterus
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>
                On average, at least one in ten people born with a uterus will
                suffer from <a className="text-decoration-underline hover-color" href="https://www.womenshealth.gov/a-z-topics/endometriosis">one or more debilitating chronic conditions</a> that
                primarily impacts the female body, such as Endometriosis,
                Fibroids, Polycystic Ovarian Syndrome (PCOS), and chronic pelvic
                pain. These conditions remain understudied, underdiagnosed, and
                under researched. The need for more resources and support is
                imperative for patients, and now there is a new way for them to
                get in control of their uterine health. Introducing <a className="text-decoration-underline hover-color" href="https://www.uterinekind.com/">U by
                Uterinekind</a>, a one-of-a-kind platform putting patients in the
                driver's seat of their care by helping create more
                self-awareness and education about common gynecologic
                conditions.
              </p>
              <p>
                People may wait years to receive a diagnosis due to lack of
                education, support, and stigma surrounding gynecologic care,
                especially among BIPOC communities. Symptoms are key to
                diagnosing a chronic uterine condition, but it can be daunting
                to fully comprehend what might be happening to you without the
                proper resources. U by Uterinekind provides its members with
                everything they need to navigate their path through diagnosis,
                treatment, and recovery. The app focuses on chronic conditions
                such as Endometriosis, PCOS, Abnormal Uterine Bleeding, Pelvic
                Inflammatory Disease, Uterine Prolapse and Chronic Pelvic Pain.
              </p>
              <p>
                “As someone who was misdiagnosed, I know the brick walls
                patients meet within the healthcare system when it comes to
                uterine care, and the casual way debilitating symptoms are
                dismissed,” said Carol Johnson, Founder and CEO of Uterinekind.
                “We chose to build an app that will help consumers have a clear
                understanding of their symptoms, conditions, and options, while
                also giving them the ability to share detailed, self-reported
                data with physicians to inform their care. We want patients to
                take control of their uterine health journey, and our ultimate
                goal for our platform is to help break down barriers to
                equitable healthcare in an effort to better serve everyone with
                a uterus.”
              </p>
              <p>
                U by Uterinekind’s platform is a place for users to be citizen
                scientists of their own bodies. Members can measure their own
                symptoms, watch how they trend over time, and see how their
                bodies are impacted. With this data, users can generate a
                comprehensive symptoms data report which is designed for
                patients to share with their physicians. Once treated for their
                conditions, members can monitor their medications and see how
                treatments are impacting symptoms. In addition to these
                user-friendly tools, the platform offers comprehensive resources
                including podcasts, blogs, research and uterine news, treatment
                advancements and more to provide members with a clearer
                understanding of uterine health as they continue their journey
                of self-advocacy.
              </p>
              <p>
                In addition to directly supporting people with uterine health
                education via their app, Uterinekind is also teaming up with
                industry-leading partners including The Cornell Ovary Lab by
                funding research that answers the most pressing questions about
                the female body and conditions like PCOS. Uterinekind will be
                donating 10% of every new membership to their partner
                laboratories, including The Cornell Ovary Lab.
              </p>
              <p>
                “We are happy to partner with Uterinekind and contribute to
                their efforts that enable the dissemination of scientifically
                grounded information on human health,” said Marla Lujan, PhD,
                Associate Professor in the division of Nutritional Sciences at
                Cornell University and PCOS researcher. “In sharing our findings
                and experiences as researchers, we hope to empower those living
                with reproductive health concerns to seek timely care and
                contribute to shared-decision making with their healthcare
                providers.”
              </p>
              <p>
                Diagnosing the cause of your chronic “bad periods” begins with
                the Uterinekind app. Free download and usage.
              </p>
              <p>
                To learn more or join U by Uterinekind, please visit <a className="text-decoration-underline hover-color" href="https://www.uterinekind.com/">https://www.uterinekind.com/.</a>
              </p>
              <div className="content-spacer">
              <h4>Uterinekind</h4>
              <p>
                U by Uterinekind aims to improve uterine care for everyone by
                helping those dealing with uterine conditions connect the dots
                on their symptoms. With that evidence at hand, users can
                seamlessly present data to their healthcare providers. Our goal
                is to accelerate the time from symptom onset to diagnosis for
                some of the world’s most debilitating and costly diseases, like
                PCOS, endometriosis, and fibroids. Increasing body and health
                literacy and supporting research will improve the experience of
                uterine care. 10% of all app purchases are donated directly to
                laboratories focusing on the research of conditions that
                primarily impact the female body. To learn more about how
                Uterinekind helps people become citizen scientists of their
                bodies, visit uterinekind.com and listen to the Hello Uterus
                podcast at <a className="text-decoration-underline hover-color" href="https://hello-uterus.simplecast.com/">https://hello-uterus.simplecast.com/</a>.
              </p>
              </div>
              <div className="content-spacer">
              <h4>The Cornell Ovary Lab</h4>
              <p>
                The Cornell Ovary Lab studies the interplay of nutrition,
                metabolism and ovarian function, with translational research
                efforts geared toward improving the timely diagnosis and
                management of ovulatory disorders, such as polycystic ovary
                syndrome (PCOS).
              </p>
              </div>
            </div>
          </div>
                </div>
            </div>
         
         
        </div>
      </section>

      {/* <section className="more-news spacer-bottom">
        <div className="container">
      <div className="row">
            <div className="col-md-12">
              <h2 className="h4">More news</h2>
            </div>
          </div>
          </div>
        <div className="more-news__slider">
        <div className="container-fluid g-0 mt-5">
          <div className="container">
          <div className="row g-0">
            <div className="col-md-12">
            <Slider {...settings}>
             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">1 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">2 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">3 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">4 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">5 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">6 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">7 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">8 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">9 News title goes here and this one is pretty long</a></h3>
             </div>

             <div className="more-news__items">             
              <a href="javascript:void(0)"><img src={Newsone} alt="John Petrozza" /></a>
                <div className="news-info mt-2">
                  <span className="ps-0">News Release</span>
                  <span className="fw-normal">January 23, 2023</span>
                </div>
                <h3 className="h4"><a href="javascript:void(0)" className="primary-text-color">10 News title goes here and this one is pretty long</a></h3>
             </div>
             
               
         </Slider>
         </div>
         </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default NewsDetailsPage;
