import { useEffect, useRef } from "react";
import gsap from "gsap";
import AboutFounder from "../About/About-content";
import { SuperSEO } from "react-super-seo";
function FounderPage(props) {
  const chooseYourRef = useRef([]);
  // gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  useEffect(() => {
    gsap.to(window, {
      scrollTo: {
        y: ".pageHeader",
      },
      duration: 0,
    });
  }, []);

  // useEffect(() => {
  //   const ctx = gsap.context(() => {});

  //   return () => ctx.revert();
  // }, []);

  return (
    <>
      <SuperSEO title="Uterinekind™ - Founder" />
      <SuperSEO description="Driven by founder Carol Johnson's own experiences with undiagnosed health conditions, Uterinekind emerges as a beacon of hope and empowerment. This platform reflects her commitment to transforming uterine care, offering support and accurate information to those navigating similar health challenges." />
      <AboutFounder />
    </>
  );
}

export default FounderPage;
