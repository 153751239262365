import ActivePodcastEpisode from "./ActivePodcastEpisode";
import PodcastLists from "./PodcastLists";
import LatestEpisode from "./LatestEpisode";
import { combineReducers } from "redux";

const allReducers = combineReducers({
    ActivePodcastEpisode:ActivePodcastEpisode,
    PodcastLists:PodcastLists,
    LatestEpisode:LatestEpisode,
})
export default allReducers;