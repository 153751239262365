import { useEffect, useRef } from "react";
import { gsapSplitTextLineAnimation, gsapAlphaYInAnimation } from "../../gsap/common";

function DeliveringMember( props ){
    const ourMemberRef = useRef([]);
    const ourMemberContentRef = useRef([]);
    ourMemberContentRef.current = [];
  
    useEffect(() => {
      const ctx = props.gsap.context(() => {
        
        gsapSplitTextLineAnimation(props.gsap, ourMemberRef, ourMemberRef,
          1, 50, 0.6, "top bottom", "bottom bottom");
  
        // gsapSplitTextLineAnimation(props.gsap, peterRef, peterRef,
        // 1, 50, 0.6, "top bottom", "bottom bottom");
  
        gsapAlphaYInAnimation(props.gsap, ourMemberContentRef, ourMemberContentRef, 0, 30, 0.5, 0.4, 0, "bottom bottom", "bottom bottom");
  
      });
      return () => ctx.revert();
    }, []);
  
    
    const _ourMemberContentRef = el => {
      if (el && !ourMemberContentRef.current.includes(el)) {
        ourMemberContentRef.current.push(el);
      }
    };

    return(
        <>
           <section className="about-mission spacer text-center">
                <div className="container">
                    <div className="row">
                        <div ref={ourMemberRef} className="col-md-9 mx-auto">
                            <h2>Delivering for our members</h2>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <div className="d-flex flex-column h5">
                                <span ref={_ourMemberContentRef}>Your data is not for sale</span>
                                {/*<span ref={_ourMemberContentRef}>No pharma or device industry influence</span>*/}
                                <span ref={_ourMemberContentRef}>Ad and sponsor-free content</span>
                                <span ref={_ourMemberContentRef}>Continuous development of new features</span>
                                <span ref={_ourMemberContentRef}>Fresh articles and podcasts weekly</span>
                            </div>
                        </div>
                    </div>
                </div>

            </section> 
        </>
    )
}

export default DeliveringMember;