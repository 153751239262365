import {useEffect, useState, useRef} from "react";

import Symptomsdata from "../../../assets/images/Mockup-impact-M-S.png";
import useWindowDimensions from "../../../hooks/useWindowDimensions"

function HowToUseChart(props) {
    const {width} = useWindowDimensions();
    const [contentAccrodingToDevice, setContentAccrodingToDevice] = useState('on the right');
    useEffect(() => {
        const ctx = props.gsap.context(() => {

            let timelineHowToUseChart = props.gsap.timeline({
                scrollTrigger: {
                    trigger: ".how-to-use-chart-section",
                    start: "top 75%",
                }
            });

            timelineHowToUseChart.from(".how-to-use-chart-image", {
                duration: 1,
                x: 320,
                autoAlpha: 0,
                ease: "Power4.easeOut"
            }, 0).from(".how-to-use-chart-conent", {
                duration: 1,
                x: -320,
                autoAlpha: 0,
                ease: "Power4.easeOut"
            }, 0).from(".how-to-use-chartText1", {
                duration: 0.8,
                y: 40,
                autoAlpha: 0,
                ease: "Power4.easeOut",
                delay: .5
            }, 0)
                .from(".howTouseChartCircle", {
                    duration: 0.8,
                    y: 40,
                    autoAlpha: 0,
                    ease: "Power4.easeOut",
                    delay: 1
                }, 0).from(".howTouseChartCircleText2", {
                duration: 0.8,
                y: 40,
                autoAlpha: 0,
                ease: "Power4.easeOut",
                delay: 1.5
            }, 0).from(".howTouseChartCircleText3", {
                duration: 0.8,
                y: 40,
                autoAlpha: 0,
                ease: "Power4.easeOut",
                delay: 2
            }, 0).from(".howTouseChartCircleText4", {
                duration: 0.8,
                y: 40,
                autoAlpha: 0,
                ease: "Power4.easeOut",
                delay: 2.5
            }, 0);
        });
        if (width < 768) {
            setContentAccrodingToDevice('below');
        }

        return () => ctx.revert();
    }, []);

    return <>
        <section className='spacer-both how-to-use-chart-section overflow-hidden'>
            <div className='container container-sm'>
                <div className='row justify-content-center'>
                    <div className='col-md-6 how-to-use-chart-conent'>
                        <h2 className='h4'>How to use the chart of your symptoms data</h2>
                        <p className='my-4 how-to-use-chartText1'>Click the small symptom dots directly underneath the
                            graph to ‘hide’ or ‘show’ specific symptom categories so you can compare how one symptom
                            impacts another symptom</p>
                        <div className='circle howTouseChartCircle'>
                            <div className='circle-blk'>
                                <span className='circle-blk__shape bg-yellow'></span>
                                <span className='text-yellow'>Pain</span>
                            </div>

                            <div className='circle-blk'>
                                <span className='circle-blk__shape bg-green'></span>
                                <span className='text-green'>Mental</span>
                            </div>

                            <div className='circle-blk'>
                                <span className='circle-blk__shape bg-blue'></span>
                                <span className='text-blue'>Body</span>
                            </div>

                            <div className='circle-blk'>
                                <span className='circle-blk__shape bg-purple'></span>
                                <span className='text-purplee'>Sleep</span>
                            </div>

                            <div className='circle-blk'>
                                <span className='circle-blk__shape bg-pink'></span>
                                <span className='text-pink'>Energy</span>
                            </div>

                            <div className='circle-blk'>
                                <span className='circle-blk__shape bg-red'></span>
                                <span className='text-red'>Bleed</span>
                            </div>

                        </div>
                        <p className='primary-text-color mt-4 howTouseChartCircleText2'>In the
                            example {contentAccrodingToDevice}, we can see as her mental health deteriorates, so does her
                            ability to sleep</p>
                        <div className='mt-4'>
                            <p className="howTouseChartCircleText3">Observe how treatments and lifestyle modifications
                                are impacting your symptoms</p>
                            <p className="howTouseChartCircleText4">Over time, your graphed data will show symptom
                                trends and can help you and your care team connect the dots on your symptoms,
                                accelerating the time from symptom onset to diagnosis</p>
                        </div>
                    </div>

                    <div className='col-md-5 text-center how-to-use-chart-image'>
                        <img src={Symptomsdata} alt='Symptomsdata'/>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default HowToUseChart;