import {React, useEffect} from "react";
import gsap from "gsap";

import {ScrollTrigger} from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import {SuperSEO} from "react-super-seo";

import Trail from '../HomePage/Trail';

import Image from '../../../images/navigate-path.png';

function ConnectTheDots(props) {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            }, duration: 0,
        });
    }, []);

    useEffect(() => {
        const ctx = gsap.context(() => {
        });

        return () => ctx.revert();
    }, []);

    return (<>
        <SuperSEO
            title="Uterinekind™ - Everything needed to navigate the path to diagnosis, treatment, and recovery"/>
        <SuperSEO description="Uterinekind App: The essential healthcare companion for women. Offering a complete suite of tools for symptom understanding, treatment access, and recovery support, our app ensures a seamless and well-informed healthcare journey." />
        <section className="hero-shadow hero-section-spacer spacer-bottom">
            <div className="container container-sm position-relative">
                <div className="row align-content-center px-5 mb-5">
                    <div className="col-md-6">
                        <h4>
                            Everything needed to navigate the path to diagnosis, treatment, and recovery
                        </h4>
                    </div>
                    <div className="col-md-6">
                        <img src={Image}
                             alt="Everything needed to navigate the path to diagnosis, treatment, and recovery"
                             className="mb-5"/>
                    </div>
                </div>
                <Trail gsap={gsap} showButtonsOnly={1}/>
                <h5>Empower your patients in their journey towards healing with the comprehensive Uterinekind App. Access all the resources and guidance required for accurate diagnosis, effective treatment, and smooth recovery. Simplify the process of connecting the dots with our user-friendly interface and expert support.</h5>
                {/*<span>*/}
                {/*            Chronic gynecological conditions,*/}
                {/*            Mental health,*/}
                {/*            Women's health,*/}
                {/*            Patient care,*/}
                {/*            Symptom management,*/}
                {/*            Health technology,*/}
                {/*            Healthcare app,*/}
                {/*            Gynecology,*/}
                {/*            Women's wellness,*/}
                {/*            Reproductive health,*/}
                {/*            Endometriosis,*/}
                {/*            PCOS (Polycystic Ovary Syndrome),*/}
                {/*            Fibroids,*/}
                {/*            Menstrual disorders,*/}
                {/*            Pelvic pain,*/}
                {/*            Vulvodynia*/}
                {/*        </span>*/}
            </div>
        </section>

    </>);
}

export default ConnectTheDots;
