import axios from 'axios';

export function getRequest(URL) {
  let payload = {}, headers={}
  return apiRequest(URL, payload, "get", headers);
}

export function postRequest(URL, payload, headers = {}) {
  return apiRequest(URL, payload, "post", headers);
}

export async function apiRequest(endPoint, data, method, headers, requestOptions = {}) {
  return new Promise(async (resolve, reject) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    axios({
      method: method,
      url: endPoint,
      headers:headers,
      data: data,
    }).then((result) => { return resolve(result); }).catch((error) => {return reject(error);});
    return false;
  });
}