import { useEffect, useRef } from 'react';
import SplitType from 'split-type'

function AboutHero(props) {
    const heroSubHeading = useRef();
    const heroHeadingg = useRef();
    const aboutYouContentRef = useRef([]);
    aboutYouContentRef.current = [];
    useEffect(() => {

        const _elem = [heroSubHeading.current];

        const ctx = props.gsap.context(() => {
            props.gsap.fromTo(_elem, 1, {
                autoAlpha: 0, letterSpacing: "-50px"
            }, {
                autoAlpha: 1, letterSpacing: "10px", ease: 'Power4.easeOut', delay: .4
            });

            props.gsap.fromTo(new SplitType(heroHeadingg.current).words, {
                duration: 1.5,
                opacity: 0,
                yPercent: 100,
                ease: "power4",
                stagger: 0.1
            }, {
                duration: 1.5,
                opacity: 1,
                yPercent: 0,
                ease: "power4",
                stagger: 0.1,
            },);

            props.gsap.fromTo(aboutYouContentRef.current, {
                opacity: 0,
                yPercent: 50,
            }, {
                duration: 1,
                opacity: 1,
                yPercent: 0,
            },);
        });
        return () => ctx.revert();
    }, []);

    const _aboutYouContentRef = el => {
        if (el && !aboutYouContentRef.current.includes(el)) {
            aboutYouContentRef.current.push(el);
        }
    };

    return <>
        <section class="about-hero full-screen panel">
            <div class="container container-sm text-center position-relative  text-white">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="d-flex flex-column">
                            <span ref={heroHeadingg} class="h3 animate-heading">This had to be about</span>
                            <span ref={heroSubHeading} class="secondary-text-color about-hero__subheading">you</span>
                        </h1>
                        <div ref={_aboutYouContentRef} className="animated-content">
                            <p >Listening to your stories over the past decade was, and remains, a crucial and impactful part of our work. We've interviewed people in all stages of living with chronic conditions. </p>
                            <p class="mt-4">These interviews usually end with hugs, they're often interrupted by tears, and each story left a mark.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default AboutHero