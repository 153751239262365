import Slider from "react-slick";
import NextArrow from "../HomePage/SampleNextArrow";
import PrevArrow from "../HomePage/SamplePrevArrow";

function Testimonials() {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        // initialSlide:1,
        //centerPadding:"200px",
        arrows: true,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
    };

    return (
        <>
            <section className="hero-shadow testimonials spacer text-center hero-section-spacer testimonials-shadow ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <Slider {...settings}>
                                <div className="testimonials-slide">
                                    <div className="testimonials-slide__content">
                                        <p className="h4 fw-bold">
                                            “90% of our diagnosis will come from your history, your story, your daily
                                            symptoms data.”
                                        </p>
                                    </div>
                                    <h4 className="heading-font-24 mt-5">Dr. Evelyn Nicole Mitchell</h4>
                                    <p className="w-75 h5 mx-auto">
                                        Clinical Assistant Professor of Obstetrics & Gynecology (Clinician Educator)
                                        Director of Diversity, Equity, and Inclusion of Obstetrics and Gynecology, Keck
                                        Medicine of USC
                                    </p>
                                </div>
                                <div className="testimonials-slide">
                                    <div className="testimonials-slide__content">
                                        <p className="h4 fw-bold">
                                            “Knowledge of the female reproductive system is rudimentary. What we do to
                                            women is primitive.”
                                        </p>
                                    </div>
                                    <p className="w-75 h5 mx-auto mt-5">
                                        –Dr. Jennifer Garrison, Founder of The Global Consortium on Reproductive
                                        Longevity and Equality
                                    </p>
                                </div>

                                <div className="testimonials-slide">
                                    <div className="testimonials-slide__content">
                                        <p className="h4 fw-bold">
                                            “We are happy to partner with Uterinekind and contribute to
                                            their efforts that enable the dissemination of
                                            scientifically grounded information on human health.”
                                        </p>
                                    </div>
                                    <h4 className="heading-font-24 mt-5">Dr. Marla Lujan</h4>
                                    <p className="w-75 h5 mx-auto">
                                        Associate Professor, Division of Nutritional Sciences at
                                        Cornell University and PCOS researcher
                                    </p>
                                </div>


                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Testimonials;
