import { useEffect, React } from 'react';
import './css/HomePage.css';
import Tracking from './Tracking';
import ContentGrid from "./ContentGrid";
import HomeStepSection from './HomeStepSection';
import Quote from './HomeQuote';
import Conditions from './Conditions';
import IntroVideo from "./IntroVideo";
import Trail from "./Trail";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import '../HomePage/css/home-hero.css';
import '../HomePage/css/animate-lines.css';
import '../HomePage/css/steps.css';
import '../HomePage/css/general-style.css';
import '../HomePage/css/content-animate.css';
import '../HomePage/css/clouds.css';
import '../HomePage/css/symptoms.css';
import '../HomePage/css/faq.css';
import '../HomePage/css/footer.css';
import '../HomePage/css/typing-animation.css';
import { TextPlugin } from "gsap/all";
import { SuperSEO } from "react-super-seo";
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import HomeQuote from "./HomeQuote";

function HomePage(props) {

    const { width } = useWindowDimensions();
    gsap.registerPlugin(TextPlugin)
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin)

    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            },
            duration: 0,
        });
    }, []);
    return <>
        <SuperSEO title="Uterinekind™" />
        <SuperSEO description="Uterinekind is a safe and easy symptoms tracker and educational platform. Know and own your symptoms data and be informed for better health outcomes." />
        <Tracking gsap={gsap} />
        <HomeStepSection gsap={gsap} /> 
        <ContentGrid gsap={gsap} />
        <HomeQuote />
        <Conditions gsap={gsap} />
        <HomeQuote quoteNumber={2} />
        <IntroVideo gsap={gsap} />
        <Trail />
    </>
}

export default HomePage