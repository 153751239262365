import {React, useEffect} from "react";
import gsap from "gsap";
import Mailchimp from "../../../utils/mailchimp-form";

import {ScrollTrigger} from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import {SuperSEO} from "react-super-seo";

import Trail from '../HomePage/Trail';

import Image from '../../../images/FNL_UK_General-Problem-Visit-Discussion-Guide-SS@2x.png';
import Playstore from "../../../images/store-google.png";
import ExternalLink from "../../other/ExternalLink";
import Applestore from "../../../images/store-apple.png";

function ConnectTheDots(props) {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const formMessages = {
        success: "Thank you! Check your email inbox for a link to the free guide!",
        button: "Send request"
    }

    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            },
            duration: 0,
        });
    }, []);

    useEffect(() => {
        const ctx = gsap.context(() => {
        });

        return () => ctx.revert();
    }, []);

    return (
        <>
            <SuperSEO title="Uterinekind™ - Help your patients connect the dots with the Uterinekind App."/>
            <SuperSEO description="Uterinekind App: The essential healthcare companion for women. Offering a complete suite of tools for symptom understanding, treatment access, and recovery support, our app ensures a seamless and well-informed healthcare journey." />
            <section className="hero-shadow hero-section-spacer spacer-bottom">
                <div className="container container-sm position-relative">
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="fw-light h3 mb-5">
                                Why are my periods painful and heavy?
                            </h1>


                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 pe-md-5">
                            <p className="mb-5">Just <span className="fst-italic">going</span> to an OBGYN appointment is not enough to get a diagnosis for
                                symptoms often
                                dismissed as “bad periods”.</p>
                            <p className="mb-5">To get a diagnosis, you need to share a thorough, detailed
                                account of your symptoms over a period of 3-6 months. Ask the right questions. And be
                                informed so you can advocate for yourself, without being stressed or dismissed.</p>
                            <p className="text-blue fs-5 mb-0">Receive the General Problem Discussion Guide in your
                                inbox</p>
                            <p className="mb-4">and get the guidance you need to arrive at a definitive diagnosis with
                                your care team</p>
                            <p>
                                <Mailchimp
                                    className="uk-form"
                                    buttonClassName="btn me-3"
                                    messages={formMessages}
                                    action='https://uterinekind.us11.list-manage.com/subscribe/post?u=5949df872bf794ee6cbea8a93&amp;id=e8d34d99fc&amp;f_id=0056abe0f0'
                                    fields={[
                                        {
                                            name: 'FNAME',
                                            placeholder: 'First name',
                                            type: 'text',
                                            required: true
                                        },
                                        {
                                            name: 'EMAIL',
                                            placeholder: 'Email',
                                            type: 'email',
                                            required: true
                                        }
                                    ]}
                                />
                            </p>
                        </div>
                        <div className="col-md-4"><img src={Image}
                                                       alt="Receive a free version of the General Problem Discussion Guide"
                                                       className="mt-5 mt-md-0 mb-5"/></div>
                    </div>
                </div>
            </section>
            <section className="content-grid bg-tertiary spacer text-center">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-7 mx-auto mb-1">
                            <h2 className="text-darkblue2">Download the FREE app</h2>
                            <div className="store-links my-5 mx-auto flex-sm-row flex-column">
                                <a target="_blank" className="me-sm-5"
                                   href="https://play.google.com/store/apps/details?id=app.vercel.uterinekind_manifest.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                    <img alt="Get it on Google Play" src={Playstore}/>
                                </a>
                                <ExternalLink href="https://apps.apple.com/us/app/uterinekind-app/id6446996869?itsct=apps_box_link&itscg=30200">
                                    <img alt='Get it on the Apple Store' src={Applestore}/>
                                </ExternalLink>
                            </div>
                            <p className="text-darkgray lh-lg">For immediate access to all discussion
                                guides and a fast and safe tool for symptom
                                tracking, download the Uterinekind app and begin recording your symptoms today. This is
                                the data that will lead to a diagnosis.</p>
                            <p className="text-red fs-4">
                                * (We don’t sell or share your data) *
                            </p>
                            <p className="text-darkgray">
                                Without your self-reported data, a diagnosis could take years.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content-grid bg-darkblue spacer text-center">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-7 mx-auto mb-1">
                            <p className="text-white fs-4 mb-5">
                                “90% of our diagnosis will come from your history, your story, your daily symptoms
                                data.”
                            </p>
                            <p>
                                Dr. Evelyn Nicole Mitchell <br/>Clinical Assistant Professor of Obstetrics & Gynecology
                                (Clinician Educator) Director of Diversity, Equity, and Inclusion of Obstetrics and
                                Gynecology, Keck Medicine of USC, Senior Medical Advisor, Uterinekind
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default ConnectTheDots;
