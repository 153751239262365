import React, { useEffect, useRef, useState } from 'react'
// import physicianReport from '../../../../src/assets/images/Symptoms-Summary.png'
// import physicianReport2 from '../../../../src/assets/images/physician-report-2.png'
import physicianReport from '../../../../src/assets/images/PhyReport-1.png'
import physicianReport2 from '../../../../src/assets/images/PhyReport-2.png'
import physicianReport3 from '../../../../src/assets/images/physician-report-2.png'

import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';

import { gsapSplitTextAnimationv2 } from '../../gsap/common';
import { generateYourPhysicianReport } from '../../../utits/staticContent/HomePage'

function Report(props) {
    const [reportImg, setReportImg] = useState(physicianReport);
    const [isActive, setIsActive] = useState(5);
    const reportTriggerRef = useRef([]);
    const reportRef = useRef([]);

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            gsapSplitTextAnimationv2(props.gsap, reportRef, reportTriggerRef,
                1, 100, 1.5, 0.1, "top 80%", "bottom top"
            );
        });
        return () => ctx.revert();
    }, []);



    const handleClick = (id, key) => {
        setIsActive(key);
        switch (id) {
            case '2':
                setReportImg(physicianReport2);
                break;
            case '3':
                setReportImg(physicianReport3);
                break;
            default:
                setReportImg(physicianReport);
                break;
        }
    }

    useEffect(() => {
        const osInstance = OverlayScrollbars(document.querySelector('.gallery-items2'), {
            overflow: {
                x: 'hidden',
            },
        });

    }, [])

    return (
        <>
            <section className="bg-secondary physician spacer">
                <div className="container">
                    <div className="row align-items-center flex-column-reverse flex-md-row" ref={reportTriggerRef}>
                        <div className="col-md-5 text-center mt-5 mt-md-0">
                            <div className="physician-report-gallery">
                                <div className="gallery-blk" id="physician-items-one">
                                    <img src={reportImg} alt="physician report" />
                                </div>
                            </div>

                        </div>
                        <div className="col-md-7 text-start">
                            <h2 ref={reportRef} className="fw-bold animate-heading">Generate your symptoms summary</h2>
                            <div className="list-unstyled animate-listing animate-listing-rt physician-report-items gallery-items2">
                                {generateYourPhysicianReport().map(({ title, key, id }) => {
                                    return (
                                        <>
                                            <div key={key}>
                                                <a className={(isActive == key ? `active` : ``)} key={key} href="javascript:void(0)"
                                                    onClick={() => handleClick(id, key)}
                                                >{title}
                                                </a>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Report