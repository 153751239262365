import { formatPublisedDate } from "../../../utils/functions";
import { useDispatch } from 'react-redux';
import { activePodcastItem } from '../../../store/actions/PodcastItems';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { seoFriendlyUrl } from "../../../utils/functions";
import { randomNo } from "../../../utils/functions";
import { minToStandardFormat } from "../../../utils/functions";
function PodcastItem(props) {
    let navigate = useNavigate();
    const _dispatch = useDispatch();
    const _activePodcastItems = useSelector(state => state.ActivePodcastEpisode)
    
    function playPodcast(guid, id, title, published, description, link, audioId, duration) {
        _dispatch(activePodcastItem({
            title: title,
            id:id,
            seoFriendlyUrl: seoFriendlyUrl(title),
            searchFriendlyUrl: seoFriendlyUrl(title, " "),
            guid: guid,
            description: description,
            link: link,
            publistDate: published,
            author: "",
            duration: duration,
            audioId: audioId,
        }))
        let path = `/podcast/${seoFriendlyUrl(title)}`;
        navigate(path);
    }
    const bgRandomMizer = (key) => {
        switch (key) {
            case 2:
                return 'podcast-bg-third'
            case 3:
                return 'podcast-bg-second'
            default:
                return 'podcast-bg-first'
        }
    }
    if (_activePodcastItems.guid !== props.guid) {
        return <>
            <div onClick={() =>
                playPodcast(
                    props.guid,
                    props.id,
                    props.title,
                    props.publistDate,
                    props.description,
                    props.link,
                    props.audioId,
                    props.duration
                )
            } key={props.key} className={'col-lg-4 col-sm-6'} style={{ cursor: "pointer" }}>
                <div className='podcast__listing'>
                    {/*<span className='fw-normal podcast-date-duration'>{formatPublisedDate(props.publistDate, "short")} | { minToStandardFormat( props.duration )}</span>*/}
                    <div className={`${bgRandomMizer( randomNo(1, 3) )} podcast__listing__inner text-center mt-2 d-flex`}>
                        <h2 className='h4 m-auto' dangerouslySetInnerHTML={{__html: props.title}} />
                        {/* <div className='podcast__by h5 d-flex flex-column mt-3'>
                            {props.author
                                ? <>
                                    <span>{props.author}</span>
                                </>
                                : ''
                            }
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    }
}
export default PodcastItem;