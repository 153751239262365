import React, { useEffect, useRef } from 'react'
import { gsapSplitTextAnimationv2 } from '../../gsap/common'

import mybody from '../../../../src/assets/images/MyBody.svg'
import diary from '../../../../src/assets/images/diary-icon.svg'
import library from '../../../../src/assets/images/content-icon.svg'
import prepicon from '../../../../src/assets/images/Prep Icon.svg'


function PartTool(props) {

    const partToolTriggerRef = useRef([]);
    const animatePartTool1Ref = useRef([]);
    const animatePartTool2Ref = useRef([]);
    const animatePartTool3Ref = useRef([]);
    const navigateThePathRef = useRef([]);

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            /*
                * funcation: gsapSplitTextAnimationv2
                * param: 
                    *   gsap obj, 
                    *   element Ref, 
                    *   elementTrigger Ref, 
                    *   opacity
                    *   yPercent
                    *   duration
                    *   stagger
                    *   start
                    *   end
                *
            */
            gsapSplitTextAnimationv2(props.gsap, animatePartTool1Ref, animatePartTool1Ref,
                1, 100, 1.5, 0.1, "bottom bottom", "bottom top");
            gsapSplitTextAnimationv2(props.gsap, animatePartTool2Ref, animatePartTool2Ref,
                1, 100, 1.5, 0.1, "bottom bottom", "bottom top");
            gsapSplitTextAnimationv2(props.gsap, animatePartTool3Ref, animatePartTool3Ref,
                1, 100, 1.5, 0.1, "bottom bottom", "bottom top");
            gsapSplitTextAnimationv2(props.gsap, navigateThePathRef, navigateThePathRef,
                1, 100, 1.5, 0.1, "bottom bottom", "bottom top");

        })
        return () => ctx.revert();
    }, []);

    return (
        <>
            <section id="section-faq" className="faq spacer">
                <div className="container container-sm">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <h2 className="d-flex flex-column text-center faq__headings" ref={partToolTriggerRef}>
                                <span ref={animatePartTool1Ref} className="animate-heading">Part tool,</span>
                                <span ref={animatePartTool2Ref} className="animate-heading">part tutorial,</span>
                                <span ref={animatePartTool3Ref} className="animate-heading">part hand-holder</span>
                            </h2>
                            <h4 ref={navigateThePathRef} className="animate-heading mb-5">Everything you need to navigate the path to diagnosis, treatment and recovery is here.</h4>
                            <div className="accordion home-accordian" id="accordionExample">
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            <div className="faq__icon">
                                                <img src={mybody} alt="My Body" />
                                                <span>My Body</span>
                                            </div>
                                            <div className="faq__label"><span className="d-block">Be the person who knows you best and accelerate your healing journey</span>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>Measure your symptoms</li>
                                                            <li>Monitor symptom impact</li>
                                                            <li>Identify trends in symptoms</li>
                                                            <li>Utilize your data to expedite a diagnosis </li>
                                                            <li>Measure treatment impact on symptoms</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>

                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <div className="faq__icon">
                                                <img src={diary} alt="diary" />
                                                <span>Diary</span>
                                            </div>
                                            <div className="faq__label"><span className="d-block">Preserve your lived experience and shut down gaslighting</span>
                                                <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>Record your experience</li>
                                                            <li>Track what you want, in your own words</li>
                                                            <li>Attach selected entries to the physician report</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>

                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <div className="faq__icon">
                                                <img src={library} alt="library" />
                                                <span>Library</span>
                                            </div>
                                            <div className="faq__label"><span className="d-block">We'll bring it to you fresh, each week: podcasts, blogs, research updates, uterine news, treatment advancements, legal moves, and more</span>
                                                <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <ul className="col2-listing">
                                                                    <li>Master your anatomy</li>
                                                                    <li>Learn about all chronic uterine conditions</li>
                                                                    <li className='mb-10p'>Listen to people's stories</li>
                                                                </ul>

                                                            </div>
                                                            <div className="col-md-6">
                                                                <ul className="col2-listing">
                                                                    <li>Explore all available treatment options</li>
                                                                    <li>Hear from expert researchers and physicians</li>
                                                                    <li>Read the latest research &amp; studies, summarized for you</li>
                                                                </ul>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>

                                </div>

                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            <div className="faq__icon">
                                                <img src={prepicon} alt="Prep" />
                                                <span>Prep</span>
                                            </div>
                                            <div className="faq__label"><span className="d-block">Be prepared and make the most of your limited time with physicians</span>
                                                <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>Build a library of content just for you</li>
                                                            <li>Use appointment discussion guides on all conditions and for any stage appointment</li>
                                                            <li>Generate your symptoms summary report for appointments</li>
                                                            <li>Review the checklist before each appointment</li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PartTool