import React, { useEffect, useRef, useState } from 'react'
// import chart from '../../../../src/assets/images/Chart-Trend-2.png'
// import chart2 from '../../../../src/assets/images/Chart-Trend-3.png'
import chart from '../../../../src/assets/gif/MeasureSymptoms-Impact-2.gif'
import chart2 from '../../../../src/assets/gif/MeasureSymptoms-Impact-1.gif'
// import chart from '../../../../src/assets/images/MeasureSymptoms-Trend-1day.png'
// import chart2 from '../../../../src/assets/images/MeasureSymptoms-Trend-7days.png'
import chart3 from '../../../../src/assets/images/Chart-Trend-3.png'
import chart4 from '../../../../src/assets/images/Chart-Trend-4.png'
import chart5 from '../../../../src/assets/images/Chart-Trend-5.png'

/* generate report images */
import physicianReport from '../../../../src/assets/images/Symptoms-Summary.png'
import physicianReport2 from '../../../../src/assets/images/physician-report-2.png'

/* monitor meds and treatment */

import monitorReport from '../../../../src/assets/images/Diary-UI.png'
import monitorReport2 from '../../../../src/assets/images/Chart-Trend-6.png'
import monitorReport3 from '../../../../src/assets/images/Chart-Trend-6.png'



import { MeasureYourSymptoms } from '../../../utits/staticContent/HomePage'
import { gsapSplitTextAnimationv2 } from '../../gsap/common'


import Slider from "react-slick";
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';

import NextArrow from './SampleNextArrow';


function Symptoms(props) {

    const [symtomsImg, setSymtomsImg] = useState(chart);
    const [isActive, setIsActive] = useState(1);
    const symptomsTriggerRef = useRef([]);
    const symptomsRef = useRef([]);
    useEffect(() => {
        const ctx = props.gsap.context(() => {
            gsapSplitTextAnimationv2(props.gsap, symptomsRef, symptomsTriggerRef,
                1, 100, 1.5, 0.1, "top 80%", "bottom top"
            );
        });
        return () => ctx.revert();
    }, []);

    const handleClick = (id, key) => {
        setIsActive(key);
        switch (id) {
            case '2':
                setSymtomsImg(chart2);
                break;

            case '3':
                setSymtomsImg(chart3);
                break;

            case '4':
                setSymtomsImg(chart4);
                break;

            case '5':
                setSymtomsImg(chart5);
                break;

            default:
                setSymtomsImg(chart);
                break;
        }
    }


    useEffect(() => {
        const osInstance = OverlayScrollbars(document.querySelector('.gallery-items'), {
            overflow: {
                x: 'hidden',
            },
        });

    }, []);

    return (
        <>
        
            <section className="bg-secondary symptoms" id="measure-symptoms">
                <div className="container">
                    <div className="row align-items-center" ref={symptomsTriggerRef}>
                        <div className="col-md-7 text-end">
                            <h2 ref={symptomsRef} className="fw-bold animate-heading">Measure your symptoms</h2>
                            <div className="list-unstyled animate-listing animate-listing-lt gallery-items">

                                {MeasureYourSymptoms().map(({ title, text, key, img, id }) => {
                                    return (
                                        <>
                                            <div key={key}>
                                                <a className={(isActive == key ? `active` : ``)} key={key} href="javascript:void(0);"
                                                    onClick={() => handleClick(id, key)}
                                                >{title}
                                                </a>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="col-md-5 text-center">
                            <div className="gallery-media mt-5 mt-md-0">

                                <div className="media-blk" id="gallery-items-one">
                                    <img src={symtomsImg} alt="symptoms report" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Symptoms