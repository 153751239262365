export const BASEURL = process.env.REACT_APP_PODCAST_DOMAIN_URL;
export const PODCAST_ID = process.env.REACT_APP_PODCAST_ID;

export const PODCAST = {
    DEFAULT_VALUES:{
        LIMIT:10,
        OFFSET:0,
    },

    EPISODES:`${BASEURL}/podcasts/${PODCAST_ID}/episodes`,
    EPISODES_SEARCH:`${BASEURL}/episodes/search`,
    EPISODES_BY_ID:`${BASEURL}/episodes`,
}

export const defaultValues = {
    'podcastBaseUrl':'https://feeds.simplecast.com/bCEoV8aL',
    'SIMPLECAST_SEARCH_URL':'https://api.simplecast.com/episodes/search'
}

export const DOMAIN = 'https://www.uterinekind.com/';
