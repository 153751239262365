import './App.css';
import HomePage from './components/pages/HomePage/Index';
import AboutPage from './components/pages/About/Index';
import ExplainerVideo from './components/pages/ExplainerVideo/Index';
import PodcastPage from './components/pages/Podcast/Index';
import PodcastDetailsPage from './components/pages/Podcast/PodcastDetailsPage';
// import Pricing from './components/pages/pricing';
import HowItWorks from './components/pages/HowItWorks';
import TermsAndConditions from './components/pages/TermsAndConditions';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import FaqPage from './components/pages/Faq/Index';
import {
  BrowserRouter, Routes,
  Route
} from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import NewsPage from './components/pages/News/Index';
import FounderPage from './components/pages/FounderPage/Index';
import NewsDetailPr from './components/pages/News/NewsDetailPage/Index';
import NewsDetailGroundBreakingStudy from './components/pages/News/NewsDetailPage/groundbreaking-study-reveals-link-oral-contraception-use-depression';
import OhioDerailmentPage from './components/pages/Ohio-Derailment';
import BadPeriodsNotDiagnosis from './components/pages/BadPeriodsNotDiagnosis';

// Landing pages
import ConnectTheDots from './components/pages/LandingPages/ConnectTheDots';
import PathToTreatment from './components/pages/LandingPages/PathToTreatment';
import StrugglingDiagnosis from './components/pages/LandingPages/StrugglingDiagnosis';
import DiscussionGuide from './components/pages/LandingPages/DiscussionGuide';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path='/' element={<HomePage />}></Route>
          <Route exact path='/bad-periods-not-diagnosis' element={<BadPeriodsNotDiagnosis />}></Route>
          <Route exact path='/about' element={<AboutPage />}></Route>
          <Route exact path='/explainer-video' element={<ExplainerVideo />}></Route>
          <Route exact path='/intro-video' element={<ExplainerVideo />}></Route>
          <Route exact path='/podcast' element={<PodcastPage />}></Route>
          <Route exact path='/podcast/:podcastid' element={<PodcastDetailsPage />}></Route>
          {/*<Route exact path='/pricing' element={<Pricing />}></Route>*/}
          <Route exact path='/how-it-works' element={<HowItWorks />}></Route>
          <Route exact path='/terms-and-conditions' element={<TermsAndConditions />}></Route>
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route exact path='/faq' element={<FaqPage />}></Route>
          <Route exact path='/founder' element={<FounderPage />}></Route>
          {/*news pages*/}
          {/*<Route exact path='/news' element={<NewsPage />}></Route>*/}
          <Route exact path='/news/uterinekind-launches-u-by-uterinekind' element={<NewsDetailPr />}></Route>
          <Route exact path='/news/groundbreaking-study-reveals-link-oral-contraception-use-depression' element={<NewsDetailGroundBreakingStudy />}></Route>

        {/*  landing pages  */}
          <Route exact path='/ohio-derailment-chemical-disaster-start-tracking-your-symptoms-with-uterinekind-app' element={<OhioDerailmentPage />}></Route>
          <Route exact path='/uterinekind-help-your-patients-connect-the-dots' element={<ConnectTheDots />}></Route>
          <Route exact path='/uterinekind-everything-need-to-navigate-the-path-to-diagnosis-treatment-and-recovery' element={<PathToTreatment />}></Route>
          <Route exact path='/uterinekind-struggling-to-get-a-diagnosis' element={<StrugglingDiagnosis />}></Route>
          <Route exact path='/get-free-general-problem-discussion-guide' element={<DiscussionGuide />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
