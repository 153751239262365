import { useEffect } from "react";

import Slider from "react-slick";
import NextArrow from "../HomePage/SampleNextArrow";
import PrevArrow from "../HomePage/SamplePrevArrow";
import { FirstTimeUseContent } from "../../../utits/staticContent/HowitWorks";

function FirstTimeUse(props) {

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            let timelineFirstTimeuse = props.gsap.timeline({
                scrollTrigger: {
                    trigger: ".firstTimeuse",
                    start: "top 75%",
                }
            });

            timelineFirstTimeuse.from(".firstTimeuseTitle", {
                duration: 1.5,
                // y: 5,
                autoAlpha: 0,
                ease: "Power4.easeOut"
            }, 0)
            .from(".firstTimeusesteps", {
                duration: 0.8,
                y: 50,
                autoAlpha: 0,
                ease: "Power4.easeOut",
                delay:0.5
            }, 0);
        });

        return () => ctx.revert();
    }, []);

    return <>
            <section className='steps-slider text-center spacer-b firstTimeuse'>
            <div className='container container-sm'>
                <div className='row'>
                    <div className='col-md-8 mx-auto'>
                        <h2 className='h4 firstTimeuseTitle'>First-time use of the app:</h2>
                        <span className='text-purple d-block mb-2 firstTimeusesteps'>Steps</span>
                        <Slider className="firstTimeusesteps" arrows={true} dots={true} fade={false} infinite={false} nextArrow={<NextArrow />}
                            prevArrow={
                                <PrevArrow />}
                        >
                        {FirstTimeUseContent().map(({ id, key, title }) => (
                            <div key={key} className='steps-slider__blk'>
                                <p>{title}</p>
                            </div>
                        ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    </>
}
export default FirstTimeUse;