import React from "react";

function PrevArrow(props){
    const { className, style, onClick } = props;
    return(
        <div 
        onClick={onClick}
        style={{ ...style}}
        className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
                <path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" fill="rgba(255,255,255,1)"/></svg> 
        </div>
    )
}

export default PrevArrow;
