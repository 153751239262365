import React, { useEffect } from "react"
import iPhone_13 from '../../../../src/images/iPhone_13.png'
import citizen from '../../../../src/images/citizen.svg'
function ContentCitizen(props) {

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            let phone = props.gsap.timeline({
                scrollTrigger: {
                    trigger: ".citizen",
                    pin: false,
                    markers: false,
                    start: "center 70%",
                    pinSpacing: false,
                    scrub: false,
                    toggleActions: "play pause pause reverse",
                }
            });

            phone.from(".phone-screen", {
                duration: 1,
                x: -320,
                autoAlpha: 0,
                ease: "Power4. easeOut"
            }, 0);
            phone.from(".phone-content", {
                duration: 1,
                x: 320,
                autoAlpha: 0,
                ease: "Power4. easeOut"
            }, 0);
        })
        return () => ctx.revert();
    }, [])
    return <>
        <section className="bg-secondary citizen spacer overflow-hidden" id="citizen">
            <div className="container container-sm">
                <div className="row align-items-center justify-content-center flex-column-reverse flex-md-row">
                    <div className="col-md-5 phone-screen mt-5 mt-md-0">
                        <img src={iPhone_13} alt="citizen" />
                    </div>

                    <div className="col-md-7">
                        <img src={citizen} alt="citizen" className="mb-4" />
                        <div className="phone-content">
                            <h4 className="fw-bold">Be a citizen scientist of your body</h4>
                            <p className="mb-0">Checking in with your body daily and developing a detailed record of your symptom experiences is step one in getting a definitive diagnosis. Learning about your anatomy and how chronic uterine conditions impact anatomy will help you advocate for modern care and gold standard treatments.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}
export default ContentCitizen
