import React, { Component } from 'react';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import thumbnail from '../../../assets/images/video-bg.jpg'
import { VIDEO_URL } from '../../../utits/staticContent/HomePage';
import Playstore from "../../../images/store-google.png";
import ExternalLink from "../../other/ExternalLink";
import Applestore from "../../../images/store-apple.png";
const sources = {
  introVideo: VIDEO_URL
};

export default class VideoPlayer extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      source: sources.introVideo,
      isEnded: false
    };

  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }


  handleStateChange(state) {

    if (state.ended) {
      this.setState({ isEnded: true });
    } else {
      this.setState({ isEnded: false });
    }

    this.setState({
      player: state
    });
  }

  render() {
    return (
      <div className='text-center video'>
        <Player
          ref={player => {
            this.player = player;
          }}
          poster={thumbnail}
        >
          <source src={this.state.source} />
            <BigPlayButton className="btn" position="center" />
          <ControlBar autoHide={false} />
        </Player>

        {/*{this.state.isEnded ? <a class="btn mt-5 btn-lg" href="https://app.uterinekind.com/en/body" >Join now - free trial!</a> : ''}*/}
        {this.state.isEnded ? <div className="store-links d-flex mt-5">
          <a target="_blank" className="me-3"
             href="https://play.google.com/store/apps/details?id=app.vercel.uterinekind_manifest.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img alt="Get it on Google Play" src={Playstore}/>
          </a>
          <ExternalLink href="https://apps.apple.com/us/app/uterinekind-app/id6446996869?itsct=apps_box_link&itscg=30200">
            <img alt='Get it on the Apple Store' src={Applestore}/>
          </ExternalLink>
        </div> : ''}
      </div>
      
    );
  }
}