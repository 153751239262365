import { useEffect, useRef } from 'react';
import { gsapSplitTextAnimationv2 } from '../../gsap/common';
import pain from '../../../../src/assets/images/pain.svg'
import mentalHealth from '../../../../src/assets/images/mental-health.svg'
import body from '../../../../src/assets/images/body.svg'
import sleep from '../../../../src/assets/images/sleep.svg'
import energy from '../../../../src/assets/images/energy.svg'
import bleeding from '../../../../src/assets/images/bleeding.svg'

import circlebg from '../../../../src/assets/images/circle.svg'
import gsap from 'gsap';
import useWindowDimensions from "../../../hooks/useWindowDimensions";



function StepSection(props) {

    const { width } = useWindowDimensions();
    const uIsTheAppTriggerRef = useRef([]);
    const uIstheAppRef = useRef([]);

    const uIsTheAppTriggerMobRef = useRef([]);
    const uIstheAppMobRef = useRef([]);

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: ".steps",
                    pin: false,
                    start: "top 60%",
                    toggleActions: "restart none none none",
                }
            });

            tl.from(".steps__one__heading", {
                duration: .3,
                autoAlpha: 0,
                y: "20px",
                ease: "Power4. easeOut"
            })
                .from(".steps__one__graphics", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: -120,
                    transformOrigin: "center",
                    ease: "Power4. easeOut"
                })
                .from(".steps__one__subheading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })

                .from(".steps__two__heading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })
                .from(".steps__two__graphics", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: 180,
                    transformOrigin: "center",
                    ease: "Power4. easeOut"
                })
                .from(".steps__two__subheading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })

                .from(".steps__three__heading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })
                .from(".steps__three__graphics", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: 180,
                    transformOrigin: "center",
                    ease: "Power4. easeOut"
                })
                .from(".steps__three__subheading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })

                .from(".steps__four__heading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })
                .from(".steps__four__graphics", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: 180,
                    transformOrigin: "center",
                    ease: "Power4. easeOut"
                })
                .from(".steps__four__subheading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })

                .from(".steps__five__heading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })
                .from(".steps__five__graphics", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: 180,
                    transformOrigin: "center",
                    ease: "Power4. easeOut"
                })
                .from(".steps__five__subheading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })

                .from(".steps__six__heading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })
                .from(".steps__six__graphics", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    rotation: 180,
                    transformOrigin: "center",
                    ease: "Power4. easeOut"
                })
                .from(".steps__six__subheading", {
                    duration: .3,
                    autoAlpha: 0,
                    y: "20px",
                    ease: "Power4. easeOut"
                })

                .to("#Scroll_Group_1", {
                    opacity: 1,
                    duration: 5,
                    force3D: false,
                    strokeDashoffset: 0,
                    ease: "Power4. easeOut"
                })

            /*
                * funcation: gsapSplitTextAnimationv2
                * param: 
                    *   gsap obj, 
                    *   element Ref, 
                    *   elementTrigger Ref, 
                    *   opacity
                    *   yPercent
                    *   duration
                    *   stagger
                    *   start
                    *   end
                *
            */
            gsapSplitTextAnimationv2(props.gsap, uIstheAppRef, uIsTheAppTriggerRef,
                1, 100, 1.5, 0.1, "top 90%", "bottom top"
            );
            gsapSplitTextAnimationv2(props.gsap, uIstheAppMobRef, uIsTheAppTriggerMobRef,
                1, 100, 1.5, 0.1, "top 90%", "bottom top"
            );
        });
        return () => ctx.revert();
    }, []);

    return <>
        <section className="steps" id="steps">
            {width < 768 ? <div ref={uIsTheAppTriggerMobRef} class="container mob-steps">
                <div className="container container-sm">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="steps__content position-static">
                                <h2 ref={uIstheAppMobRef} className="h3 animate-heading mb-5">U is an app that simplifies connecting the dots on your symptoms, accelerating the time to diagnosis.</h2>
                                <a className='btn mb-3' href="https://app.uterinekind.com/">Sign up today</a>
                            </div>
                            <svg width="95%" height="600" viewBox="0 0 364.233 784.952">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect x="-31" y="-90" width="364.233" height="784.952" fill="none" />
                                    </clipPath>

                                    <filter id="shadow11">
                                        <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" />
                                    </filter>
                                    <filter id="shadowmob">
                                        <feDropShadow dx="0" dy="0" stdDeviation="10" flood-color="white" />
                                    </filter>
                                    <filter id="shadow33">
                                        <feDropShadow
                                            dx="-0.8"
                                            dy="-0.8"
                                            stdDeviation="0"
                                            flood-color="pink"
                                            flood-opacity="0.5" />
                                    </filter>

                                </defs>
                                <g id="Scroll_Group_1" data-name="Scroll Group 1" transform="translate(31 90)" clip-path="url(#clip-path)" style={{ isolation: "isolate", filter: "url(#shadowmob)" }}>
                                    <path id="Path_13577" data-name="Path 13577" d="M10191.665,1282.377s86.387-9.836,161.139,60.449c38.145,35.865,73.294,95.782,92.432,181.235,21.409,95.6-31.9,182.452-85.5,236.318-91.557,80-206.374,111.021-206.374,111.021" transform="translate(-10150.913 -1279.9)" fill="none" stroke="#13a5df" stroke-linecap="round" stroke-width="4" />
                                </g>

                                <g className="steps__items_one">
                                    <text className="steps__heading steps__one__heading" fill="#FF9300" x="50" y="25">Pain</text>
                                    <image x="30" y="50" width="65" height="65" className="steps__one__graphics" href={pain} />
                                    <text className="steps__subheading steps__one__subheading" x="20" y="140">Uncomfortable</text>
                                </g>

                                <g className="steps__items_two">
                                    <text className="steps__heading steps__two__heading" fill="#4AC12E" x="170" y="60">Mental Health</text>

                                    <image x="160" y="80" width="112" height="112" className="steps__two__graphics" href={mentalHealth} />
                                    <text className="steps__subheading steps__two__subheading" x="200" y="224">Severe</text>
                                </g>

                                <g className="steps__items_three">
                                    <text className="steps__heading steps__three__heading" fill="#11A4DE" x="300" y="230">Body</text>

                                    <image x="270" y="250" width="78" height="78" className="steps__three__graphics" href={body} />
                                    <text className="steps__subheading steps__three__subheading" x="270" y="360">Many issues</text>
                                </g>

                                <g className="steps__items_four">
                                    <text className="steps__heading steps__four__heading" fill="#808CE3" x="270" y="420">Sleep</text>

                                    <image x="245" y="430" width="112" height="112" className="steps__four__graphics" href={sleep} />
                                    <text className="steps__subheading steps__four__subheading" x="270" y="570">Insomnia</text>
                                </g>

                                <g className="steps__items_five">

                                    <text className="steps__heading steps__five__heading" fill="#C12EC1" x="150" y="540">Energy</text>

                                    <image x="130" y="560" width="112" height="112" className="steps__five__graphics" href={energy} />
                                    <text className="steps__subheading steps__five__subheading" x="150" y="700">Debilitated</text>
                                </g>

                                <g className="steps__items_six">
                                    <text className="steps__heading steps__six__heading" fill="#C12E2E" x="0" y="630">Bleeding</text>

                                    <image x="0" y="650" width="78" height="78" className="steps__six__graphics" href={bleeding} />
                                    <text className="steps__subheading steps__six__subheading" x="10" y="760">Spotting</text>
                                </g>

                            </svg>
                        </div>
                    </div>
                </div>
            </div> : <div ref={uIsTheAppTriggerRef} className="container container-sm desktop-steps">
                <div className="row">
                    <div className="col-md-12">
                        <div className="steps__content">
                            <h2 ref={uIstheAppRef} className="h3 animate-heading">U is an app that simplifies connecting the dots on your symptoms, accelerating the time to diagnosis.</h2>
                            <a className='btn mt-4' href="https://app.uterinekind.com/">Sign up today</a>
                        </div>
                        <svg width="100%" height="788.32" viewBox="0 0 1018.868 788.32">

                            <defs>
                                <filter id="shadow">
                                    <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" />
                                </filter>
                                <filter id="shadow2">
                                    <feDropShadow dx="0" dy="0" stdDeviation="10" flood-color="white" />
                                </filter>
                                <filter id="shadow3">
                                    <feDropShadow
                                        dx="-0.8"
                                        dy="-0.8"
                                        stdDeviation="0"
                                        flood-color="pink"
                                        flood-opacity="0.5" />
                                </filter>


                            </defs>

                            <g id="Scroll_Group_1" data-name="Scroll Group 1" transform="translate(97 92)" clip-path="url(#clip-path)" style={{ isolation: "isolate", filter: "url(#shadow2)" }}>
                                <path id="Path_13576" filter="url(#shadow)" data-name="Path 13576" d="M-12331.765-1904.8s230.685,217.5-41.743,439.4-628.341,83.486-698.646,10.985" transform="translate(13076.396 1909.04)" fill="none" stroke="#4FBBE8" stroke-linecap="round" stroke-width="4" />
                            </g>

                            <g className="steps__items_one">
                                <text className="steps__heading steps__one__heading" fill="#FF9300" x="805" y="25">Pain</text>
                                <image x="790" y="45" width="65" height="65" className="steps__one__graphics" href={pain} />
                                <text className="steps__subheading steps__one__subheading" x="755" y="140">Uncomfortable</text>
                            </g>

                            <g className="steps__items_two">
                                <text className="steps__heading steps__two__heading" fill="#4AC12E" x="865" y="250">Mental Health</text>

                                <image x="850" y="350" width="100" height="70" className="d-none" href={circlebg} />
                                <image x="870" y="270" width="112" height="112" className="steps__two__graphics" href={mentalHealth} />
                                <text className="steps__subheading steps__two__subheading" x="910" y="420">Severe</text>
                            </g>

                            <g className="steps__items_three">


                                <image x="720" y="460" width="180" height="180" className="d-none" href={circlebg} />
                                <text className="steps__heading steps__three__heading" fill="#11A4DE" x="800" y="460">Body</text>
                                <image x="780" y="480" width="78" height="78" className="steps__three__graphics" href={body} />
                                <text className="steps__subheading steps__three__subheading" x="780" y="590">Many issues</text>
                            </g>

                            <g className="steps__items_four">
                                <text className="steps__heading steps__four__heading" fill="#808CE3" x="580" y="539">Sleep</text>

                                <image x="500" y="550" width="180" height="180" className="d-none" href={circlebg} />
                                <image x="550" y="560" width="112" height="112" className="steps__four__graphics" href={sleep} />
                                <text className="steps__subheading steps__four__subheading" x="580" y="701">Insomnia</text>
                            </g>

                            <g className="steps__items_five">

                                <text className="steps__heading steps__five__heading" fill="#C12EC1" x="310" y="545">Energy</text>

                                <image x="220" y="520" width="180" height="180" className="d-none" href={circlebg} />
                                <image x="280" y="570" width="112" height="112" className="steps__five__graphics" href={energy} />

                                <text className="steps__subheading steps__five__subheading" x="290" y="716">Debilitated</text>

                            </g>

                            <g className="steps__items_six">
                                <text className="steps__heading steps__six__heading" fill="#C12E2E" x="60" y="460">Bleeding</text>
                                <image x="50" y="490" width="78" height="78" className="steps__six__graphics" href={bleeding} />
                                <text className="steps__subheading steps__six__subheading" x="60" y="590">Spotting</text>
                            </g>
                        </svg>

                    </div>
                </div>
            </div>}


        </section>
    </>
}
export default StepSection;