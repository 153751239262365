import { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import NextArrow from "../HomePage/SampleNextArrow";
import PrevArrow from "../HomePage/SamplePrevArrow";

import Resultone from "../../../assets/images/result-1.png";
import Resulttwo from "../../../assets/images/result-2.png";

function HowToUse(props) {

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            
                let timelineYourData = props.gsap.timeline({
                    scrollTrigger: {
                        trigger: ".yourData-section",
                        start: "top 80%",
                    }
                });
    
                timelineYourData.from(".data-text-section", {
                    duration: 1,
                    y: 80,
                    autoAlpha: 0,
                    ease: "Power4.easeOut"
                }, 0);
                
                timelineYourData.from(".data-slider-section", {
                    duration: 1,
                    autoAlpha: 0,
                    ease: "Power4.easeOut",
                    delay:0.8
                }, 0);
        });
        
        return () => ctx.revert();
    }, []);

    return <>
        <section className='result-slider text-center yourData-section'>
            <div className='container container-sm'>
                <div className='row'>
                    <div className='col-md-12 data-text-section'>
                        <p>Your data will now be graphed in the chart located above the symptoms dots, on the top of the My Body page</p>
                    </div>
                </div>
                <div className='row mt-5 data-slider-section'>
                    <div className='col-md-6 mx-auto'>
                        <Slider arrows={true} fade={true} infinite={false} nextArrow={<NextArrow />}
                            prevArrow={
                                <PrevArrow />}
                        >
                            <div className='result-slider__items'>
                                <div><span className='h5 mb-3 d-block'>After one day</span></div>
                                <img src={Resultone} alt='After one day' />
                            </div>
                            <div className='result-slider__items'>
                                <div>
                                    <span className='h5 mb-3 d-block'>After one week</span>
                                </div>
                                <img src={Resulttwo} alt='After one week' />
                            </div>
                        </Slider>

                    </div>
                </div>
            </div>
        </section>
    </>
}
export default HowToUse;