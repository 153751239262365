import { useEffect, useState, useRef } from "react";

import Recordsymptoms from "../../../assets/images/record-symptoms.png";

import Painone from "../../../assets/images/pain-1.svg";
import Paintwo from "../../../assets/images/pain-2.svg";
import Painthree from "../../../assets/images/pain-3.svg";
import Painfour from "../../../assets/images/pain-4.svg";
import More from "../../../assets/images/More-icon.svg";

function HowToRecord(props) {

    useEffect(() => {
        const ctx = props.gsap.context(() => {
            
                let timelineHowToRecord = props.gsap.timeline({
                    scrollTrigger: {
                        trigger: ".how-to-record",
                        start: "top 80%",
                    }
                });
    
                timelineHowToRecord.from(".image-section", {
                    duration: 1,
                    x: -320,
                    autoAlpha: 0,
                    ease: "Power4.easeOut"
                }, 0);
                timelineHowToRecord.from(".symtoms-title", {
                    duration: 1,
                    x: 320,
                    autoAlpha: 0,
                    ease: "Power4.easeOut"
                }, 0);
                
                timelineHowToRecord.from(".how-to-recordText1", {
                    duration: 0.8,
                    y: 40,
                    autoAlpha: 0,
                    ease: "Power4.easeOut",
                    delay: 0.5
                }, 0);
                
                timelineHowToRecord.from(".how-to-recordText2", {
                    duration: 0.8,
                    y: 40,
                    autoAlpha: 0,
                    ease: "Power4.easeOut",
                    delay: 1
                }, 0);

                timelineHowToRecord.from("#idPainone", {
                    duration: 0.8,
                    autoAlpha: 0,
                    ease: "Power4.easeOut",
                    delay: 1.5
                }, 0);
                timelineHowToRecord.from("#idPaintwo", {
                    duration: 0.8,
                    autoAlpha: 0,
                    ease: "Power4.easeOut",
                    delay: 2
                }, 0);
                timelineHowToRecord.from("#idPainthree", {
                    duration: 0.8,
                    autoAlpha: 0,
                    ease: "Power4.easeOut",
                    delay: 2.5
                }, 0);
                timelineHowToRecord.from("#idPainfour", {
                    duration: 0.8,
                    autoAlpha: 0,
                    ease: "Power4.easeOut",
                    delay: 3
                }, 0);
                
                timelineHowToRecord.from(".ellipses", {
                    duration: 0.5,
                    y: 30,
                    autoAlpha: 0,
                    ease: "Power4.easeOut",
                    delay: 3.3
                }, 0);
        });
        
        return () => ctx.revert();
    }, []);

    return <>
       <section className="overflow-hidden">
            <div className='container container-sm how-to-record'>
                <div className='row justify-content-center'>
                    <div className='col-md-5 image-section mb-4 mb-md-0 text-center text-md-start'>
                        <img src={Recordsymptoms} alt='Record symptoms' />
                    </div>
                    <div className='col-md-6 content-section text-center text-md-start'>
                        <h2 className='h4 mb-3 symtoms-title'>How to record your symptoms</h2>
                        <p className="text1 how-to-recordText1">To record your symptoms, scroll down until you see the six symptom categories represented by swirling dots</p>
                        <div className='record-syptoms mt-5'>
                            <p className="text2 how-to-recordText2">Interact with the swirling dots by clicking through 4 levels to select your level of experience that day</p>
                            <div class="record-syptoms-wrapper mt-4">
                                <div id="idPainone"  className='record-syptoms__items'>
                                    <img src={Painone} alt='none' />
                                    <span>None</span>
                                </div>

                                <div id="idPaintwo"  className='record-syptoms__items'>
                                    <img src={Paintwo} alt='Uncomfortable' />
                                    <span>Uncomfortable</span>
                                </div>

                                <div id="idPainthree"  className='record-syptoms__items'>
                                    <img src={Painthree} alt='Disruptive' />
                                    <span>Disruptive</span>
                                </div>

                                <div id="idPainfour"  className='record-syptoms__items'>
                                    <img src={Painfour} alt='Severe' />
                                    <span>Severe</span>
                                </div>

                            </div>

                            <p className='mt-5 ellipses'>Click the ellipses <span><img src={More} alt='More' /></span> next to each symptom category to open the detailed symptoms list</p>
                        </div>
                    </div>

                </div>

            </div>

        </section>
    </>
}
export default HowToRecord;