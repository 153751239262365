import { useEffect, useRef } from "react";
import { gsapSplitTextLineAnimation, gsapAlphaYInAnimation } from "../../gsap/common";

function Mission( props ){
    const ourMissionRef = useRef([]);
    const ourMissionContentRef = useRef([]);
    ourMissionContentRef.current = [];
  
    useEffect(() => {
      const ctx = props.gsap.context(() => {
        
        gsapSplitTextLineAnimation(props.gsap, ourMissionRef, ourMissionRef,
          1, 50, 0.6, "top bottom", "bottom bottom");
  
        // gsapSplitTextLineAnimation(props.gsap, peterRef, peterRef,
        // 1, 50, 0.6, "top bottom", "bottom bottom");
  
        gsapAlphaYInAnimation(props.gsap, ourMissionContentRef, ourMissionContentRef, 0, 30, 0.5, 0.4, 0, "bottom bottom", "bottom bottom");
  
      });
      return () => ctx.revert();
    }, []);
  
    
    const _ourMissionContentRef = el => {
      if (el && !ourMissionContentRef.current.includes(el)) {
        ourMissionContentRef.current.push(el);
      }
    };

    return(
        <>
           <section className="about-mission text-center spacer-bottom">
                <div className="container">
                    <div className="row">
                        <div ref={ourMissionRef} className="col-md-9 mx-auto">
                            <h2>Our mission</h2>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <div className="d-flex flex-column h5">
                                <span ref={_ourMissionContentRef}>Decrease the time from symptom onset to diagnosis</span>
                                <span ref={_ourMissionContentRef}>Support and educate those burdened by chronic conditions</span>
                                <span ref={_ourMissionContentRef}>Improve patient-physician communication and experience</span>
                                <span ref={_ourMissionContentRef}>Prioritize research for better diagnostics and treatments</span>
                            </div>
                        </div>
                    </div>
                </div>

            </section> 
        </>
    )
}

export default Mission;