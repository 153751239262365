import { useEffect } from 'react';
import VideoPlayer from '../HomePage/VideoPlayer';
import gsap from 'gsap';
import { SuperSEO } from "react-super-seo";

function ExplainerVideo() {
  useEffect(() => {
    gsap.to(window, {
      scrollTo: {
        y: ".pageHeader",
      },
      duration: 0,
    });
  }, []);
  return <>
    <SuperSEO title="Uterinekind™ - Intro video" />
    <SuperSEO description="Explore Uterinekind through our Explainer Video: Learn how our platform simplifies tracking uterine health symptoms, empowering you with the knowledge and tools to manage your health data for better outcomes." />
    <section className='about-hero spacer-b h-auto'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
          <VideoPlayer />
          </div>
        </div>
      </div>
    </section>  
    
  </>
}

export default ExplainerVideo