import React, { useEffect, useRef } from 'react'
import { gsapAlphaXInAnimation, gsapSplitTextLineAnimation, gsapScrollToElementById } from '../../gsap/common'

import UlogoTrans from '../../../assets/images/U-logo-transp.svg'

function MoniterAndMeasure(props) {
    const monitorRef = useRef();
    const measureRef = useRef();
    const connectTheDotsRef = useRef();
    const monitorAndMeasureTriggerRef = useRef();
    const monitorContentRef = useRef();

    useEffect(() => {
        const ctx = props.gsap.context(() => {

            /*
                *   function: gsapAlphaXInAnimation
                *   @param: gsap obj, element Ref, triggerRef, autoAlpha value, x value, stagger, duration
            */
            gsapAlphaXInAnimation(props.gsap, monitorRef, monitorAndMeasureTriggerRef,
                0, 300, 0.5, 1, 0);
            gsapAlphaXInAnimation(props.gsap, measureRef, monitorAndMeasureTriggerRef,
                0, 300, 0.5, 1, .45);
            gsapAlphaXInAnimation(props.gsap, connectTheDotsRef, monitorAndMeasureTriggerRef,
                0, 300, 0.5, 1, .95);


            /*  
                @param: 
                    *   gsap obj, 
                    *   element Ref, 
                    *   elementTrigger Ref, 
                    *   opacity
                    *   yPercent
                    *   duration
                *
            */
            gsapSplitTextLineAnimation(props.gsap, monitorContentRef, monitorAndMeasureTriggerRef,
                1, 25, 1);

        });
        return () => ctx.revert();
    }, []);

    const handleClick = (elementId) => {
        gsapScrollToElementById(props.gsap, elementId, 1)
    }

    return (
        <>
            <section className="bg-secondary join-now  position-relative text-center spacer overflow-hidden" id="join-now">
                <div className="container container-sm">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="text-center">
                                <img src={UlogoTrans} alt="uterinekind" />
                                <p className="h4 mt-5"> is an app that helps you</p>
                            </div>

                            <div ref={monitorAndMeasureTriggerRef} className="join-now__content text-center mt-5">
                                <h2 className="d-flex flex-column animate-vertical-heading">
                                    <span ref={monitorRef}>monitor</span>
                                    <span ref={measureRef}>measure</span>
                                    <span ref={connectTheDotsRef}>and connect the dots </span>
                                </h2>
                                <div ref={monitorContentRef} className="animated-content">
                                    <p className="h4 fw-bold mt-4 mb-5">on symptoms you experience, which may be associated with chronic conditions that affect those born with a uterus</p>
                                    <p className="h4 fw-bold">We invite you to be a member of U</p>
                                </div>

                                <a href="https://app.uterinekind.com/" className="btn mt-5">Join now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MoniterAndMeasure