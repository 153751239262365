import { useEffect } from "react";
import { faqQuestions } from "../../../utits/staticContent/Faq";

import JSONLD from 'react-structured-data/dist/JSONLD';
import Generic from 'react-structured-data/dist/schemas/Generic';
import GenericCollection from 'react-structured-data/dist/schemas/GenericCollection'
import Question from 'react-structured-data/dist/schemas/Question'

function FaqJsonLdStructure() {
    useEffect(() => {
        console.log("kfjdklf");


    }, []);
    return <>
        <JSONLD>
            <Generic type="FAQPage" jsonldtype="FAQPage" >
                <GenericCollection type="mainEntity">
                    {faqQuestions().map(({ questions, answers }) => (
                        <Question name={questions} >
                            <Generic type="acceptedAnswer" jsonldtype="Answer" schema={{ "text": answers }} />
                        </Question>
                    ))}
                </GenericCollection>
            </Generic>
        </JSONLD>
    </>
}

export default FaqJsonLdStructure;