import {useEffect, useRef} from 'react';
import gsap from 'gsap';
import {gsapTitleInYAnimation} from '../../gsap/common';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import pwaIos from "../../../assets/images/pwa-ios.png";
import pwaAndroid from "../../../assets/images/pwa-android.png";
import pwaDeskEdge from "../../../assets/images/pwa-desk-edge.png";
import pwaDeskMacChrome from "../../../assets/images/pwa-desk-mac-chrome.png";
import cancelSub1 from "../../../assets/images/faq-how-cancel-subscription-1.png";
import cancelSub2 from "../../../assets/images/faq-how-cancel-subscription-2.png";

import './css/index.css'
import '../HomePage/css/faq.css';
import FaqJsonLdStructure from './FaqJsonLDStructure';

import {Link} from 'react-router-dom';
import {SuperSEO} from "react-super-seo";

function FaqPage(props) {
    const chooseYourRef = useRef([]);
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            },
            duration: 0,
        });
    }, []);

    useEffect(() => {
        const ctx = gsap.context(() => {

            /*
            * function: gsapTitleInXAnimation,
            * @param: gsap obj, element Ref, ease, duration, opacity, x, delay
            */
            gsapTitleInYAnimation(gsap, chooseYourRef, "expo.out", 1, 0, "200px", 0);
        });

        return () => ctx.revert();
    }, [])

    return <>
        <SuperSEO title="Uterinekind™ - FAQ"/>
        <SuperSEO description="Visit the Uterinekind FAQ page for comprehensive answers to all your questions about tracking and managing uterine health symptoms, ensuring you're well-informed for better health outcomes." />
        <FaqJsonLdStructure/>
        <section className='hero-shadow hero-section-spacer spacer-bottom'>
            <div className='container container-sm position-relative'>
                <div className='row'>
                    <div className='col-md-12 text-center' ref={chooseYourRef}>
                        <h1 className='h2 text-uppercase'>Faq<span className='h3'>s</span></h1>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-12 faq faq-item-spacer'>
                        <div className="accordion" id="accordionExample">

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo">
                                        <div className="faq__label"><span className="d-block">What is the Uterinekind app?</span>

                                        </div>
                                    </button>
                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                         aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>It's an app that makes it easy to: </p>
                                            <ul>
                                                <li>collect detailed symptoms data</li>
                                                <li>monitor symptoms and treatment impact on symptoms</li>
                                                <li>share data with your care teams</li>
                                                <li>learn about chronic conditions</li>
                                                <li>learn from world-class researchers and physicians through exclusive
                                                    content
                                                </li>
                                                <li>support gynecologic and reproductive research</li>
                                                <li>connect with others living with chronic conditions often (wrongly)
                                                    associated with “bad periods”
                                                </li>
                                            </ul>
                                            <br/>
                                            <p>Uterinekind supports research laboratories that are committed to finding
                                                the root causes of chronic conditions primarily impacting those born
                                                female. Our understanding of these conditions is primitive, due to a
                                                lack of funding at the federal level. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                            aria-expanded="false" aria-controls="collapseThree">

                                        <div className="faq__label"><span className="d-block">Who is it for?</span>

                                        </div>
                                    </button>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                         aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>The app is for people who experience symptoms often dismissed as “bad
                                                periods” and struggle with getting a diagnosis and the right treatment.
                                                If you experience these symptoms and are frustrated with your healthcare
                                                experience, the Uterinekind app can help.</p>
                                            <br/>
                                            <p>Common symptoms of chronic conditions:</p>
                                            <ul>
                                                <li>heavy bleeding</li>
                                                <li>painful cramps</li>
                                                <li>chronic pain</li>
                                                <li>bloating</li>
                                                <li>long periods</li>
                                                <li>irregular periods</li>
                                                <li>large clots</li>
                                            </ul>
                                            <br/>
                                            <p>Conditions we focus on:</p>
                                            <ul>
                                                <li>endometriosis</li>
                                                <li>AUB (abnormal uterine bleeding) which includes:
                                                    <ul>
                                                        <li>fibroids</li>
                                                        <li>adenomyosis</li>
                                                        <li>polyps</li>
                                                    </ul>
                                                </li>
                                                <li>PCOS and other ovulatory disorders</li>
                                                <li>endometrial disorders</li>
                                                <li>chronic pelvic pain</li>
                                                <li>pelvic inflammatory disease</li>
                                                <li>uterine prolapse</li>
                                            </ul>


                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingeight">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseeight"
                                            aria-expanded="false" aria-controls="collapseeight">

                                        <div className="faq__label"><span
                                            className="d-block">How much does it cost?</span>
                                        </div>
                                    </button>
                                    <div id="collapseeight" className="accordion-collapse collapse"
                                         aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>The app is 100% free to use. No paywalls. No ads.</p>
                                            {/*<p>$3.99 per month, billed monthly</p>*/}
                                            {/*<p>$39.99 for a year, billed once</p>*/}
                                            {/*<p>All memberships include a 7-day free trial.</p>*/}
                                            {/*<p>Cancel the membership anytime, all access remains until the end of the paid cycle.</p>*/}
                                            {/*<p>10% of every dollar received goes directly to funding research on chronic conditions like endometriosis, PCOS and AUB, as well as research on the female body.</p>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/*<div className="accordion-item">*/}
                            {/*    <div className="accordion-header" id="headingnine">*/}
                            {/*        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">*/}

                            {/*            <div className="faq__label"><span className="d-block">Why isn’t the app free?</span>*/}


                            {/*            </div>*/}
                            {/*        </button>*/}
                            {/*        <div id="collapsenine" className="accordion-collapse collapse" aria-labelledby="headingnine" data-bs-parent="#accordionExample">*/}
                            {/*            <div className="accordion-body">*/}
                            {/*                <p>The app is not free because we don’t sell your data to fund the app, and we don’t allow ads or manufacturer sponsored content of any kind. Your membership funds new features, fresh content every week, and research on the conditions that impact our members. </p>*/}
                            {/*                <p>We believe that your membership will pay for itself by reducing the number of doctor appointments required to get a diagnosis and treatment, and by reducing the time from symptom onset to definitive diagnosis. </p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}


                            <div className="accordion-item">
                                <div className="accordion-header" id="headingten">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseten"
                                            aria-expanded="false" aria-controls="collapseten">

                                        <div className="faq__label"><span
                                            className="d-block">What does the app include?</span>


                                        </div>
                                    </button>

                                    <div id="collapseten" className="accordion-collapse collapse"
                                         aria-labelledby="headingten" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>A tool that makes it easy to measure and monitor your symptoms</p>
                                            <p>A diary to record your experience in your own words</p>
                                            <p>A symptoms summary report to share with care teams</p>
                                            <p>The app includes exclusive member content featuring these conditions and
                                                more:</p>
                                            <ul>
                                                <li>articles</li>
                                                <li>podcasts</li>
                                                <li>current research summarized for you</li>
                                                <li>interviews with physicians, researchers and patients</li>
                                                <li>monthly Q&As</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingtwenty">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapsetwenty"
                                            aria-expanded="false" aria-controls="collapsetwenty">

                                        <div className="faq__label"><span className="d-block">Who’s the team behind Uterinekind?</span>


                                        </div>
                                    </button>
                                    <div id="collapsetwenty" className="accordion-collapse collapse"
                                         aria-labelledby="headingtwenty" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">

                                            <p>For the last decade, we have created educational content (including
                                                websites and other digital experiences, videos and live events) for
                                                patients and health care professionals. We focused on chronic conditions
                                                that primarily impact the female body. Our clients were manufacturers
                                                and physician practices. All of the content we created needed to meet
                                                stringent FDA rules. </p>

                                            <p>While we were successful, we had no control over how the content was
                                                delivered to the patient community, nor how it was used. The problems
                                                patients identified in market research were not being fixed, and they
                                                had trust issues with manufacturers. </p>

                                            <p>We took those learnings and self-funded the Uterinekind app to have a
                                                more direct impact on the people who needed support the most: those
                                                living with debilitating symptoms that are often dismissed,
                                                underdiagnosed or misdiagnosed. </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                            aria-expanded="false" aria-controls="collapseFour">

                                        <div className="faq__label"><span className="d-block">Is my private information safe?</span>


                                        </div>
                                    </button>
                                    <div id="collapseFour" className="accordion-collapse collapse"
                                         aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Yes.</p>
                                            <p>Your privacy is a top priority for us. We purposely do not ask for
                                                information like full name, age or gender. You can even use a nickname.
                                                But we do need an email address to register you. We guard this email and
                                                will not spam you. </p>
                                            {/*<p>We process payments through the Stripe payment platform. Stripe is a PCI*/}
                                            {/*    Service Provider Level 1, which is the highest grade of payment*/}
                                            {/*    processing security.</p>*/}
                                            <p>Please review our <Link to="/privacy-policy"
                                                                       className='primary-text-color'>privacy
                                                policy</Link> for specific information about the protections in place to
                                                secure your information.
                                            </p>
                                            <p>You can delete your data at any time within the app.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingFive">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                            aria-expanded="false" aria-controls="collapseFive">

                                        <div className="faq__label"><span className="d-block">Do you sell or share my symptoms data?</span>
                                            <div id="collapseFive" className="accordion-collapse collapse"
                                                 aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>No.</p>
                                                    <p>We won't sell your symptoms data or personal information for any
                                                        reason. We share certain pieces of information only for
                                                        administrative tasks like signing up for app.</p>
                                                    <p>We will not monetize your data. In 2023 you will have the
                                                        opportunity to allow your data to be used for research, but that
                                                        choice is 100% yours. And you'll know who is using it and for
                                                        what purpose.</p>
                                                    <p>We will never share your information to any manufacturer, such as
                                                        a pharmaceutical company or other manufacturer.</p>
                                                    <p>Our technology team is committed to employing bank-level
                                                        security.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </button>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingsix">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapsesix"
                                            aria-expanded="false" aria-controls="collapsesix">

                                        <div className="faq__label"><span className="d-block">Do you sell my personal information like my email?</span>


                                        </div>
                                    </button>
                                    <div id="collapsesix" className="accordion-collapse collapse"
                                         aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>No.</p>
                                            <p>Your information is not a product to us; you’re a member. Protecting your
                                                privacy is our top priority. </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingeleven">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseheadingeleven"
                                            aria-expanded="false" aria-controls="collapseheadingeleven">

                                        <div className="faq__label"><span className="d-block">What conditions does the app focus on?</span>


                                        </div>
                                    </button>

                                    <div id="collapseheadingeleven" className="accordion-collapse collapse"
                                         aria-labelledby="headingheadingeleven" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>It focuses on chronic conditions that are underdiagnosed and
                                                undertreated, like fibroids, endometriosis and PCOS.</p>
                                            <p>List of conditions</p>
                                            <p>Endometriosis</p>
                                            <p>Abnormal Uterine Bleeding (AUB)</p>
                                            <p>AUB includes:</p>
                                            <ul>
                                                <li>fibroids</li>
                                                <li>adenomyosis</li>
                                                <li>PCOS</li>
                                                <li>endometrial and ovulatory dysfunctions</li>
                                                <li>polyps</li>
                                            </ul>
                                            <br/>
                                            <p>Pelvic inflammatory disease</p>
                                            <p>Chronic pelvic pain</p>
                                            <p>Uterine prolapse</p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingseven">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseseven"
                                            aria-expanded="false" aria-controls="collapseseven">

                                        <div className="faq__label"><span className="d-block">Does the app diagnose my condition?</span>


                                        </div>
                                    </button>
                                    <div id="collapseseven" className="accordion-collapse collapse"
                                         aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>No. Only your doctor can definitively diagnose your condition.</p>
                                            <p>Doctors state that detailed symptoms tracked daily over a period of time
                                                are the key to diagnosing the cause of chronic conditions.</p>
                                            <p>With a detailed record of your symptoms, and the benefit of being
                                                educated on conditions and up-to-date on the latest research, we believe
                                                members will feel more confident in their appointments. And they’ll be
                                                able to spot misinformation and advocate from a position of strength.
                                                This will save time and money, and reduce stress. </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingtwelve">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapsetwelve"
                                            aria-expanded="false" aria-controls="collapsetwelve">

                                        <div className="faq__label"><span className="d-block">Do you have doctors or nurses available for consultations?</span>


                                        </div>
                                    </button>

                                    <div id="collapsetwelve" className="accordion-collapse collapse"
                                         aria-labelledby="headingtwelve" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Not at this time. But we do have interviews with world-class physicians
                                                who specialize in chronic gynecologic conditions that are like free
                                                consultations packed with tips and advice to guide you and save you time
                                                and money. </p>
                                            <p>Some of our expert guest physicians do virtual consultations through
                                                their practice. </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingthirteen">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapsethirteen"
                                            aria-expanded="false" aria-controls="collapsethirteen">

                                        <div className="faq__label"><span className="d-block">Is this a period-tracking app?</span>


                                        </div>
                                    </button>

                                    <div id="collapsethirteen" className="accordion-collapse collapse"
                                         aria-labelledby="headingthirteen" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>If you choose, you can use the app to record your cycle in the diary and
                                                in the symptom category covering bleeding.</p>
                                            <p>It does not predict ovulatory cycles or diagnose fertility and cycle
                                                issues.</p>
                                            <p>The app focuses on helping people whose symptoms are dismissed as “bad
                                                periods” but are often signs of an underlying, undiagnosed condition
                                                like endometriosis, fibroids, PCOS and others. These conditions impact
                                                millions of people. Improving their quality of life and getting them on
                                                the path to a diagnosis without delay is our focus.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingfourteen">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapsefourteen"
                                            aria-expanded="false" aria-controls="collapsefourteen">

                                        <div className="faq__label"><span className="d-block">How is the app different from a period tracker?</span>


                                        </div>
                                    </button>

                                    <div id="collapsefourteen" className="accordion-collapse collapse"
                                         aria-labelledby="headingfourteen" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>It doesn’t track your period, it helps you monitor, measure and connect
                                                the dots on symptoms often associated with “bad periods”. It focuses on
                                                common chronic conditions that people struggle to get diagnosed. </p>
                                            <ul>
                                                <li>simple way to measure and monitor detailed symptoms over time</li>
                                                <li>easy to share symptoms with your doctor in a format they can quickly
                                                    scan
                                                </li>
                                                <li>stay informed and educated on chronic gynecologic conditions</li>
                                                <li>unites researchers, doctors and patients in conversations</li>
                                                <li>supports efforts to increase funding for female and reproductive
                                                    health research
                                                </li>
                                                <li>10% of all money received goes directly to research</li>
                                                <li>a companion as you navigate the health system and seek a diagnosis
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingsixteen">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapsesixteen"
                                            aria-expanded="false" aria-controls="collapsesixteen">

                                        <div className="faq__label"><span className="d-block">How easy is it to enter symptoms?</span>


                                        </div>
                                    </button>
                                    <div id="collapsesixteen" className="accordion-collapse collapse"
                                         aria-labelledby="headingsixteen" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Really, really easy. With a few taps, you can capture detailed
                                                information. Click save and the app graphs the data for you and builds
                                                your symptoms summary for you to use in appointments.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingseventeen">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseseventeen"
                                            aria-expanded="false" aria-controls="collapseseventeen">

                                        <div className="faq__label"><span className="d-block">What is the time commitment?</span>


                                        </div>
                                    </button>
                                    <div id="collapseseventeen" className="accordion-collapse collapse"
                                         aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Record your symptoms in just a few minutes each day. And then spend as
                                                much time as you want, as often as you want, exploring content created
                                                just for you. </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingeighteen">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseeighteen"
                                            aria-expanded="false" aria-controls="collapseeighteen">

                                        <div className="faq__label"><span className="d-block">Do I need to input my symptoms everyday?</span>


                                        </div>
                                    </button>
                                    <div id="collapseeighteen" className="accordion-collapse collapse"
                                         aria-labelledby="headingeighteen" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>We recommend you input symptoms every day. This helps to:</p>
                                            <ul>
                                                <li>paint an accurate picture of your symptoms over time</li>
                                                <li>show trends in symptoms</li>
                                                <li>show how symptoms impact each other</li>
                                                <li>show how treatments impact symptoms</li>
                                                <li>provide your care teams with accurate, detailed data they can use to
                                                    diagnose the root cause of your symptoms
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingnineteen">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapsenineteen"
                                            aria-expanded="false" aria-controls="collapsenineteen">

                                        <div className="faq__label"><span className="d-block">Can I receive reminder notifications?</span>


                                        </div>
                                    </button>
                                    <div id="collapsenineteen" className="accordion-collapse collapse"
                                         aria-labelledby="headingnineteen" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Notifications are coming! Stay tuned...</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingfifteen">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapsefifteen"
                                            aria-expanded="false" aria-controls="collapsefifteen">

                                        <div className="faq__label"><span className="d-block">Is the Uterinekind app available in other languages?</span>


                                        </div>
                                    </button>
                                    <div id="collapsefifteen" className="accordion-collapse collapse"
                                         aria-labelledby="headingfifteen" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>The symptom summary report that you can share with physicians, mental
                                                health professionals and physical therapists, among others, is available
                                                in Spanish, French and English. The app itself and all written content
                                                will be translated into other languages beginning in early 2023.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/*<div className="accordion-item">*/}
                            {/*    <div className="accordion-header" id="headingOne">*/}
                            {/*        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">*/}
                            {/*            <div className="faq__label"><span className="d-block">How do I install the app on iOS?</span>*/}

                            {/*            </div>*/}
                            {/*        </button>*/}
                            {/*        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">*/}
                            {/*            <div className="accordion-body">*/}
                            {/*                <p>The U app can only be installed on iOS using the Safari web browser.</p>*/}
                            {/*                <ul>*/}
                            {/*                    <li>First, navigate to <a href="https://app.uterinekind.com" target="_blank" className='primary-text-color'>https://app.uterinekind.com</a> in Safari.</li>*/}
                            {/*                    <li>Press the "Share" button and select "Add to Home Screen" from the popup.</li>*/}
                            {/*                    <li>Lastly, tap "Add" in the top right corner to finish installing the app. It will now be on your home screen.</li>*/}
                            {/*                </ul>*/}
                            {/*                <p><img src={pwaIos} alt='Installing U app on iOS' /></p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                            {/*<div className="accordion-item">*/}
                            {/*    <div className="accordion-header" id="headingOneDotOne">*/}
                            {/*        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneDotOne" aria-expanded="false" aria-controls="collapseOneDotOne">*/}
                            {/*            <div className="faq__label"><span className="d-block">How do I install the U app on Android?</span>*/}

                            {/*            </div>*/}
                            {/*        </button>*/}
                            {/*        <div id="collapseOneDotOne" className="accordion-collapse collapse" aria-labelledby="headingOneDotOne" data-bs-parent="#accordionExample">*/}
                            {/*            <div className="accordion-body">*/}
                            {/*                <ul>*/}
                            {/*                    <li>First, navigate to <a href="https://app.uterinekind.com" target="_blank" className='primary-text-color'>https://app.uterinekind.com</a> in Chrome.</li>*/}
                            {/*                    <li>Press the "three dot" icon in the upper right to open the menu.</li>*/}
                            {/*                    <li>Select "Install app."</li>*/}
                            {/*                    <li>Press the “Install” button in the popup.</li>*/}
                            {/*                    <li>Press the "Add" button in the popup. U app is now installed and available on your home screen.</li>*/}
                            {/*                </ul>*/}
                            {/*                <p><img src={pwaAndroid} alt='Installing U app on Android' /></p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingOneDotTwo">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOneDotTwo"
                                            aria-expanded="false" aria-controls="collapseOneDotTwo">
                                        <div className="faq__label"><span className="d-block">How do I install the app on Windows PC or Mac?</span>

                                        </div>
                                    </button>
                                    <div id="collapseOneDotTwo" className="accordion-collapse collapse"
                                         aria-labelledby="headingOneDotTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Google Chrome and Microsoft Edge currently have the best support for the
                                                app on Windows and Mac OS. The installation process is very similar for
                                                both browsers.</p>
                                            <ul>
                                                <li>First, navigate to <a href="https://app.uterinekind.com"
                                                                          target="_blank"
                                                                          className='primary-text-color'>https://app.uterinekind.com</a>.
                                                </li>
                                                <li>There will be an icon on the right side of the URL bar. Click the
                                                    icon then click the "Install" button.
                                                </li>
                                                <li>Alternatively, you can install the app from the "three dot" menu.
                                                </li>
                                            </ul>
                                            <p>On a Windows PC, the app will be available in the start menu. On a Mac,
                                                the app will be in the launcher and in the spotlight search.</p>
                                            <p><img src={pwaDeskEdge} alt='Installing app on Edge'/></p>
                                            <p><img src={pwaDeskMacChrome} alt='Installing app on Mac Chrome'/></p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingOneDotThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOneDotThree"
                                            aria-expanded="false" aria-controls="collapseOneDotThree">
                                        <div className="faq__label"><span className="d-block">Can I use the app without installing it?</span>

                                        </div>
                                    </button>
                                    <div id="collapseOneDotThree" className="accordion-collapse collapse"
                                         aria-labelledby="headingOneDotThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Absolutely!</p>
                                            <p>The app can be used in any browser and on any platform. Navigate to <a
                                                href="https://app.uterinekind.com" target="_blank"
                                                className='primary-text-color'>https://app.uterinekind.com</a>, and
                                                register or login to start/continue your journey.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/*<div className="accordion-item">*/}
                            {/*    <div className="accordion-header" id="headingOneDotFour">*/}
                            {/*        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneDotFour" aria-expanded="false" aria-controls="collapseOneDotFour">*/}
                            {/*            <div className="faq__label"><span className="d-block">How can I cancel my membership?</span>*/}

                            {/*            </div>*/}
                            {/*        </button>*/}
                            {/*        <div id="collapseOneDotFour" className="accordion-collapse collapse" aria-labelledby="headingOneDotFour" data-bs-parent="#accordionExample">*/}
                            {/*            <div className="accordion-body">*/}
                            {/*                <p>You can easily cancel your membership at any time. Once you cancel, your plan will remain available until the end of your billing period.</p>*/}
                            {/*                <ul>*/}
                            {/*                    <li>Inside the app, click on the top right hamburger menu.</li>*/}
                            {/*                    <li>Then click on "My profile".</li>*/}
                            {/*                    <li>Then click on "Manage subscription".</li>*/}
                            {/*                    <li>This will bring you to the Stripe page showing your current plan. Click "Cancel plan".</li>*/}
                            {/*                    <li>Confirm that you really want to cancel your plan.</li>*/}
                            {/*                    <li>Your plan is now cancelled. Click the "Return to Uterinekind" in the top left of your screen to go back to the app.</li>*/}
                            {/*                </ul>*/}
                            {/*                <p><img src={cancelSub1} alt='Cancel the app subscription, part 1' /></p>*/}
                            {/*                <p><img src={cancelSub2} alt='Cancel the app subscription, part 2' /></p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                            <div className="accordion-item">
                                <div className="accordion-header" id="headingtwenty-one">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseheadingtwenty-one"
                                            aria-expanded="false" aria-controls="collapseFive">

                                        <div className="faq__label"><span className="d-block">I have a comment or question, how can I contact you? </span>


                                        </div>
                                    </button>
                                    <div id="collapseheadingtwenty-one" className="accordion-collapse collapse"
                                         aria-labelledby="headingheadingtwenty-one" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>The best way to contact us with suggestions and questions is to send an
                                                email:</p>
                                            <a href="mailto:hello@uterinekind.com"
                                               className='primary-text-color'>hello@uterinekind.com</a>
                                            <div>
                                                <p>Our mailing address is:</p>
                                                <p>Uterinekind, Inc.<br/>6 Liberty Sq. #2479<br/>Boston, MA 02109 </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default FaqPage
