import { useEffect, useState, React } from 'react';
import XMLParser from 'react-xml-parser';
import gsap from 'gsap';
import './css/podcast.css'
import tagline from '../../../assets/images/tagline.png'
import { defaultValues } from '../../../constant/endpoints';
import LatestEpisode from './LatestEpisode';
import PodcastItem from './PodcastItem';
import { useSelector, useDispatch } from 'react-redux';
import { podcastItemAction, latestPodcastItem } from '../../../store/actions/PodcastItems';

import { getAudioId, splitTitle, formatDuration, getPodcastAurthor, seoFriendlyUrl  } from '../../../utils/functions';

import { SuperSEO } from 'react-super-seo';
import { PODCAST } from '../../../constant/endpoints';
import { getRequest } from '../../../config/httpClient/axiosClient';
import { RESPONSE_MESSAGES, RESPONSE_CODE } from '../../../constant';

function Podcast(props) {

    const [limit, setLimit] = useState(PODCAST.DEFAULT_VALUES.LIMIT);
    const [offset, setOffset] = useState(PODCAST.DEFAULT_VALUES.OFFSET);
    const [episodeList, setEpisodeList] = useState([]);
    
    const [errorMessage, setErrorMessage] = useState("");

    var _podastArray = [];
    _podastArray['item'] = [];
    const _podcastItems = useSelector(state => state.PodcastLists);
    const latestPodcastId = useSelector(state => state.LatestEpisode).id;

    const _dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true)
    let [podcastItems, setPodcastItems] = useState([])

    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            },
            duration: 0,
        });

    }, []);

    useEffect( ()=>{
        if( _podcastItems.length === 0 ){
            getPodcasetEpisodes(`${PODCAST.EPISODES}`);
        }

        async function getPodcasetEpisodes(url) {
            setIsLoading(true);
            try {
                const response = await getRequest(url);
                const {status, data:{collection}} = response;
                if( status === RESPONSE_CODE[200] ){
                    setEpisodeList(collection);
                    _dispatch(latestPodcastItem({
                        title: collection[0].title,
                        id: collection[0].id,
                        seoFriendlyUrl: seoFriendlyUrl(collection[0].title),
                        searchFriendlyUrl: seoFriendlyUrl(collection[0].title, " "),
                        guid: collection[0].guid,
                        description: collection[0].description,
                        link: collection[0].slug,
                        publistDate: collection[0].published_at,
                        author: collection[0].author === undefined ? null : collection[0].author,
                        duration: collection[0].duration,
                        audioId: collection[0].guid,
                    }));
                    _dispatch(podcastItemAction(collection))
                } else {
                    setErrorMessage(RESPONSE_MESSAGES.FETCHING_EPISODES);
                }
            } catch (error){

                console.warn(error);
                // _errorMessage = ApiErrorMessage(error);
                setErrorMessage("");
                // setErrorMessage(_errorMessage);
                setIsLoading(false);              
            }
        }

    },[]);

    if (!isLoading && podcastItems.length > 0) {
        // something here to confirm.
    }
    
    return <>
        <SuperSEO title="Uterinekind™ - Podcast" />
        <SuperSEO description="Explore Uterinekind's Podcast Hello Uterus: Dive into insightful discussions on uterine health. Our podcast offers a wealth of information, empowering you with the knowledge to understand and manage your symptoms for better health outcomes." />

        {_podcastItems.length > 0
            ?
            <>
                <LatestEpisode />
                <section className="podcast spacer pt-0" style={{ 'padding-top': '100px' }}>
                    <div className="container container-sm">
                        <div className="row">
                            {_podcastItems.map((item, index) => {
                                const { published_at, title, author, duration, guid, description, id } = item;
                                if( latestPodcastId !== id ){
                                    return (
                                        <PodcastItem
                                            key={guid}
                                            description={description}
                                            id={id}
                                            link={seoFriendlyUrl(title)}
                                            publistDate={published_at}
                                            duration={duration}
                                            title={title}
                                            author={author}
                                            guid={guid}
                                            audioId={id}
                                        />
                                    );
                                }
                                
                            })}
                        </div>
                    </div>
                </section>
            </>
            : <div className='loader h5 text-center d-flex align-items-center justify-content-center'>
                <div className='loader__content'>
                    <img src={tagline} alt="tagline" />
                    <div className='mt-4'>Loading please wait..</div>
                    <div className='loading-spinner'>
                        <span className='first-point'></span>
                        <span className='second-point'></span>
                        <span className='third-point'></span>
                    </div>
                </div>
            </div>
        }
    </>
}

export default Podcast