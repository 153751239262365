import { useEffect, useState, React } from 'react';
import whitelogo from '../../../assets/images/white-img.png'
import colorlogo from '../../../assets/images/color-img.png'
import tagline from '../../../assets/images/tagline.png'
import taglineDevice from '../../../assets/images/tagline-device.png'
import music from '../../../assets/images/headphone.png'
import gsap from 'gsap';
import hanging from '../../../assets/images/hanging.png'
import { formatPublisedDate, splitTitle, getAudioId, seoFriendlyUrl } from '../../../utils/functions';

import { useDispatch } from 'react-redux';
import { activePodcastItem } from '../../../store/actions/PodcastItems';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

function LatestEpisode(props) {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    let navigate = useNavigate();
    const _dispatch = useDispatch();
    const _podcastItems = useSelector(state => state.LatestEpisode)
    
    function playPodcast(guid, id, title, published, description, link, audioId, duration) {
        _dispatch(activePodcastItem({
            title: title,
            id: id,
            seoFriendlyUrl: seoFriendlyUrl(title),
            searchFriendlyUrl: seoFriendlyUrl(title, " "),
            guid: guid,
            description: description,
            link: link,
            publistDate: published,
            author: "",
            duration: duration,
            audioId: audioId,
        }));

        let path = `/podcast/${seoFriendlyUrl(title)}`;
        navigate(path);
    }

    useEffect(() => {
        let tl = gsap.timeline({ repeat: -1, repeatDelay: .5 });
        tl.to(".podcast-hero", { "backgroundColor": "#218be3", duration: .5, ease: "power4" });
        tl.to(".second-point", { "backgroundColor": "#8a50c5", duration: .5, ease: "power4" });
        tl.to(".third-point", { "backgroundColor": "#f06022", duration: .5, ease: "power4" });
    }, [props])

    return <>
        <section className='podcast-hero pb-5'>
                    <div className='container container-sm'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='d-flex flex-wrap align-items-start perspective'>
                                    <div className='latest-episode text-center'>
                                    <div className='podcast-header mob-podcast-header'>
                                            <div className='logo-animation'>
                                                <img src={whitelogo} alt="whitelogo" />
                                                <img src={colorlogo} alt="colorlogo" />
                                            </div>
                                            <div className='podcast-tagline'>
                                                <img className='pct-dkp-img' src={tagline} alt="tagline" />
                                                <img className='pct-dkp-mob' src={taglineDevice} alt="tagline" />
                                            </div>
                                        </div>
                                        <img src={hanging} alt="hanging" className="hanging" />
                                        <div className='latest-episode__box' onClick={() => playPodcast(
                                            _podcastItems.guid,
                                            _podcastItems.id,
                                            _podcastItems.title,
                                            _podcastItems.publistDate,
                                            _podcastItems.description,
                                            _podcastItems.link,
                                            _podcastItems.audioId,
                                            // getAudioId(_podcastItems.enclosure),
                                            _podcastItems.duration
                                        )} style={{ 'cursor': 'pointer' }}>
                                            <span className='d-block h4 fw-bold latest-episode__heading mb-0'>Latest Episode</span>
                                            <img src={music} alt="music" className="music-icon" />
                                            {/*<span className='d-block'>{formatPublisedDate(_podcastItems.publistDate, "long")}</span>*/}
                                            <p className='h4 fw-normal mt-2' 
                                                dangerouslySetInnerHTML={{__html: _podcastItems.title}} 
                                            />
                                        </div>
                                    </div>
                                    <div className='latest-episode-content'>
                                        <div className='podcast-header d-none d-md-block'>
                                            <div className='logo-animation'>
                                                <img src={whitelogo} alt="whitelogo" />
                                                <img src={colorlogo} alt="colorlogo" />
                                            </div>
                                            <div className='podcast-tagline'>
                                                <img className='pct-dkp-img' src={tagline} alt="tagline" />
                                                <img className='pct-dkp-mob' src={taglineDevice} alt="tagline" />
                                            </div>
                                        </div>

                                        <h1 className='h4 primary-text-color'>{_podcastItems.title}</h1>
                                        <p className='my-3'>
                                            
                                            <div className='my-3' dangerouslySetInnerHTML={{__html: _podcastItems.description}} />
                                        </p>
                                        {/* <p className='my-3'>
                                            {isReadMore 
                                            ? <div className='my-3' dangerouslySetInnerHTML={{__html: `${_podcastItems.description.slice(0, 300)}...`}} /> 
                                            :  <div className='my-3' dangerouslySetInnerHTML={{__html: _podcastItems.description}} /> }
                                        </p>
                                        <div className='read-more my-4'>
                                            <a onClick={toggleReadMore} href={() => false} className='text-purple' style={{ cursor: "pointer" }}>
                                                {isReadMore ? "READ MORE" : "READ LESS"}
                                                <span>{isReadMore ? "+" : "-"}</span>
                                            </a>
                                        </div> */}
                                        <a onClick={() => playPodcast(
                                            _podcastItems.guid,
                                            _podcastItems.id,
                                            _podcastItems.title,
                                            _podcastItems.publistDate,
                                            _podcastItems.description,
                                            _podcastItems.link,
                                            _podcastItems.audioId,
                                            // getAudioId(_podcastItems.enclosure),
                                            _podcastItems.duration
                                        )}
                                            href={() => false} className='btn Play-btn'>Play
                                            <svg id="play-button-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <path id="Path_1" data-name="Path 1" d="M37.709,24.116l-14.667-10A.666.666,0,0,0,22,14.667v20a.666.666,0,0,0,1.042.551l14.667-10a.666.666,0,0,0,0-1.1ZM23.333,33.4V15.929L36.15,24.667Z" transform="translate(-7.333 -4.667)" fill="#fff" />
                                                <path id="Path_2" data-name="Path 2" d="M20,0A20,20,0,1,0,40,20,20.023,20.023,0,0,0,20,0Zm0,38.667A18.667,18.667,0,1,1,38.667,20,18.688,18.688,0,0,1,20,38.667Z" fill="#fff" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    </>
}
export default LatestEpisode;