import { useEffect, useRef } from "react";
import johnHeadshot from "../../../assets/images/John-Petrozza.jpg";
import Burd from "../../../assets/images/Burd-Irina_MD.jpg";
import Evelyn from "../../../assets/images/Evelyn.jpg";

import { gsapSplitTextLineAnimation, gsapAlphaYInAnimation } from "../../gsap/common";

function Team(props) {
  
  const teamImgref = useRef([]);
  const teamImg2ref = useRef([]);
  // teamImgref.current = [];
  
  const teamContentref = useRef([]);
  teamContentref.current = [];
 
  const teamContent2ref = useRef([]);
  teamContent2ref.current = [];

  useEffect(() => {
    const ctx = props.gsap.context(() => {
      gsapAlphaYInAnimation(props.gsap, teamImgref, teamImgref, 0, 30, 0.5, 0.4, 0, "top 80%", "top 80%");
      gsapAlphaYInAnimation(props.gsap, teamImg2ref, teamImg2ref, 0, 30, 0.5, 0.4, 0, "top 80%", "top 80%");
      gsapAlphaYInAnimation(props.gsap, teamContentref, teamContentref, 0, 30, 0.5, 0.4, 0, "bottom bottom", "bottom bottom");
      gsapAlphaYInAnimation(props.gsap, teamContent2ref, teamContent2ref, 0, 30, 0.5, 0.4, 0, "bottom bottom", "bottom bottom");

    });
    return () => ctx.revert();
  }, []);

  
  // const _teamImgref = el => {
  //   if (el && !teamImgref.current.includes(el)) {
  //     teamImgref.current.push(el);
  //   }
  // };
  
  // const _teamContentref = el => {
  //   if (el && !teamContentref.current.includes(el)) {
  //     teamContentref.current.push(el);
  //   }
  // };
  
  // const _teamContent2ref = el => {
  //   if (el && !teamContent2ref.current.includes(el)) {
  //     teamContent2ref.current.push(el);
  //   }
  // };
  return (
    <>
      <section className="team spacer">
        <div className="container container-sm">
          <div className="row justify-content-between">
            <div ref={teamImg2ref} className="col-md-6 pe-md-5 mb-5 mb-md-0">
            <div className="team__blk">
                <img src={Evelyn} className="w-50" />
                <div className="team__info">
                  <span className="heading-font-24 d-block primary-text-color">
                  Senior Medical Advisor
                  </span>
                  <span className="heading-font-24 d-block">
                  Evelyn Mitchell, MD
                  </span>
                  <div className="team__info__descp">
                  <p>Assistant Professor of Clinical Obstetrics and Gynecology</p>
                  <p>Faculty Director, OB/GYN Diversity & Inclusion Program</p>
                  <p>Keck School of Medicine</p>
                  <p>University of Southern California</p>
                  </div>
                </div>
              </div>
              
            </div>
            <div ref={teamImgref} className="col-md-6 ps-md-5">
              <div className="team__blk">
                <img  src={johnHeadshot} className="w-50"/>
                <div className="team__info">
                  <span className="heading-font-24 d-block primary-text-color">
                    Senior Medical Advisor
                  </span>
                  <span className="heading-font-24 d-block">
                    John Petrozza, MD
                  </span>
                  <div className="team__info__descp">
                    <p>Reproductive Endocrinologist | Reproductive Surgeon</p>
                    <p>
                      Massachusetts General Hospital Chief, Division of
                      Reproductive Medicine and IVF
                    </p>
                    <p>Director, Massachusetts General Fertility Center</p>
                    <p>Co-Director, MGH Integrated Fibroid Program</p>
                  </div>
                </div>
              </div>
            </div>
            <div ref={teamImgref} className="col-md-6 pe-md-5 mt-5 mb-md-0">
              <div className="team__blk">
                <img src={Burd} className="w-50" />
                <div className="team__info">
                  <span className="heading-font-24 d-block primary-text-color">
                  Medical Content Reviewer
                  </span>
                  <span className="heading-font-24 d-block">
                  Irina Burd, MD, Ph.D.
                  </span>
                  <div className="team__info__descp">
                  <p>Professor and Chair of the Department of Obstetrics, Gynecology and Reproductive Sciences at the University of Maryland, Baltimore, MD</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default Team;
