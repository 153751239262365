import React, {useEffect, useRef} from 'react';
import './css/home-quote.css';

function HomeQuote(props) {
    let quote = '';
    switch (props.quoteNumber) {
        case 2:
            quote =
                <p className="mx-auto">“This app will cut my doctor appointments down by so much, which is saving me
                    money and years of time. Everyone needs to know about this.”<span
                        className="mt-2">-A.B.</span></p>;
            break;
        default:
            quote =
                <p className="mx-auto">“Everything is in one place. You just made so many lives easier. Sometimes it’s
                    awkward or hard to explain symptoms, so with everything in one tool, and I can share it with my
                    doctor, that’s what we’ve been looking for this entire time. So thank you.”<span
                        className="mt-2">-Q.H.</span></p>;
            break;
    }

    return (
        <section className="home content-grid bg-tertiary spacer text-center">
            <div className="container">
                <div className="row">
                    {quote}
                </div>
            </div>
        </section>
    )

}

export default HomeQuote;
