import React, {useEffect, useRef} from 'react';
import './css/home-contentgrid.css';
import Record from '../../../images/record.png';
import Monitor from '../../../images/monitor.png';
import Educational from '../../../images/educational.png';
import Generate from '../../../images/generate.png';

function ContentGrid(props) {
    return (
        <section className="content-grid bg-secondary spacer text-center">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-lg-10 mx-auto mb-5">
                        <h2 className="font-weight-300">Informed. Prepared. Empowered.</h2>
                    </div>
                    <div className="col-md-6 mb-5">
                        <div className="content-grid__blk">
                            <h3 className="px-3">Easily record detailed symptoms daily. See how they trend over time.
                                Monitor meds, treatments and lifestyle choices and see how they impact symptoms.</h3>
                            <img src={Record} alt="Easily record detailed symptoms daily. See how they trend over time."
                                 className="mt-4"/>
                        </div>
                    </div>

                    <div className="col-md-6 mb-8">
                        <div className="content-grid__blk">
                            <h3 className="px-3">Record your experience in your own words with a private health diary.
                                Individual entries may be shared with your care teams.</h3>
                            <img src={Monitor} alt="Generate" className="mt-4"/>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="content-grid__blk">
                            <h3 className="px-3">Generate a physician report with comprehensive, self-reported data.
                                Easy to scan charts designed for physicians.</h3>
                            <img src={Generate} alt="Learn" className="mt-4"/>
                        </div>
                    </div>
                    <div className="col-md-6 mb-5 mb-md-0">
                        <div className="content-grid__blk">
                            <h3 className="px-3">Educational content prepares members for a meaningful consultation.
                                Be informed and confidently advocate for yourself and your healthcare needs.</h3>
                            <img src={Educational} alt="Generate" className="mt-4"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentGrid;