import { useEffect, useState, React } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import XMLParser from 'react-xml-parser';
import { getAudioId, splitTitle, searchFriendlyUrl, seoFriendlyUrl } from '../../../utils/functions';
import PlayPodcast from "./PlayPodcast";
import PodcastItem from "./PodcastItem";
import gsap from "gsap";
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { PODCAST } from "../../../constant/endpoints";
import { RESPONSE_CODE } from '../../../constant';
import { getRequest } from '../../../config/httpClient/axiosClient';
import tagline from '../../../assets/images/tagline.png'
import { useSelector, useDispatch } from 'react-redux';
import { podcastItemAction, activePodcastItem, latestPodcastItem } from '../../../store/actions/PodcastItems';
import { getPodcastAurthor, formatDuration } from '../../../utils/functions';
import { SuperSEO } from 'react-super-seo';

function PodcastDetailsPage(props) {
    let navigate = useNavigate();
    let _count = 0;
    gsap.registerPlugin(ScrollToPlugin)
    const _podcastItems = useSelector(state => state.PodcastLists)
    const [isLoading, setIsLoading] = useState(true);
    let [podcastItems, setPodcastItems] = useState([]);
    let [episodeDetails, setEpisodeDetails] = useState({});
    const { podcastid } = useParams();
    const _activePodcastItems = useSelector(state => state.ActivePodcastEpisode);

    var _podastArray = [];
    _podastArray['item'] = [];
    const _dispatch = useDispatch();

    useEffect(() => {

        async function getEpisodeBySlug(URL) {
            setIsLoading(true);
            let _errorMessage;
            try {
                const { status, data: { collection } } = await getRequest(URL);
                if (status === RESPONSE_CODE[200]) {
                    var episodeId = collection[0].id;
                    getEpisodeById(episodeId);
                } else {
                    // error case in get episode id
                }
            } catch (error) {
                console.warn(error);
                setIsLoading(false);
            }
        }
        
        async function getEpisodeById(episodeId) {
            setIsLoading(true);
            try {
                const { status: episodeStatus, data: episodeData } = await getRequest( `${PODCAST.EPISODES_BY_ID}/${episodeId}`);
                if (episodeStatus === RESPONSE_CODE[200]) {
                    setEpisodeDetails(episodeData)
                    setIsLoading(false);
                    gsap.to(window, {
                        scrollTo: {
                            y: ".pageHeader",
                        },
                        duration: 0,
                    });
                } else {
                    // error case in get episode data"
                }
            } catch (error) {
                console.warn(error);
                setIsLoading(false);
            }
        }

        async function getPodcasetEpisodes(url) {
            setIsLoading(true);
            try {
                const response = await getRequest(url);
                const {status, data:{collection}} = response;
                // const {collection} = data;
                if( status === RESPONSE_CODE[200] ){
                    const _activeEpisode = getActiveEpisode(collection);
                    _dispatch(activePodcastItem({
                        title: collection[_activeEpisode.index].title,
                        id: collection[_activeEpisode.index].id,
                        seoFriendlyUrl: seoFriendlyUrl(collection[_activeEpisode.index].title),
                        searchFriendlyUrl: seoFriendlyUrl(collection[_activeEpisode.index].title, " "),
                        guid: collection[_activeEpisode.index].guid,
                        description: collection[_activeEpisode.index].description,
                        link: collection[_activeEpisode.index].slug,
                        publistDate: collection[_activeEpisode.index].published_at,
                        author: collection[_activeEpisode.index].author === undefined ? null : collection[_activeEpisode.index].author,
                        duration: collection[_activeEpisode.index].duration,
                        audioId: collection[_activeEpisode.index].guid,
                    }));
                    
                    _dispatch(latestPodcastItem({
                        title: collection[0].title,
                        id: collection[0].id,
                        seoFriendlyUrl: seoFriendlyUrl(collection[0].title),
                        searchFriendlyUrl: seoFriendlyUrl(collection[0].title, " "),
                        guid: collection[0].guid,
                        description: collection[0].description,
                        link: collection[0].slug,
                        publistDate: collection[0].published_at,
                        author: collection[0].author === undefined ? null : collection[0].author,
                        duration: collection[0].duration,
                        audioId: collection[0].guid,
                    }));
                    getEpisodeById(_activeEpisode.id);
                    _dispatch(podcastItemAction(collection));
                } else {
                    console.warn(response);
                }
            } catch (error){
                console.warn(error);
            }
        }

        if( _activePodcastItems && Object.keys(_activePodcastItems).length === 0 ){
            getPodcasetEpisodes(`${PODCAST.EPISODES}`);
        } else {
            getEpisodeById(_activePodcastItems.id);
        }

    }, [podcastid]);

    function getActiveEpisode( collection ){

        for(var index in collection){
            if( seoFriendlyUrl( collection[index].title) === podcastid ) {
                return {index:index,id:collection[index].id};
            }
        }
        return {index:0, id:0};
    }
    return <>

        {_activePodcastItems.title ? <SuperSEO title={`Uterinekind™ - ${_activePodcastItems.title}`} /> : ''}
        {_activePodcastItems.description ? <SuperSEO description={`${_activePodcastItems.description.substring(0, 300)}`} /> : ''}

        <section className='podcast-hero pb-5 podcast-spacer'>
            <div className='container container-sm'>
                <div className='row'>
                    <div className='col-md-12'>
                        { isLoading 
                            ?  <div className='loader h5 text-center d-flex align-items-center justify-content-center'>
                                    <div className='loader__content'>
                                        <img src={tagline} alt="tagline" />
                                        <div className='mt-4'>Loading please wait..</div>
                                        <div className='loading-spinner'>
                                            <span className='first-point'></span>
                                            <span className='second-point'></span>
                                            <span className='third-point'></span>
                                        </div>
                                    </div>
                                </div>
                            : <PlayPodcast gsap={gsap} episodeDetails={episodeDetails} />
                        }
                    </div>
                </div>
            </div>
        </section>
        <section className="podcast spacer pt-0" style={{ 'padding-top': '100px' }}>
            <div className="container container-sm">
                <div className="row">
                    {_podcastItems.length > 0 ? (
                   _podcastItems.map((item, index) => {
                    const { published_at, title, author, duration, guid, description, id } = item;
                    return (
                        <PodcastItem
                            key={guid}
                            description={description}
                            id={id}
                            link={seoFriendlyUrl(title)}
                            publistDate={published_at}
                            duration={duration}
                            title={title}
                            author={author}
                            guid={guid}
                            audioId={id}
                        />
                    );
                })
                    ) : ''}
                </div>
            </div>
        </section>
    </>
}
export default PodcastDetailsPage;