import { useEffect, React } from 'react';

import './css/TermsAndConditions.css'
import gsap from 'gsap';
import { SuperSEO } from 'react-super-seo';

function TermsAndConditions() {
 
    useEffect(() => {
        gsap.to(window, {
            scrollTo: {
                y: ".pageHeader",
            },
            duration: 0,
        });
    }, []);

    return <>
        <SuperSEO title="Uterinekind™ - Terms and conditions" />
         <section className="spacer-both hero-shadow">
            <div className="container container-sm position-relative">
                <div className="row">
                    <div className="col-md-12">
                    <h1 className='h3 mb-4'>Terms and conditions</h1>
                    <p>These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the uterinekind.com website (“Website”), the Uterinekind mobile application (“Mobile Application”) and any of their related products and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or “your”) and Uterinekind, Inc. (“Uterinekind, Inc.”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. </p>
                    <p>If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Services. By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and Uterinekind Inc., even though it is electronic and is not physically signed by you, and it governs your use of the Services.</p>

                    <div className="my-5">
                        <h2 className='h4'>Accounts and membership</h2>
                        <p>You must be at least 13 years old (minimum age) to use the Services. If a minor between the ages of 13 and 17 uses the Services, we provide the Services on the basis of the deemed consent of their parent or legal guardian, both to these Terms and to the use of the Services. Such consent is obtained when an account is created prior to the first use of the Services. The parent or guardian takes full responsibility for the minor's use of the Services.</p>

                        <p>We do not knowingly collect or use personal information from any child under 13 years of age. We do not target or market the Services to attract children under 13 years of age. Should a parent or guardian wish to use the Services on behalf of a dependent child, the parent or guardian takes full responsibility for the use of the Services. </p>

                        <p>If you create an account on the Services, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.</p>
                    </div>

                    <div className="my-5">
                        <h2 className='h4'>Medical Disclaimer</h2>
                        <p>We are not a licensed healthcare provider. Use of the Services is not permitted for the diagnosis and treatment of any medical condition and should not replace regular consultations with healthcare professionals. The Services are not diagnostic or treatment tools. Do not rely on information contained in the Services to diagnose or treat any medical condition. The Content is for educational and informational purposes only. </p>
                        <p>If you are experiencing a medical emergency, please call 911 in the United States, contact your physician or visit the nearest emergency department. </p>
                    </div>

                    <div className="my-5">
                        <h2 className='h4'>User content</h2>
                        <p>We do not own any data, information or material (collectively, “Content”) that you submit on the Services in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may monitor and review the Content on the Services submitted or created using our Services by you. You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. </p>

                        <p>Unless specifically permitted by you, your use of the Services does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose.</p>

                        <p>Through the use of the Services, you record and monitor your daily symptoms experiences. These data are not sold or shared by us for any purpose. You will have the option to choose to have your data anonymized and shared for the purposes of medical research by opting in via the Services. In the future, there may be other options for the sharing of data to benefit you, your care team, and medical research. In all cases, you will choose to opt in to share your anonymized data.</p>
                    </div>    
                    
                    <div className="my-5">
                        <h2 className='h4'>Billing and payments</h2>
                        <p>You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis, payment may be required after the free trial period ends, and not when you enter your billing details (which may be required prior to the commencement of the free trial period). If auto-renewal is enabled for the Services you have subscribed for, you will be charged automatically in accordance with the term you selected. If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.</p>
                        </div>   

                        <div className="my-5">
                        <h2 className='h4'>Accuracy of information</h2>
                        <p>Occasionally there may be information on the Services that contains typographical errors, inaccuracies or omissions that may relate to promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Services or Services is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Services including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Services should be taken to indicate that all information on the Services or Services has been modified or updated.</p>
                        </div>  


                        <div className="my-5">
                        <h2 className='h4'>Uptime guarantee</h2>
                        <p>We offer a Service uptime guarantee of 99% of available time per month. The service uptime guarantee does not apply to service interruptions caused by: (1) periodic scheduled maintenance or repairs we may undertake from time to time; (2) interruptions caused by you or your activities; (3) outages that do not affect core Service functionality; (4) causes beyond our control or that are not reasonably foreseeable; and (5) outages related to the reliability of certain programming environments.</p>
                        </div>

                        <div className="my-5">
                        <h2 className='h4'>Backups</h2>
                        <p>We perform regular backups of the Website and its Content and will do our best to ensure completeness and accuracy of these backups. In the event of the hardware failure or data loss we will restore backups automatically to minimize the impact and downtime.</p>
                        </div>

                        <div className="my-5">
                        <h2 className='h4'>Links to other resources</h2>
                        <p>Although the Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Services. Your linking to any other off-site resources is at your own risk.</p>
                        </div>

                        <div className="my-5">
                        <h2 className='h4'>Prohibited uses</h2>
                       <p>In addition to other terms as set forth in the Agreement, you are prohibited from using the Services or Content:</p>
                       <ul className="alphabat-listing">
                            <li>for any unlawful purpose;</li>
                         <li>to solicit others to perform or participate in any unlawful acts;</li>
                         <li>to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;</li>
                         <li>to infringe upon or violate our intellectual property rights or the intellectual property rights of others;</li>
                          <li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
                          <li>to submit false or misleading information;</li>
                          <li>to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Services, third party products and services, or the Internet;</li>
                          <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                          <li>for any obscene or immoral purpose; or</li>
                          <li>to interfere with or circumvent the security features of the Services, third party products and services, or the Internet. We reserve the right to terminate your use of the Services for violating any of the prohibited uses.</li>
                          </ul>
                        </div>


                        <div className="my-5">
                        <h2 className='h4'>Intellectual property rights</h2>
                        <p>"Intellectual Property Rights" means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by Uterinekind, Inc. or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with Uterinekind, Inc. All trademarks, service marks, graphics and logos used in connection with the Services, are trademarks or registered trademarks of Uterinekind, Inc. or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Services may be the trademarks of other third parties. Your use of the Services grants you no right or license to reproduce or otherwise use any of Uterinekind, Inc. or third-party trademarks.</p>    
                        </div>

                        <div className="my-5">
                        <h2 className='h4'>Limitation of liability</h2>
                        <p>To the fullest extent permitted by applicable law, in no event will Uterinekind, Inc., its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of Uterinekind, Inc. and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount no greater than one dollar or any amounts actually paid in cash by you to Uterinekind, Inc. for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.</p>    
                        </div>

                        <div className="my-5">
                        <h2 className='h4'>Indemnification</h2>
                        <p>You agree to indemnify and hold Uterinekind, Inc. and its affiliates, directors, officers, employees, agents, suppliers, and licensors harmless from and against any liabilities, losses, damages, or costs, including reasonable attorneys’ fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Services or any wilful misconduct on your part.</p>    
                        </div>

                        <div className="my-5">
                        <h2 className='h4'>Dispute resolution</h2>
                        <p>The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Massachusetts, United States without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of United States. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Massachusetts, United States, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.</p>    
                        </div>

                        <div className="my-5">
                        <h2 className='h4'>Changes and amendments</h2>
                        <p>We reserve the right to modify this Agreement or its terms related to the Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.</p>    
                        <p>An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement unless otherwise specified. Your continued use of the Services after the effective date of the revised Agreement (or such other act specified at that time) will constitute your consent to those changes.</p>
                        </div>

                        <div className="my-5">
                        <h2 className='h4'>Acceptance of these terms</h2>
                        <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Services.</p>    
                        </div>

                        <div className="my-5">
                        <a className="primary-text-color" href="mailto:support@uterinekind.com">support@uterinekind.com</a>
                        <p className='small'>This document was last updated on December 8, 2022</p>    
                        </div>

                        

                    </div>    
                </div>
            </div>
           
        </section>
    </>
}

export default TermsAndConditions