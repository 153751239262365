import React, { useEffect, useRef } from 'react'
import { gsapSplitTextAnimationv2 } from '../../gsap/common/';
import { Player, BigPlayButton } from 'video-react';
import thumbnail from '../../../assets/images/Introvideo-thumbnail.jpg'
// import './video.css';
import '../HomePage/video.css'
import VideoPlayer from "./VideoPlayer";
import { gsapTitleInXAnimation, gsapTitleInYAnimation, gsapAlphaFadeInAnimation, gsapScrollToElementById } from '../../gsap/common'
function Intro(props){

    const animatePartTool1Ref = useRef([]);
    useEffect(() => {
        const ctx = props.gsap.context(() => {
            /*
                * funcation: gsapSplitTextAnimationv2
                * param: 
                    *   gsap obj, 
                    *   element Ref, 
                    *   elementTrigger Ref, 
                    *   opacity
                    *   yPercent
                    *   duration
                    *   stagger
                    *   start
                    *   end
                *
            */
            gsapSplitTextAnimationv2(props.gsap, animatePartTool1Ref, animatePartTool1Ref,
                1, 100, 1.5, 0.1, "bottom bottom", "bottom top");
        })
        return () => ctx.revert();
   

    }, []);

    const handleClick = (elementId) => {
        gsapScrollToElementById(props.gsap, elementId, 1)
    }
    
    return(

          <section className="intro spacer  pt-4 text-center" id="intro">
            <div className="container container-sm">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <h2 className="h3 primary-text-color fw-bold animate-heading" ref={animatePartTool1Ref}>Provide the evidence your doctor needs to diagnose the root cause of your symptoms</h2>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12 mx-auto">
                        <VideoPlayer />
                    </div>
                </div>
            </div>
          </section>   
  )
}

export default Intro;