import React from 'react';
import ReactGA from 'react-ga';

const ExternalLink = ({ href, children, ...rest }) => {
    const handleClick = (event) => {
        event.preventDefault();

        ReactGA.event({
            category: 'External Link',
            action: 'click',
            label: href,
        });

        window.open(href, '_blank', 'noopener noreferrer');
    };

    return (
        <a href={href} onClick={handleClick} {...rest}>
            {children}
        </a>
    );
};

export default ExternalLink;
