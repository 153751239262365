import { useState, React } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { formatPublisedDate, minToStandardFormat } from '../../../utils/functions';
import Transcript from './Transcript';
import { defaultValues } from "../../../constant/endpoints";
import { PODCAST } from '../../../constant/endpoints';
import { getAudioId } from '../../../utils/functions';
import { getRequest } from '../../../config/httpClient/axiosClient';

function PlayPodcast(props) {

    const [isReadMore, setIsReadMore] = useState(false);

    const {id} = props.episodeDetails;
    const {title} = props.episodeDetails;
    const {description} = props.episodeDetails;
    const {long_description} = props.episodeDetails;
    const {transcription} = props.episodeDetails;

    let navigate = useNavigate();
    const [isTranscriptReadMore, setIsTranscriptReadMore] = useState(true);
    const _activePodcastItems = useSelector(state =>state.ActivePodcastEpisode )
    const [transcript, setTranscript] = useState(null);
    const [transcriptLoader, setTranscriptLoader] = useState(true);
    
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const toggleTranscriptReadMore = () => {
        if (isTranscriptReadMore === false) {
            props.gsap.to(window, {
                scrollTo: {
                    y: ".transcript",
                    offsetY: 200
                },
                duration: 0.8,
            })
        }
        setIsTranscriptReadMore(!isTranscriptReadMore);
    };

    function redirectToPodcastPage() {
        let path = `/podcast`;
        navigate(path);
    }

    return <>
        <div className='podcast-detail'>
            <a onClick={redirectToPodcastPage} href={() => false} className='btn back-btn mb-4' style={{ cursor: "pointer" }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" fill="rgba(255,255,255,1)" /></svg> Back
            </a>
            <h1 className='h4 primary-text-color'
                dangerouslySetInnerHTML={{__html: title }}
            >
            </h1>
            {/*<span className='fw-normal small d-block mt-2'>{formatPublisedDate(_activePodcastItems.publistDate, "short")} | {minToStandardFormat(_activePodcastItems.duration)}</span>*/}
            <iframe title={title} className="mb-5 mt-5" height="52px" width="100%" frameBorder="no" scrolling="no" seamless="" src={`https://player.simplecast.com/${id}?dark=true&color=transparent`}></iframe>
            
            <p className='my-3'>
                {description}
            </p>
            <p className='my-3'>    
                {isReadMore === true
                    ? <div className='my-3' dangerouslySetInnerHTML={{__html: `${long_description.slice(0, 300)}...`}} /> 
                    : <div className='my-3' dangerouslySetInnerHTML={{__html: `${long_description}`}} /> 
                    }
            </p>
            {/* <p className='my-3' dangerouslySetInnerHTML={{__html: _activePodcastItems.description}}>    
               
            </p> */}
            <div className='read-more my-4'>
                <a onClick={toggleReadMore} href={() => false} className='text-purple' style={{ cursor: "pointer" }}>
                    {isReadMore ? "READ MORE" : "READ LESS"}
                    <span>{isReadMore ? "+" : "-"}</span>
                </a>
            </div>

            {transcription !== null
             ?
                <>
                <h1 className='h4 primary-text-color transcript'>TRANSCRIPT</h1>
                <p className='my-3'>
                    {isTranscriptReadMore ? transcription.replace(/<[^>]*>?/gm, '').slice(0, 300) : <Transcript html={transcription} />}{isTranscriptReadMore ? ".." : ''}
                </p><div className='read-more my-4'>
                        <a onClick={toggleTranscriptReadMore} href={() => false} className='text-purple' style={{ cursor: "pointer" }}>
                            {isTranscriptReadMore ? "READ MORE" : "READ LESS"}
                            <span>{isTranscriptReadMore ? "+" : "-"}</span>
                        </a>
                    </div></>
                : transcriptLoader ? <p></p> : ""

            }
        </div>
    </>
}
export default PlayPodcast;
